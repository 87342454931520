import { Entityc, FFigur, Figur, Particle } from "./Figur";
import { ClientData, map, moreData, Point, RNG, skin } from "../common/ClientData";
import { ClickRequest, LoginRequest, LoginResponse, SendCommand, SendParticles, SendPositions, UpdateRequest, UpdateResponse, WebsocketResponse } from "../common/RequestsResponses.js";
import { Spiel } from "./Spiel";
import { Welt } from "./Welt";
import * as PIXI from 'pixi.js'
import '../main.css'
import $ from 'jquery'

export class Main {
    static rang: string;
    static app: PIXI.Application;
    static width: number = 800;
    static height: number = 600;
    // static spritesheet: PIXI.Spritesheet;
    md: moreData = new moreData();
    static websocket: WebSocket;
    banzeit: number;
    static w: Welt;
    selBlock = "dirt";
    skins: skin[];
    transparent: boolean = false;
    static m: Main;
    ban: boolean = false
    rayCastHelp: boolean;
    phoneMode: boolean;
    toggleRightClick: boolean;
    skin2:skin;
    constructor() {
        let that = this;
        Main.m = this;
        Main.app = new PIXI.Application();
        Main.app.init({
            width: Main.width, height: Main.height,
            backgroundColor: 0x0bb300,
            // backgroundColor: 0x1099bb,
            antialias: true,
        }).then(()=>{
            $('#pixi').append($(Main.app.canvas));
            that.setup();
        })
        // PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

        // PIXI.Loader
        //     .shared.add("assets/spritesheet.json")
        //     // .add("assets/ .png")
        //     .load(() => { that.setup() });

        // document.body.appendChild(app.view); 



    }
    setup() {

        let that = this;
        // Main.spritesheet = PIXI.Loader.shared.resources["assets/spritesheet.json"].spritesheet;


        // Listen for animate update

        let url = window.location.origin.replace("http", "ws");
        // console.log("Opening websocket: " + url);
        $("#loginknopf").on("click", () => {
            this.login(false)
        });
        $("#erstellenknopf").on("click", () => {
            this.login(true)
        });
        $("#command").on("keydown", (event) => {
            if (event.key == "Enter" && $("#command").is(":focus")) {
                $("#tabindex").focus()
                let sendCommand: SendCommand = {
                    type: "command",
                    data: new ClientData(null, <string>$("#name").val(), <string>$("#passwort").val(), Spiel.spiel.geld, 0, 0, true, Main.rang, 0, "", that.md, null, null, null, -1, null, null, null, null,null, null),
                    command: <string>$("#command").val()
                }

                Main.websocket.send(JSON.stringify(sendCommand));
                $("#command").val("")
            }
            event.stopPropagation();
        })
        $("#locode").on("click", () => {
            let l: string = <string>$("#scode").val();
            if (l.length == 7 * ((((skin.hy + skin.ay + skin.by) * (skin.hx))) + ((skin.ax * 2 * skin.ay)))) {
                for (let i = 0; i < ((((skin.hy + skin.ay + skin.by) * (skin.hx))) + ((skin.ax * 2 * skin.ay))); i++) {
                    let c = l.charAt(7 * i + 0) + l.charAt(7 * i + 1) + l.charAt(7 * i + 2) + l.charAt(7 * i + 3) + l.charAt(7 * i + 4) + l.charAt(7 * i + 5) + l.charAt(7 * i + 6)
                    if (c.charAt(0) == "#") {
                        if (c == "#trans.") {
                            let p = skin.getpixel(i);
                            let pi = $("#px" + p.x + "x" + p.y + "");
                            pi.css("background-color", "#66666600")
                        } else {
                            let p = skin.getpixel(i);
                            let pi = $("#px" + p.x + "x" + p.y + "");
                            pi.css("background-color", c)
                        }
                    } else {
                        return
                    }
                }
            }
        })
        $("#save").on("click", () => {
            that.skin2 = new skin(that.getCode())
            that.update();
            for (let y = 0; y < skin.hy + skin.ay + skin.by; y++) {
                for (let x = 0; x < skin.ax * 2 + skin.hx; x++) {
                    let b = false;
                    if (x > skin.ax - 1 && x < skin.ax + skin.hx) {
                        b = true;
                    }
                    if (y > skin.hy - 1 && y < skin.hy + skin.ay) {
                        b = true;
                    }
                    if (b) {
                        let c = $("#px" + x + "x" + y + "").css("background-color")
                        if (c == "rgba(102, 102, 102, 0)") {
                            Spiel.spiel.f.s.setpixelInt(x, y, 0, 0, 0, 0, true);
                        } else {
                            var rgb = c.match(/^rgb\((\d*), ?(\d*), ?(\d*)\)/)
                            // if(x==skin.ax+skin.hx-1&&y==skin.hy+skin.ay+skin.by-1){
                            Spiel.spiel.f.s.setpixelInt(x, y, Number.parseInt(rgb[1]), Number.parseInt(rgb[2]), Number.parseInt(rgb[3]), 255, true);
                        }
                        // }else{
                        //     Spiel.spiel.f.s.setpixelInt(x,y,Number.parseInt(rgb[1]),Number.parseInt(rgb[2]),Number.parseInt(rgb[3]));
                        // }
                    }
                }
            }
        })
        $("#ccode").on("click", () => {
            let s = that.getCode();
            // navigator.clipboard.writeText("hi")
            document.addEventListener('copy', (e: ClipboardEvent) => {
                e.clipboardData.setData('text/plain', (s));
                e.preventDefault();
                document.removeEventListener('copy', null);
            });
            document.execCommand('copy');
        })
        $("#transparent").on("click", () => {
            if (that.transparent) {
                $("#transparent").css("border-style", "none")
                $("#transparent").css("padding", "6px 10px")
            } else {
                $("#transparent").css("border-style", "solid")
                $("#transparent").css("padding", "3px 7px")
            }
            that.transparent = !that.transparent;
        })
        $("#rayCastHelp").on("click", () => {
            if (that.rayCastHelp) {
                $("#rayCastHelp").css("border-style", "none")
                $("#rayCastHelp").css("padding", "6px 10px")
            } else {
                $("#rayCastHelp").css("border-style", "solid")
                $("#rayCastHelp").css("padding", "3px 7px")
            }
            that.rayCastHelp = !that.rayCastHelp;
        })
        $("#toggleRightClick").on("click", () => {
            if (that.toggleRightClick) {
                $("#toggleRightClick").css("border-style", "none")
                $("#toggleRightClick").css("padding", "6px 10px")
            } else {
                $("#toggleRightClick").css("border-style", "solid")
                $("#toggleRightClick").css("padding", "3px 7px")
            }
            that.toggleRightClick = !that.toggleRightClick;
        })
        $("#phoneMode").on("click", () => {
            if (that.phoneMode) {
                $("#phoneMode").css("border-style", "none")
                $("#phoneMode").css("padding", "6px 10px")
                $(".phoneMode").css("display", "none")
            } else {
                $("#phoneMode").css("border-style", "solid")
                $("#phoneMode").css("padding", "3px 7px")
                $(".phoneMode").css("display", "block")
            }
            that.phoneMode = !that.phoneMode;
        })
        
        // let s: string[][] = [
        //     ["dirt", "dirt"], 
        //     ["dirt", "dirt"]
        // ];
    }
    getCode() {
        let s: string = ""
        for (let y = 0; y < skin.hy + skin.ay + skin.by; y++) {
            for (let x = 0; x < skin.ax * 2 + skin.hx; x++) {
                let b = false;
                if (x > skin.ax - 1 && x < skin.ax + skin.hx) {
                    b = true;
                }
                if (y > skin.hy - 1 && y < skin.hy + skin.ay) {
                    b = true;
                }
                if (b) {
                    let c = $("#px" + x + "x" + y + "").css("background-color")
                    if (c == null) {
                        c = null;
                    }
                    if (c == "rgba(102, 102, 102, 0)") {
                        s = s + "#trans."
                    } else {
                        var rgb = c.match(/^rgb\((\d*), ?(\d*), ?(\d*)\)/)
                        var r = Number.parseInt(rgb[1]).toString(16);//r = "0" + r statt r = r + "0"
                        if (r.length == 1) {
                            r ="0"+ r
                        }
                        var g = Number.parseInt(rgb[2]).toString(16);
                        if (g.length == 1) {
                            g ="0"+ g
                        }
                        var bl = Number.parseInt(rgb[3]).toString(16);
                        if (bl.length == 1) {
                            bl = "0"+bl
                        }
                        s = s + "#" + r + g + bl;
                    }
                }
            }
        }
        return s
    }
    handleLoginResponse(response: LoginResponse, neu: boolean) {
        // console.log("Login-Response: ", response.existiert);
        if (response.data.banzeit <= 0) {
            if (response.existiert) {
                Main.w = new Welt(new map(response.s.s, response.s.x, response.s.y, 1));
                Main.w.render();
                let spiel = new Spiel();
                spiel.FillInv(response.data.cInventar)
                Main.app.ticker.add((delta) => {
                    spiel.act(delta.deltaMS);
                });
                Spiel.spiel.geld = response.geld;
                Main.rang = response.data.rang;
                this.skins = response.data.skins;
                for (let x = 0; x < this.skins.length; x++) {
                    const s = this.skins[x];
                    if (s != null) {
                        let i = 0;
                        let sk = $("<div class='kskin'></div>")
                        $("#skins").append(sk);
                        for (let y = 0; y < skin.hy + skin.ay + skin.by; y++) {
                            let re = $("<div class='kpixelreihe'></div>")
                            sk.append(re);
                            if (!(y > skin.hy - 1 && y < skin.hy + skin.ay)) {
                                let ph = $("<div class='kpixelreiheleer' style='width:" + skin.ax * 5 + "px'></div>")
                                re.append(ph)
                            }
                            for (let x = 0; x < skin.ax * 2 + skin.hx; x++) {
                                let b = false;
                                if (x > skin.ax - 1 && x < skin.ax + skin.hx) {
                                    b = true;
                                }
                                if (y > skin.hy - 1 && y < skin.hy + skin.ay) {
                                    b = true;
                                }
                                if (b) {
                                    let c = s.s.charAt(7 * i + 1) + s.s.charAt(7 * i + 2) + s.s.charAt(7 * i + 3) + s.s.charAt(7 * i + 4) + s.s.charAt(7 * i + 5) + s.s.charAt(7 * i + 6)
                                    re.append("<div class='kpixel' id='px" + x + "x" + y + "' style='background-color:#" + c + "'></div>")
                                    i++;
                                }
                            }
                        }
                        let edit = $("<div class='button' style='width:-webkit-fill-available;margin-right:0px;text-align: center;margin-top:5px'>edit</div>")
                        sk.append(edit)
                        let copy = $("<div class='button' style='width:-webkit-fill-available;margin-right:0px;text-align: center;margin-top:5px'>copy</div>")
                        sk.append(copy)
                    }
                }
                let l: string = response.data.skin2.s;
                if (l.length == 7 * ((((skin.hy + skin.ay + skin.by) * (skin.hx))) + ((skin.ax * 2 * skin.ay)))) {
                    for (let i = 0; i < ((((skin.hy + skin.ay + skin.by) * (skin.hx))) + ((skin.ax * 2 * skin.ay))); i++) {
                        let c = l.charAt(7 * i + 0) + l.charAt(7 * i + 1) + l.charAt(7 * i + 2) + l.charAt(7 * i + 3) + l.charAt(7 * i + 4) + l.charAt(7 * i + 5) + l.charAt(7 * i + 6)
                        if (c.charAt(0) == "#") {
                            if (c == "#trans.") {
                                let p = skin.getpixel(i);
                                let pi = $("#px" + p.x + "x" + p.y + "");
                                pi.css("background-color", "#66666600")
                                Spiel.spiel.f.s.setpixelInt(p.x, p.y, 0, 0, 0, 0);
                            } else {
                                let p = skin.getpixel(i);
                                let pi = $("#px" + p.x + "x" + p.y + "");
                                pi.css("background-color", c)
                                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c);
                                Spiel.spiel.f.s.setpixelInt(p.x, p.y, Number.parseInt(result[1], 16), Number.parseInt(result[2], 16), Number.parseInt(result[3], 16));
                            }
                        } else {
                            return
                        }
                    }
                }
                Spiel.spiel.f.s.setpixelInt(0, 0, 0, 0, 0, 0, true);
                $(".spiel").show();
                $(".tab").show();
            } else {
                $("#login").show();
                $("#Fehler").show();
                window.location.reload();
            }
        } else {
            this.banzeit = response.data.banzeit;
            $("#Fehler2").show();
            let b = response.data.banzeit;
            let d = (b - (b % (3600 * 24))) / 3600 * 24;
            b = b % (3600 * 24);
            let h = (b - (b % (3600))) / 3600;
            b = b % (3600);
            let m = (b - (b % (60))) / 60;
            b = b % (60);
            let s = b;
            $("#Fehler2").text("this account has been temporary banned for " + response.data.banreason + ". unban in " + d + "d, " + h + "h, " + m + "m, " + s + "s");
            Main.app.ticker.add(() => {//hier  
                response.data.banzeit -= Main.app.ticker.elapsedMS / 1000;
                let b = Math.ceil(response.data.banzeit);
                let d = (b - (b % (3600 * 24))) / 3600 * 24;
                b = b % (3600 * 24);
                let h = (b - (b % (3600))) / 3600;
                b = b % (3600);
                let m = (b - (b % (60))) / 60;
                b = b % (60);
                let s = b;
                $("#Fehler2").text("this account has been temporary banned for " + response.data.banreason + ". unban in " + d + "d, " + h + "h, " + m + "m, " + s + "s");
                if (response.data.banzeit <= 0) {
                    Main.app.ticker.stop()
                    $("#Fehler2").text("you are now unbanned. click here to reload!");
                    $("#Fehler2").on("click", () => {
                        window.location.reload()
                    })
                }
            });

        }
    }

    update() {
        let c: ClientData = new ClientData(null, <string>$("#name").val(), <string>$("#passwort").val(), Spiel.spiel.geld, 0, 0, true, "", 0, "", this.md, this.skins, null, null, -1, null, null, null, null,null, this.skin2);
        let v: UpdateRequest = {
            type: "update",
            name: <string>$("#name").val(),
            password: <string>$("#passwort").val(),
            data: c
        }
        Main.websocket.send(JSON.stringify(v));
    }

    login(neu: boolean) {
        let that = this;
        $("#login").hide();
        Main.websocket = new WebSocket(window.location.origin.replace("http", "ws") + "/websocket");
        Main.websocket.onopen = (event) => {

            let loginRequest: LoginRequest = {
                type: "login",
                name: <string>$("#name").val(),
                password: <string>$("#passwort").val(),
                code: <string>$("#code").val(),
                neu: neu
            }

            Main.websocket.send(JSON.stringify(loginRequest));
            // $("#pixi").on("click", (event) => {
            // })
            $("#pixi").on({
                "contextmenu": function (e) {
                    // console.log("ctx menu button:", e.which);
                    // Stop the context menu
                    // that.sendClickRequest(e.originalEvent,"right");
                    e.preventDefault();
                },
                // "mousedown": function (e) {
                //     // console.log("normal mouse down:", e.which);
                //     if(e.which==1){
                //         that.sendClickRequest(e.originalEvent,that.toggleRightClick?"right":"left");
                //     }
                //     if(e.which==3){
                //         that.sendClickRequest(e.originalEvent,"right");
                //     }
                // },
                "pointerup": function (e) {
                    // console.log("normal mouse up:", e.which);
                    if(e.which==1){
                        that.sendClickRequest(e,that.toggleRightClick?"rightup":"leftup");
                    }
                    if(e.which==3){
                        that.sendClickRequest(e,"rightup");
                    }
                },
                // "mousemove": function (e) {
                //     // console.log("normal mouse up:", e.which);
                //     that.sendClickRequest(e,"move");
                // }
            });
            $("#pixi").on("pointerdown", (e)=> {
                // console.log("normal mouse down:", e.which);
                if(e.which==1){
                    that.sendClickRequest(e,that.toggleRightClick?"right":"left");
                }
                if(e.which==3){
                    that.sendClickRequest(e,"right");
                }
            });
            $("#pixi").on("pointermove", (e) => {
                that.sendClickRequest(e,"move");
                
            });
        }
        Main.websocket.onclose = (event) => {
            if (!that.ban) {
                $(".spiel").hide()
                $(".tab").hide()
                $(".news").hide()
                $(".options").hide()
                $("#Fehler2").show()
                $("#Fehler2").text("Lost connection. The page will reload automatically in 5 seconds.")
                let rlz = 5000;
                let b = true;
                Main.app.ticker.add(() => {
                    rlz -= Main.app.ticker.elapsedMS;
                    if (rlz <= 0 && b) {
                        window.location.reload()
                        b = false;
                    }
                })
            }
        }
        Main.websocket.onmessage = (messageevent) => {
            // console.log(messageevent.data);
            let response: WebsocketResponse = JSON.parse(messageevent.data);

            switch (response.type) {
                case "login":
                    that.handleLoginResponse(response, neu);
                    break;
                case "chat":
                    that.handleChatMessage(response.nachricht, response.farbig);
                    break;
                case "setblock":
                    Main.w.w.setblock(response.x, response.y, response.block);
                    Main.w.w.x = response.wx;
                    Main.w.w.y = response.wy;
                    Main.w.neu(Main.w.w);
                    Main.w.render();
                    break;
                case "world":
                    Main.w.neu(response.map)
                    break;
                case "particle":
                    let pa=<SendParticles>response;
                    Particle.createParticles(pa.p)
                    break;
                case "position":
                    //
                    Figur.speed=response.stats[12];
                    Figur.range=response.stats[16];
                    $("#he7").text(response.health+" / "+response.stats[15]);
                    $("#ma7").text(response.mana+" / "+response.stats[17]);
                    $("#health").css("width", 200 * response.health / response.stats[15]);
                    $("#mana").css("width", 200 * response.mana / response.stats[17])
                    let löschen: number[] = [];
                    FFigur.f.forEach((value, id) => {
                        let id2 = (<SendPositions>response).daten.findIndex((value2) => {
                            return value.name == value2.name;
                        })
                        if (id2 > -1) {
                            if (!(<SendPositions>response).daten[id2].online) {
                                löschen.push(id);
                                // (<SendPositions>response).daten.splice(id2, 1);
                            } else {
                                if (Main.w != null) {
                                    value.ship.s.x = (<SendPositions>response).daten[id2].x + Main.w.x;
                                    value.ship.s.y = (<SendPositions>response).daten[id2].y + Main.w.y;
                                    if ((<SendPositions>response).daten[id2].skin2!=null&&(<SendPositions>response).daten[id2].skin2.s!=null&&value.skin != (<SendPositions>response).daten[id2].skin2.s) {
                                        value.skin = (<SendPositions>response).daten[id2].skin2.s;
                                        value.invalidate()
                                    }
                                    (<SendPositions>response).daten.splice(id2, 1);
                                }
                            }
                        }
                    });
                    for (let i = löschen.length-1; i >= 0; i--) {
                        FFigur.f[löschen[i]].ship.s.destroy();
                        FFigur.f.splice(löschen[i], 1);
                    }
                    // löschen.forEach((value, id) => {
                    // });
                    let id3 = (<SendPositions>response).daten.findIndex((value2) => {
                        return $("#name").val() == value2.name;
                    });
                    (<SendPositions>response).daten.splice(id3, 1);
                    (<SendPositions>response).daten.forEach((value) => {
                        if (value.online) {
                            let f = new FFigur(value.name);
                            f.ship.s.x = value.x;
                            f.ship.s.y = value.y;
                            if (value.skin2!=null&&f.skin != value.skin2.s) {
                                f.skin = value.skin2.s;
                                f.invalidate()
                            }
                        }
                    });
                    //
                    let löschene: number[] = [];
                    let help=0;
                    Entityc.f.forEach((value, id) => {
                        let id2 = (<SendPositions>response).entities.findIndex((value2) => {
                            return value.id == value2.id;
                        })
                        if (id2 > -1) {
                            if (Main.w != null) {
                                value.lx=value.lx2;
                                value.ly=value.ly2;
                                value.ship.x = (<SendPositions>response).entities[id2].x + Main.w.x;
                                value.ship.y = (<SendPositions>response).entities[id2].y + Main.w.y;
                                value.lx2=value.ship.x;
                                value.ly2=value.ship.y;
                                if (value.texture != (<SendPositions>response).entities[id2].grafik) {
                                    value.texture = (<SendPositions>response).entities[id2].grafik;
                                }
                                value.textst = (<SendPositions>response).entities[id2].text;
                                value.ship.scale.set((<SendPositions>response).entities[id2].scale*5);
                                value.ship.rotation=(<SendPositions>response).entities[id2].rotation;
                                value.invalidate();
                                setTimeout(() => {
                                    if(!value.ship.destroyed){
                                        value.sib=1;
                                        value.PNS();
                                    }
                                }, 30);
                                (<SendPositions>response).entities.splice(id2, 1);
                            }
                        } else {
                            löschene.push(id-help);
                            help++;
                        }
                    });
                    // löschene.forEach((value, id) => {
                    //     if(Entityc.f[value].ship!=null){
                    //         Entityc.f[value].ship.destroy();
                    //     }
                    //     for (let i = 0; i < Entityc.f[value].text.length; i++) {
                    //         const e = Entityc.f[value].text[i];
                    //         for (let j = 0; j < e.length; j++) {
                    //             const t = e[j];
                    //             t.destroy()
                    //         }
                    //     }
                    //     Entityc.f.splice(value, 1);
                    // });
                    for (let i = löschene.length-1; i >= 0; i--) {
                        const e = löschene[i];
                        if(Entityc.f[e].ship!=null){
                            Entityc.f[e].ship.destroy();
                        }
                        for (let j = 0; j < Entityc.f[e].text.length; j++) {
                            const e2 = Entityc.f[e].text[j];
                            for (let k = 0; k < e2.length; k++) {
                                const t = e2[k];
                                t.destroy()
                            }
                        }
                        Entityc.f.splice(e, 1);
                        
                    }
                    (<SendPositions>response).entities.forEach((value) => {
                        let f = new Entityc(value.id, value.grafik, value.hitboxc, value.x + Main.w.x, value.y + Main.w.y, value.text);
                        // f.ship.x = value.x;
                        // f.ship.y = value.y;
                        f.ship.scale.set(value.scale*5);
                        f.ship.rotation=value.rotation;
                        if (f.texture != value.grafik) {
                            f.texture = value.grafik;
                        }
                        f.invalidate()
                    });
                    //
                    break;
                case "update":
                    let u = <UpdateResponse>response;
                    Spiel.spiel.geld = u.data.geld;
                    $("#name").val(u.data.name + "");
                    $("#password").val(u.data.pasword + "");
                    Main.rang = u.data.rang;
                    Spiel.spiel.FillInv(response.data.cInventar)
                    if (u.data.x != null && !Number.isNaN(u.data.x)) {
                        let gx = Spiel.spiel.f.x - u.data.x;
                        let gy = Spiel.spiel.f.y - u.data.y;
                        Spiel.spiel.f.y -= gy;
                        Spiel.spiel.f.x -= gx;
                        Main.w.move(-gx, -gy)
                        for (let i = 0; i < FFigur.f.length; i++) {
                            const e = FFigur.f[i];
                            e.ship.s.y += gy;
                            e.ship.s.y += gx;
                        }
                        for (let i = 0; i < Entityc.f.length; i++) {
                            const e = Entityc.f[i];
                            e.ship.y += gy;
                            e.ship.y += gx;
                            e.hitbox.y += gy;
                            e.hitbox.y += gx;
                        }
                    }
                    if (u.data.banzeit > 0) {
                        $(".spiel").hide()
                        $(".tab").hide()
                        $(".news").hide()
                        $(".options").hide()
                        $("#Fehler2").show()
                        if (u.data.banzeit > 1) {
                            $("#Fehler2").text("you  re banned for " + response.data.banreason + ". the page will reload automatically in 5s.")
                        } else {
                            $("#Fehler2").text("you where kicked for " + response.data.banreason + ". the page will reload automatically in 5s.")
                        }
                        that.ban = true;
                        let rlz = 5000;
                        let b = true;
                        Main.app.ticker.add(() => {
                            rlz -= Main.app.ticker.elapsedMS;
                            if (rlz <= 0 && b) {
                                window.location.reload()
                                b = false;
                            }
                        })
                    }
                    break;
            }
        }

    }
    sendClickRequest(e:MouseEvent|JQuery.TriggeredEvent<HTMLElement, undefined, HTMLElement, HTMLElement>, key:string) {
        let target;
        var rect;
        var x;
        var y;
        if(e==null){
            x=e.pageX;
            y=e.pageY;
        }else{
            target = <HTMLDivElement>e.target;
            rect = target.getBoundingClientRect();
            x = e.clientX - rect.left;
            y = e.clientY - rect.top;
        }
        // that.writeinchat("/setblock " + ((x - Main.w.x) / Welt.bb + 0.5) + " " + ((y - Main.w.y) / Welt.bb + 0.5) + " " + that.selBlock + " true")
        let sendCommand: ClickRequest = {
            type: "click",
            name: <string>$("#name").val(),
            password: <string>$("#passwort").val(),
            x: x + Spiel.spiel.f.x - 400,
            y: y + Spiel.spiel.f.y - 300,
            key: key
        }
        Main.websocket.send(JSON.stringify(sendCommand));
    }
    static high(n1: number, n2: number) {
        if (n1 > n2) {
            return n1
        } else {
            return n2
        }
    }
    handleChatMessage(nachricht: string, farbig: boolean) {
        if (farbig) {
            let id = Math.random();
            let letzteFarbe = 0;
            let letzterLink = 0;
            let letzterLinkAnfang = 0;
            let farbe = "000000"
            let link = ""
            $(".chat").append("<div id='chat" + id + "'></div>")
            let i;
            for (i = 0; i < nachricht.length; i++) {
                let c = nachricht.charAt(i);
                if (c == "~") {
                    let id2: string = "" + Math.floor(Math.random() * 1000000000);
                    if(farbe=="raibow"){
                        $(".chat").append("<span id='hgf" + id2 + "' class='chatspan' class='rainbow'></span>")
                    }else{
                        $(".chat").append("<span id='hgf" + id2 + "' class='chatspan' style='color:#" + farbe + "'></span>")
                    }
                    $("#hgf" + id2).text(nachricht.slice(Main.high(letzteFarbe, letzterLink), i))
                    let l2 = link;
                    $("#hgf" + id2).on("click", () => {
                        this.writeinchat(l2);
                    })
                    farbe = nachricht.slice(i + 1, i + 7);
                    letzteFarbe = i + 7;
                }
                if (c == "}") {
                    letzterLink = i + 1;
                    link = nachricht.slice(letzterLinkAnfang, i)
                    let id2: string = "" + Math.floor(Math.random() * 1000000000);
                    letzteFarbe = i + 1;
                }
                if (c == "{") {
                    letzterLinkAnfang = i + 1;
                    let id2: string = "" + Math.floor(Math.random() * 1000000000);
                    if(farbe=="raibow"){
                        $(".chat").append("<span id='hgf" + id2 + "' class='chatspan rainbow'></span>")
                    }else{
                        $(".chat").append("<span id='hgf" + id2 + "' class='chatspan' style='color:#" + farbe + "'></span>")
                    }
                    $("#hgf" + id2).text(nachricht.slice(Main.high(letzteFarbe, letzterLink), i))
                    let l2 = link;
                    $("#hgf" + id2).on("click", () => {
                        this.writeinchat(l2);
                    })
                }
                if (c == ";") {
                    let id2: string = "" + Math.floor(Math.random() * 1000000000);
                    if(farbe=="raibow"){
                        $(".chat").append("<span id='hgf" + id2 + "' class='chatspan rainbow'></span>")
                    }else{
                        $(".chat").append("<span id='hgf" + id2 + "' class='chatspan' style='color:#" + farbe + "'></span>")
                    }
                    $("#hgf" + id2).text(nachricht.slice(Main.high(letzteFarbe, letzterLink), i))
                    let l2 = link;
                    $("#hgf" + id2).on("click", () => {
                        this.writeinchat(l2);
                    })
                    letzteFarbe = i + 1;
                    link = "";
                }
                ///say advanced {Hi!}KLICK HIER!,; nicht hier!
            }
            let id2: string = "" + Math.floor(Math.random() * 1000000000);
            if(farbe=="raibow"){
                $(".chat").append("<span id='hgf" + id2 + "' class='chatspan rainbow'></span>")
            }else{
                $(".chat").append("<span id='hgf" + id2 + "' class='chatspan' style='color:#" + farbe + "'></span>")
            }
            $("#hgf" + id2).text(nachricht.slice(letzteFarbe, i))
            let l2 = link;
            $("#hgf" + id2).on("click", () => {
                this.writeinchat(l2);
            })
        } else {
            let id2: string = "" + Math.floor(Math.random() * 1000000000);
            $(".chat").append("<div id='hgf" + id2 + "' class='chatspan'></div>")
            $("#hgf" + id2).text(nachricht)
        }
        $('.chat').scrollTop($('.chat')[0].scrollHeight);
    }
    writeinchat(msg: string) {
        if (msg != "") {
            let sendCommand: SendCommand = {
                type: "command",
                data: new ClientData(null, <string>$("#name").val(), <string>$("#passwort").val(), Spiel.spiel.geld, 0, 0, true, Main.rang, 0, "", this.md, null, null, null, -1, null, null, null, null,null, null),
                command: msg
            }

            Main.websocket.send(JSON.stringify(sendCommand));
        }
    }

}


$(() => {
    console.log("main called!");

    new Main();
})

console.log("main called!");
