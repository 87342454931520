import { Main } from "./Main";
import { Entity, Point, RNG, SendParticle, skin } from "../common/ClientData";
import { SendPosition } from "../common/RequestsResponses";
import { Welt } from "./Welt";
import * as PIXI from 'pixi.js'
import $ from 'jquery'
import { cache } from "./Spiel";

export class Figur {
    x = 400;
    y = 300;
    s: Manschgal;
    static speed: number;
    walls: PIXI.Graphics;
    static range: number;
    constructor() {
        // let spritesheet = PIXI.Loader.shared.resources["assets/Figur.png"].spritesheet;
        // const texture = await PIXI.Assets.load('assets/Figur.png');

        // Create a 5x5 grid of ships
        this.s = new Manschgal();
        // const ship = new PIXI.Sprite(texture);
        // ship.texture=await PIXI.Assets.load('assets/ERROR.png');
        this.s.s.anchor.set(0.5);
        this.s.s.x = 400;
        this.s.s.y = 300;
        this.s.s.scale.set(5);
        // Main.app.stage.addChild(this.s.s);
        // this.s.setpixel(3, 2, "#00ff00", true)
        // ship.setTransform(400,300)
        let w = false;
        let s = false;
        let a = false;
        let d = false;
        this.walls = new PIXI.Graphics();
        Main.app.stage.addChild(this.walls);
        $(document).on("keydown", (event) => {
            if (event.key == "w") {
                w = true;
            }
            if (event.key == "s") {
                s = true;
            }
            if (event.key == "a") {
                a = true;
            }
            if (event.key == "d") {
                d = true;
            }
            if (event.key == "t") {
                $("#command").focus()
                return false;
            }
        })
        $(document).on("keyup", (event) => {
            if (event.key == "w") {
                w = false;
            }
            if (event.key == "s") {
                s = false;
            }
            if (event.key == "a") {
                a = false;
            }
            if (event.key == "d") {
                d = false;
            }
        })
        $("#up").on("mousedown", () => {
            w = true;
        })
        $("#up").on("touchstart", () => {
            w = true;
        })
        $("#up").on("mouseup", () => {
            w = false;
        })
        $("#up").on("mouseleave", () => {
            w = false;
        })
        $("#up").on("touchend", () => {
            w = false;
        })
        $("#down").on("mousedown", () => {
            s = true;
        })
        $("#down").on("touchstart", () => {
            s = true;
        })
        $("#down").on("mouseup", () => {
            s = false;
        })
        $("#down").on("mouseleave", () => {
            s = false;
        })
        $("#down").on("touchend", () => {
            s = false;
        })
        $("#left").on("mousedown", () => {
            a = true;
        })
        $("#left").on("touchstart", () => {
            a = true;
        })
        $("#left").on("mouseup", () => {
            a = false;
        })
        $("#left").on("mouseleave", () => {
            a = false;
        })
        $("#left").on("touchend", () => {
            a = false;
        })
        $("#right").on("mousedown", () => {
            d = true;
        })
        $("#right").on("touchstart", () => {
            d = true;
        })
        $("#right").on("mouseup", () => {
            d = false;
        })
        $("#right").on("mouseleave", () => {
            d = false;
        })
        $("#right").on("touchend", () => {
            d = false;
        })
        Main.app.ticker.add((delta) => {
            if (w || s || a || d && Main.websocket.CLOSING) {
                let g = 0.05 * Figur.speed;
                let x = 0;
                let y = 0;
                if (w) {
                    y -= g;
                }
                if (a) {
                    x -= g;
                }
                if (s) {
                    y += g;
                }
                if (d) {
                    x += g;
                }
                if (x != 0 && y != 0) {
                    let l = Math.sqrt(x * x + y * y) / g;
                    x /= l;
                    y /= l;
                }
                this.move(x, y)

                // if (w) {
                //     this.y -= g;
                //     Main.w.move(0, -g)
                //     for (let i = 0; i < FFigur.f.length; i++) {
                //         const e = FFigur.f[i];
                //         e.ship.s.y += g;
                //     }
                //     for (let i = 0; i < Entityc.f.length; i++) {
                //         const e = Entityc.f[i];
                //         e.ship.y += g;
                //         e.hitbox.y += g;
                //     }
                // }
                // if (s) {
                //     this.y += g;
                //     Main.w.move(0, g)
                //     for (let i = 0; i < FFigur.f.length; i++) {
                //         const e = FFigur.f[i];
                //         e.ship.s.y -= g;
                //     }
                //     for (let i = 0; i < Entityc.f.length; i++) {
                //         const e = Entityc.f[i];
                //         e.ship.y -= g;
                //         e.hitbox.y -= g;
                //     }
                // }
                // if (a) {
                //     this.x -= g;
                //     Main.w.move(-g, 0)
                //     for (let i = 0; i < FFigur.f.length; i++) {
                //         const e = FFigur.f[i];
                //         e.ship.s.x += g;
                //     }
                //     for (let i = 0; i < Entityc.f.length; i++) {
                //         const e = Entityc.f[i];
                //         e.ship.x += g;
                //         e.hitbox.x += g;
                //     }
                // }
                // if (d) {
                //     this.x += g;
                //     Main.w.move(g, 0)
                //     for (let i = 0; i < FFigur.f.length; i++) {
                //         const e = FFigur.f[i];
                //         e.ship.s.x -= g;
                //     }
                //     for (let i = 0; i < Entityc.f.length; i++) {
                //         const e = Entityc.f[i];
                //         e.ship.x -= g;
                //         e.hitbox.x -= g;
                //     }
                // }
                if (w || a || s || d) {
                    for (let i = 0; i < Entityc.f.length; i++) {
                        const e = Entityc.f[i];
                        e.invalidate();
                    }
                }
                let p: SendPosition = {
                    type: "position",
                    x: -Main.w.x + 400,
                    y: -Main.w.y + 300,
                    name: <string>$("#name").val(),
                    passwort: <string>$("#passwort").val()
                }
                Main.websocket.send(JSON.stringify(p));
            }
        });
    }
    static Linienschnittpunkt(ax: number, bx: number, ay: number, by: number, cx: number, dx: number, cy: number, dy: number) {
        let ex = bx - ax;
        let ey = by - ay;
        let fx = dx - cx;
        let fy = dy - cy;
        let o = cy * ex - ay * ex - cx * ey + ax * ey;
        let u = fx * ey - fy * ex;
        if (u == 0) {
            return;
        }
        let l = o / u;
        let x = cx + l * fx;
        let y = cy + l * fy;
        return [x, y]
    }
    move(x: number, y: number) {
        if (x == 0 && y == 0) {
            return;
        }
        let hitBoxX = 30;
        let hitBoxY = 70;
        let w = Main.w;
        let xmax = Math.max(w.x + hitBoxX, w.x + hitBoxX - x)
        let ymax = Math.max(w.y + hitBoxY, w.y + hitBoxY - y)
        let xmin = Math.min(w.x - hitBoxX, w.x - hitBoxX - x)
        let ymin = Math.min(w.y - hitBoxY, w.y - hitBoxY - y)
        // let x1=[w.x-hitBoxX,w.x+hitBoxX,w.x+hitBoxX,w.x-hitBoxX];
        // let y1=[w.y-hitBoxY,w.y-hitBoxY,w.y+hitBoxY,w.y+hitBoxY];
        // let x2=[w.x-hitBoxX+x,w.x+hitBoxX+x,w.x+hitBoxX+x,w.x-hitBoxX+x];
        // let y2=[w.y-hitBoxX+y,w.y-hitBoxX+y,w.y+hitBoxX+y,w.y+hitBoxX+y];
        let bb = Welt.bb;
        let xhelp = [0, 1];
        let yhelp = [1, 0];
        let walls: Wall[] = [];
        // let b=w.w.s[-(w.y-w.y%bb)/bb+idy-w.w.y][-(w.y-w.y%bb)/bb+idy-w.w.y];
        // let b=w.w.s[Math.ceil((-w.x+400)/Welt.bb)-w.w.x][Math.ceil((-w.y+300)/Welt.bb)-w.w.y];
        this.walls.clear();
        for (let x = -1; x < Math.ceil((xmax - xmin) / Welt.bb) + 1; x++) {
            for (let y = -3; y < Math.ceil((ymax - ymin) / Welt.bb); y++) {
                let b;
                let hhx = Math.floor((-w.x + 400) / Welt.bb) - w.w.x + x;
                let hhy = Math.floor((-w.y + 300) / Welt.bb) - w.w.y + y;
                if (hhx < 0 || hhx >= w.w.s.length || hhy < 0 || hhy >= w.w.s[0].length) {
                    b = "";
                } else {
                    b = w.w.s[hhx][hhy];
                }
                for (let i = 0; i < xhelp.length; i++) {
                    const xh = xhelp[i];
                    const yh = yhelp[i];
                    let b1;
                    if (hhx + xh < 0 || hhx + xh >= w.w.s.length || hhy + yh < 0 || hhy + yh >= w.w.s[0].length) {
                        b1 = "";
                    } else {
                        b1 = w.w.s[hhx + xh][hhy + yh];
                    }
                    if ((b1[0] == "#") != (b[0] == "#")) {
                        // console.log("b1 = "+b1+"; b = "+b)
                        walls.push(new Wall(
                            Math.floor((-w.x + (400 % bb)) / Welt.bb) * bb + x * bb - bb,
                            Math.floor((-w.y + (300 % bb)) / Welt.bb) * bb + y * bb - bb,
                            // xh*(b1[0] != "#"?1:-1)
                            xh, yh
                            // yh*(b1[0] != "#"?1:-1)
                        ));
                        const wa = walls[walls.length - 1];
                        const path = [
                            wa.posx + wa.roty * bb / 2 + w.x + 400 + wa.rotx * bb / 2,
                            wa.posy + wa.rotx * bb / 2 + w.y + 300 + wa.roty * bb / 2,
                            wa.posx + wa.roty * bb / (-2) + w.x + 400 + wa.rotx * bb / 2,
                            wa.posy + wa.rotx * bb / (-2) + w.y + 300 + wa.roty * bb / 2
                        ];
                        this.walls.setStrokeStyle({width:1})
                        if (true) {
                            // this.walls.drawPolygon(path)
                        }
                    }
                }
            }
        }
        //hitbox[0]+x,hitbox[1]+y,hitbox[2]+x,hitbox[3]+y
        let hx = 1;
        let hy = 1;
        for (let i = 0; i < walls.length; i++) {
            let wa = walls[i];
            if (wa.rotx != 0) {
                if (x == 0) {
                    continue;
                }
                const path = [
                    wa.posx + wa.roty * bb / 2 + wa.rotx * bb / 2,
                    wa.posy + wa.rotx * bb / 2 + wa.roty * bb / 2,
                    wa.posx + wa.roty * bb / (-2) + wa.rotx * bb / 2,
                    wa.posy + wa.rotx * bb / (-2) + wa.roty * bb / 2
                ];
                let hv = x > 0 ? 1 : -1;
                let p1 = hv * hitBoxX - w.x;
                let mult = (path[0] - p1) / x;
                if (mult < hx && mult >= 0) {
                    let nymax = hitBoxY - w.y + y * mult;
                    let nymin = -hitBoxY - w.y + y * mult;
                    if (nymax <= Math.min(path[1], path[3]) || nymin >= Math.max(path[1], path[3])) {
                        continue;
                    }
                    hx = mult;
                }
            } else {
                if (y == 0) {
                    continue;
                }
                const path = [
                    wa.posx + wa.roty * bb / 2 + wa.rotx * bb / 2,
                    wa.posy + wa.rotx * bb / 2 + wa.roty * bb / 2,
                    wa.posx + wa.roty * bb / (-2) + wa.rotx * bb / 2,
                    wa.posy + wa.rotx * bb / (-2) + wa.roty * bb / 2
                ];
                let hv = y > 0 ? 1 : -1;
                let p1 = hv * hitBoxY - w.y;
                let mult = (path[1] - p1) / y;
                if (mult < hy && mult >= 0) {
                    let nxmax = hitBoxX - w.x + x * mult;
                    let nxmin = -hitBoxX - w.x + x * mult;
                    if (nxmax <= Math.min(path[0], path[2]) || nxmin >= Math.max(path[0], path[2])) {
                        continue;
                    }
                    hy = mult;
                }
            }
        }
        let min = Math.min(hx, hy);
        if (min < 1) {
            if (min == hx) {
                this.mxy(x * min, y * min);
                x = 0;
                y = y - y * min;
                this.move(x, y);
            }
            if (min == hy) {
                this.mxy(x * min, y * min);
                y = 0;
                x = x - x * min;
                this.move(x, y);
            }
        } else {
            this.mxy(x, y);
        }
        // for (let i = 0; i < x1.length; i++) {
        //     const x1h = x1[i];
        //     const y1h = y1[i];
        //     const x2h = x2[i];
        //     const y2h = y2[i];

        // }
        // this.walls.x+=x;
    }
    mxy(x: number, y: number) {
        if(x==0&&y==0){
            return;
        }
        y *= -1;
        this.y -= y;
        Main.w.move(0, -y)
        for (let i = 0; i < FFigur.f.length; i++) {
            const e = FFigur.f[i];
            e.ship.s.y += y;
        }
        for (let i = 0; i < Particle.p.length; i++) {
            const e = Particle.p[i];
            e.ship.y += y;
        }
        for (let i = 0; i < Entityc.f.length; i++) {
            const e = Entityc.f[i];
            e.ship.y += y;
            e.ly += y;
            e.ly2 += y;
            e.hitbox.y += y;
        }
        x *= -1;
        this.x -= x;
        Main.w.move(-x, 0)
        for (let i = 0; i < FFigur.f.length; i++) {
            const e = FFigur.f[i];
            e.ship.s.x += x;
        }
        for (let i = 0; i < Particle.p.length; i++) {
            const e = Particle.p[i];
            e.ship.x += x;
        }
        for (let i = 0; i < Entityc.f.length; i++) {
            const e = Entityc.f[i];
            e.ship.x += x;
            e.lx += x;
            e.lx2 += x;
            e.hitbox.x += x;
        }
    }
}
export class FFigur {
    static f: FFigur[] = [];
    ship: Manschgal;
    skin: string = "";
    constructor(public name: string) {
        FFigur.f.push(this)
        // let spritesheet = PIXI.Loader.shared.resources["assets/Figur.png"].spritesheet;
        // const texture = await PIXI.Assets.load('assets/Figur.png');

        // Create a 5x5 grid of ships
        this.ship = new Manschgal();
        this.ship.s.anchor.set(0.5);
        // this.ship.x = 400;
        // this.ship.y = 300; 
        this.ship.s.scale.set(5);
        Main.app.stage.addChild(this.ship.s);
        // ship.setTransform(400,300)
    }
    invalidate() {
        let l: string = this.skin
        if (l.length == 7 * ((((skin.hy + skin.ay + skin.by) * (skin.hx))) + ((skin.ax * 2 * skin.ay)))) {
            for (let i = 0; i < ((((skin.hy + skin.ay + skin.by) * (skin.hx))) + ((skin.ax * 2 * skin.ay))); i++) {
                let c = l.charAt(7 * i + 0) + l.charAt(7 * i + 1) + l.charAt(7 * i + 2) + l.charAt(7 * i + 3) + l.charAt(7 * i + 4) + l.charAt(7 * i + 5) + l.charAt(7 * i + 6)
                if (c.charAt(0) == "#") {
                    let p = skin.getpixel(i);
                    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c);
                    if (c == "#trans.") {
                        this.ship.setpixelInt(p.x, p.y, 0, 0, 0, 0);
                    } else {
                        this.ship.setpixelInt(p.x, p.y, Number.parseInt(result[1], 16), Number.parseInt(result[2], 16), Number.parseInt(result[3], 16));
                    }
                } else {
                    return
                }
            }
        }
        this.ship.setpixelInt(0, 0, 0, 0, 0, 0, true);
    }
}
export class Entityc {
    static f: Entityc[] = [];
    ship: PIXI.Sprite;
    text: PIXI.Text[][] = [[]];
    hitbox: PIXI.Graphics;
    textst: string;
    at: string;
    ay: number;
    ax: number;
    lx: number;
    ly: number;
    lx2: number;
    ly2: number;
    line: number;
    sib: number = 1;
    // skin: string = "";
    constructor(public id: number, public texture: string, public hitboxg: number[], x: number, y: number, public t: string) {
        Entityc.f.push(this)
        // let spritesheet = PIXI.Loader.shared.resources["assets/Figur.png"].spritesheet;
        // const texture2 = await PIXI.Assets.load('assets/Figur.png');
        
        // Create a 5x5 grid of ships
        this.ship = new PIXI.Sprite();
        cache('assets/' + texture,(t)=>{
            this.ship.texture=t;
        });
        this.ship.anchor.set(0.5);
        this.ship.x = x;
        this.ship.y = y;
        this.lx = x;
        this.ly = y;
        this.lx2 = x;
        this.ly2 = y;
        this.ship.scale.set(5);
        this.textst = t;
        Main.app.stage.addChild(this.ship);
        // ship.setTransform(400,300)
        const graphics = new PIXI.Graphics();
        //hitbox[0]+x,hitbox[1]+y,hitbox[2]+x,hitbox[3]+y
        const path = [hitboxg[0] + x, hitboxg[2] + y, hitboxg[1] + x, hitboxg[2] + y, hitboxg[1] + x, hitboxg[3] + y, hitboxg[0] + x, hitboxg[3] + y];
        graphics.setStrokeStyle({
            width:1
        })
        if (false) {
            graphics.drawPolygon(path);
        }
        Main.app.stage.addChild(graphics);
        this.hitbox = graphics;
        // const style = new PIXI.TextStyle({
        //     fontFamily: 'Arial',
        //     fontSize: 20,
        //     // fontStyle: 'italic',
        //     // fontWeight: 'bold',
        //     fill: ['#ffffff'], // gradient
        //     // stroke: '#4a1850',
        //     // strokeThickness: 5,
        //     // dropShadow: true,
        //     // dropShadowColor: '#000000',
        //     // dropShadowBlur: 4,
        //     // dropShadowAngle: Math.PI / 6,
        //     // dropShadowDistance: 6,
        //     // wordWrap: true,
        //     // wordWrapWidth: 440,
        //     lineJoin: 'round',
        //     align: 'center',
        // });
    }
    invalidate() {
        cache('assets/' + this.texture,(t)=>{
            this.ship.texture = t;
        });
        if (this.textst != this.at) {
            for (let i = 0; i < this.text.length; i++) {
                const e = this.text[i];
                for (let j = 0; j < e.length; j++) {
                    const t = e[j];
                    t.destroy()
                }
            }
            this.text = [[]];
            let letzteFarbe = 0;
            let farbe = "ffffff"
            let i;
            this.line = 0;
            for (i = 0; i < this.textst.length; i++) {
                let c = this.textst.charAt(i);
                if (c == "~") {
                    let style = new PIXI.TextStyle({
                        fontFamily: 'Arial',
                        fontSize: 20,
                        fill: '#' + farbe,
                        // lineJoin: 'round',
                        align: 'center',
                    });
                    let h = new PIXI.Text({text:this.textst.slice(letzteFarbe, i), style:style});
                    Main.app.stage.addChild(h)
                    this.text[this.line].push(h);
                    farbe = this.textst.slice(i + 1, i + 7);
                    letzteFarbe = i + 7;
                }
                if (c == "\n") {
                    let style = new PIXI.TextStyle({
                        fontFamily: 'Arial',
                        fontSize: 20,
                        fill: '#' + farbe,
                        // lineJoin: 'round',
                        align: 'center',
                    });
                    let h = new PIXI.Text({text:this.textst.slice(letzteFarbe, i), style:style});
                    Main.app.stage.addChild(h)
                    this.text[this.line].push(h);
                    this.text.push([]);
                    letzteFarbe = i + 1;
                    this.line++;
                    // $(".tooltip").append("<div><br></div>");
                }
                ///say advanced {Hi!}KLICK HIER!,; nicht hier!
            }
            let style = new PIXI.TextStyle({
                fontFamily: 'Arial',
                fontSize: 20,
                fill: '#' + farbe,
                // lineJoin: 'round',
                align: 'center',
            });
            let h = new PIXI.Text({text:this.textst.slice(letzteFarbe, i), style:style});
            Main.app.stage.addChild(h)
            this.text[this.line].push(h);
            // $('.tooltip').scrollTop($('.tooltip')[0].scrollHeight);
        }
        if (this.ax != this.ship.x || this.at != this.textst || this.ay != this.ship.y) {
            for (let i = 0; i < this.text.length; i++) {
                const t = this.text[i];
                let width = 0;
                let width2 = 0;
                for (let j = 0; j < t.length; j++) {
                    const t2 = t[j];
                    width += t2.width;
                }
                for (let j = 0; j < t.length; j++) {
                    const t2 = t[j];
                    t2.x = this.ship.x - width / 2 + width2;
                    t2.y = this.ship.y - this.ship.height / 2 - 30 - (this.line - i) * t2.height;
                    width2 += t2.width;
                }
            }
            this.at = this.textst;
            this.ax = this.ship.x;
            this.ay = this.ship.y;
        }
    }
    PNS() {

        let steps = 1;
        steps++;
        let step = this.sib++;
        let h = (step / steps + 1);
        this.ship.x = this.ship.x * h + this.lx * (1 - h);
        this.ship.y = this.ship.y * h + this.ly * (1 - h);
        this.invalidate();
    }
}
export class Manschgal {
    // f:PIXI.bitmap 
    s: PIXI.Sprite;
    texture: PIXI.Texture;
    data: Uint32Array;
    data8:Uint8Array;
    static breite: number = 2 * skin.ax + skin.hx;
    static höhe: number = skin.hy + skin.ay + skin.by;
    canvas:HTMLCanvasElement;
    constructor() {
        this.data = new Uint32Array(Manschgal.breite * Manschgal.höhe);
        this.data8 = new Uint8Array(this.data.buffer);
        // let cl=new Uint8ClampedArray(this.data.buffer);
        this.canvas=<HTMLCanvasElement>$("<canvas width:\""+Manschgal.breite+"\" height:\""+Manschgal.höhe+"\"></canvas>")[0];
        let d=this.canvas.getContext('2d').createImageData(Manschgal.breite,Manschgal.höhe);
        for(let i=0;i<this.data8.length;i++) {
            d.data[i]=this.data[i];
        }
        this.canvas.getContext('2d').putImageData(d,0,0);
        // let bufferResource = new PIXI.BufferResource(u8Array, { width: Manschgal.breite, height: Manschgal.höhe });
        // let bt = new PIXI.BaseTexture(bufferResource, {
        //     scaleMode: 'nearest'
        // });
        let source=new PIXI.ImageSource({
            resource:this.canvas
        });
        this.texture = new PIXI.Texture({source:source});
        
        this.s = new PIXI.Sprite(this.texture);
        Main.app.stage.addChild(this.s)
    }

    setpixel(x: number, y: number, color: string, withUpdate: boolean = false) {
        let i = y * Manschgal.breite + x;

        this.data[i] = Manschgal.hexColorToInt(color) + 0xff000000;
        
        if (withUpdate) this.update();
    }

    setpixelInt(x: number, y: number, r: number, g: number, b: number, alpha: number = 255, withUpdate: boolean = false) {
        let i = y * Manschgal.breite + x;

        this.data[i] = 0x10000 * b + 0x100 * g + r + 0x1000000 * alpha;

        if (withUpdate) this.update();
    }

    update(){
        this.data8=new Uint8Array(this.data.buffer);
        let textureNew = PIXI.Texture.from(new PIXI.BufferImageSource({resource: this.data8, 
            width: Manschgal.breite,
            height: Manschgal.höhe                
        }))
        let source: PIXI.ImageSource = textureNew.source;
            source.minFilter = "nearest";
            source.magFilter = "nearest";
        this.s.texture = textureNew;
    }

    static hexColorToInt(hexColor: string): number {
        if (hexColor == null) return 0;
        if (hexColor.startsWith('#')) hexColor = hexColor.substr(1);
        return Number.parseInt(hexColor, 16);
    }

}
export class Wall {
    constructor(public posx: number, public posy: number, public rotx: number, public roty: number) {

    }
}
export class Particle {
    static p: Particle[] = [];
    ship: PIXI.Sprite | PIXI.Text | PIXI.Graphics;
    i:NodeJS.Timeout;
    constructor(public sp: SendParticle, x: number, y: number, public vx: number, public vy: number, public size: number,public time:number) {
        Particle.p.push(this)
        if (sp.type == "sprite") {
            this.ship = new PIXI.Sprite();
            cache('assets/' + sp.texture,(t)=>{
                (<PIXI.Sprite>(this.ship)).texture=t;
            });
            this.ship.scale.set(size);
            this.ship.x = x;
            this.ship.y = y;
            this.ship.anchor.set(0.5);
        }
        if (sp.type == "text") {
            this.ship = new PIXI.Text({text:sp.texture});
            this.ship.scale.set(size);
            this.ship.x = x;
            this.ship.y = y;
            this.ship.anchor.set(0.5);
        }
        if (sp.type == "circle") {
            this.ship = new PIXI.Graphics();
            this.ship.beginFill(sp.colour,1);
            this.ship.drawCircle(x, y, size);
            this.ship.endFill();
        }
        Main.app.stage.addChild(this.ship);
        this.i=setInterval(()=>{
            this.act();
        },30)
    }
    act() {
        this.ship.x+=this.vx;
        this.ship.y+=this.vy;
        this.vx+=this.sp.ax;
        this.vy+=this.sp.ay;
        this.vx*=this.sp.frictionx;
        this.vy*=this.sp.frictiony;
        this.time--;
        if(this.time<=0){
            Particle.createParticles(this.sp.onDestroy, this.ship.x, this.ship.y, this.vx, this.vy)
            this.ship.destroy();
            clearInterval(this.i);
            Particle.p.splice(Particle.p.indexOf(this),1);
        }
    }
    static createParticles(pa:SendParticle[], x:number=0,y:number=0,vx:number=0,vy:number=0){
        for (let i = 0; i < pa.length; i++) {
            const p = pa[i];
            let rng=new RNG(p.seed);
            for (let j = 0; j < p.amount; j++) {
                let p1=p.circle?rng.randomCircle(p.dx,p.dy):new Point((rng.random()-0.5)*p.dx,(rng.random()-0.5)*p.dy);
                p1.move(p.x==null?x-Main.w.x:p.x,p.y==null?y-Main.w.y:p.y);
                let p2=p.vcircle?rng.randomCircle(p.dvx,p.dvy):new Point((rng.random()-0.5)*p.dvx,(rng.random()-0.5)*p.dvy);
                p2.move(p.vx==null?vx:p.vx,p.vy==null?vy:p.vy);
                new Particle(p,p1.x+Main.w.x,p1.y+Main.w.y,p2.x,p2.y,p.size+((rng.random()-0.5)*p.dsize),p.time+((rng.random()-0.5)*p.dtime));
            }
        }
    }
}