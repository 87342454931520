import { Main } from "./Main";
import { map } from "../common/ClientData";
import * as PIXI from 'pixi.js'
import { cache } from "./Spiel";

export class Welt{
    f:PIXI.Sprite[][]=[];
    t:string[][]=[];
    w:map;
    static bb:number=map.bb;
    x:number=0;
    y:number=0;
    constructor(w:map){
        this.neu(w);
        for (let x = 0; x < 800/Welt.bb+2; x++) {
            this.f.push([]);
            for (let y = 0; y < 600/Welt.bb+2; y++) {
                let s=new PIXI.Sprite();
                cache("assets/items/blocks/ERROR.png",(t)=>{
                    s.texture=t;
                })
                Main.app.stage.addChild(s);
                s.scale.set(Welt.bb/16)
                this.f[x].push(s)
            }
        }
        this.render();
    }
    move(x:number,y:number){
        this.x-=x
        this.y-=y
        this.render();
    }
    render() {
        let f=this.f;
        let bb=Welt.bb;
        f.forEach((e,idx) => {
            e.forEach((el,idy) => {
                el.x=idx*bb-bb/2+this.x%bb-bb*0.5;
                el.y=idy*bb-bb/2+this.y%bb-bb*0.5;
                if(-(this.x-this.x%bb)/bb+idx-this.w.x>=0&&-(this.x-this.x%bb)/bb+idx-this.w.x<this.t.length&&-(this.y-this.y%bb)/bb+idy-this.w.y>=0&&-(this.y-this.y%bb)/bb+idy-this.w.y<this.t[-(this.x-this.x%bb)/bb+idx-this.w.x].length){
                    let t=this.t[-(this.x-this.x%bb)/bb+idx-this.w.x][-(this.y-this.y%bb)/bb+idy-this.w.y]
                    cache(t,(t)=>{
                        el.texture=t;
                    });
                }else{
                    cache("assets/items/blocks/Wellen.png",(t)=>{
                        el.texture=t;
                    });
                }
            });
        });
    }
    neu(w: map) {
        this.w=w;
        w.s.forEach((st,id) => {
            this.t.push([])
            st.forEach((s,id2)=>{
                this.t[id][id2]=this.BlockFinden(s);
            });
        });
    }
    BlockFinden(s: string) {
        if(s[0]=="#"){
            s=s.slice(1,s.length)
        }
        if(s=="dirt"){
            return 'assets/items/blocks/Erde.png';
        }
        if(s=="christmas_decoration"){
            return 'assets/items/blocks/Erde.png';
        }
        if(s=="stone_bricks"){
            return 'assets/items/blocks/steinziegel.png';
        }
        if(s=="grass"){
            return 'assets/items/blocks/grassblock.png';
        }
        if(s=="oak_wood"){
            return 'assets/items/blocks/Holzstamm.png';
        }
        if(s=="stone"){
            return 'assets/items/blocks/Stein.png';
        }
        if(s=="oak_leaves"){
            return 'assets/items/blocks/Oak leaves.png';
        }
        if(s=="oak_planks"){
            return 'assets/items/blocks/Eichenholzbretter.png';
        }
        if(s=="crafting_table"){
            return 'assets/items/blocks/Crafting_table.png';
        }
        if(s=="wheat"){
            return 'assets/items/blocks/Weizen (von oben).png';
        }
        if(s==""){
            return 'assets/items/blocks/nix.png';
        }
        return 'assets/items/blocks/ERROR.png';
    }
}

