import { Main } from "./Main";
import { ClientData } from "../common/ClientData";
import { ItemClickRequest, UpdateRequest } from "../common/RequestsResponses";
import { Spiel } from "./Spiel";
import $ from 'jquery'

export class Inventar {
    static id: number = 0;
    e: JQuery<HTMLDivElement>;
    static tooltip: string[] = [];
    constructor(x: number, y: number, bx: number, by: number) {
        let neu = true;
        let i: number = Inventar.id++;
        $("body").append("<div class='ItemSlotsv spiel tabinhalt' style='position: absolute;top:" + y + "px;left:" + x + "px' id='h" + i + "'></div>")
        this.e = $("#h" + i);
        let selectedHotbarSlot = 0;
        $(".selected").removeClass("selected");
        $("#" + selectedHotbarSlot).addClass("selected")
        document.onwheel = (event) => {
            event.preventDefault();
            // console.log(event);
            if (event.deltaY > 0) {
                selectedHotbarSlot++;
            } else {
                selectedHotbarSlot--;
            }
            if (selectedHotbarSlot > 8) {
                selectedHotbarSlot -= 9
            }
            if (selectedHotbarSlot < 0) {
                selectedHotbarSlot += 9;
            }
            $(".selected").removeClass("selected");
            $("#" + selectedHotbarSlot).addClass("selected")
            let c: ClientData = new ClientData(null, <string>$("#name").val(), <string>$("#passwort").val(), Spiel.spiel.geld, 0, 0, true, "", 0, "", Main.m.md, null, null, null, selectedHotbarSlot, null, null, null, null,null,null);
            let v: UpdateRequest = {
                type: "update",
                name: <string>$("#name").val(),
                password: <string>$("#passwort").val(),
                data: c
            }
            Main.websocket.send(JSON.stringify(v));
        };
        $('#sright').on("pointerdown",(event)=>{
            // console.log(event);
            selectedHotbarSlot--;
            if (selectedHotbarSlot > 8) {
                selectedHotbarSlot -= 9
            }
            if (selectedHotbarSlot < 0) {
                selectedHotbarSlot += 9;
            }
            $(".selected").removeClass("selected");
            $("#" + selectedHotbarSlot).addClass("selected")
            let c: ClientData = new ClientData(null, <string>$("#name").val(), <string>$("#passwort").val(), Spiel.spiel.geld, 0, 0, true, "", 0, "", Main.m.md,null, null, null, selectedHotbarSlot, null, null, null, null,null,null);
            let v: UpdateRequest = {
                type: "update",
                name: <string>$("#name").val(),
                password: <string>$("#passwort").val(),
                data: c
            }
            Main.websocket.send(JSON.stringify(v));
        })
        $('#sleft').on("pointerdown",(event)=>{
            // console.log(event);
            selectedHotbarSlot++;
            if (selectedHotbarSlot > 8) {
                selectedHotbarSlot -= 9
            }
            if (selectedHotbarSlot < 0) {
                selectedHotbarSlot += 9;
            }
            $(".selected").removeClass("selected");
            $("#" + selectedHotbarSlot).addClass("selected")
            let c: ClientData = new ClientData(null, <string>$("#name").val(), <string>$("#passwort").val(), Spiel.spiel.geld, 0, 0, true, "", 0, "", Main.m.md, null, null, null, selectedHotbarSlot, null, null, null, null,null,null);
            let v: UpdateRequest = {
                type: "update",
                name: <string>$("#name").val(),
                password: <string>$("#passwort").val(),
                data: c
            }
            Main.websocket.send(JSON.stringify(v));
        })
        for (let y = 0; y < by; y++) {
            let n: number = Inventar.id++;
            this.e.append("<div class='ItemSlotsh' id='h" + n + "'></div>")
            let e2: JQuery<HTMLDivElement> = $("#h" + n);
            for (let x = 0; x < bx; x++) {
                if (!neu) {
                    new Itemslot(e2);
                } else {
                    let d: number = Itemslot.id++;
                    e2.append("<div class='ItemSlotAußen'><div class='ItemSlot' id='" + d + "'></div></div>")
                    $("#" + d).bind("contextmenu", (event) => {
                        event.preventDefault();
                        let v: ItemClickRequest = {
                            type: "inventory",
                            name: <string>$("#name").val(),
                            password: <string>$("#passwort").val(),
                            click: "right",
                            id: d
                        }
                        // $(".tooltip").hide();
                        // $(document).off("mousemove.meiner");
                        Main.websocket.send(JSON.stringify(v));
                    });
                    $("#" + d).on("click", (event) => {
                        event.preventDefault();
                        let v: ItemClickRequest = {
                            type: "inventory",
                            name: <string>$("#name").val(),
                            password: <string>$("#passwort").val(),
                            click: Main.m.toggleRightClick?"right":"left",
                            id: d
                        }
                        Main.websocket.send(JSON.stringify(v));
                        // this.click(event);
                    });
                    let intervalId;
                    $("#" + d).on("mouseover", (event) => {
                        if (Inventar.tooltip[d] != null && $("#maus").css("background-image") == "none") {
                            $(".tooltip").show();
                            Inventar.setToolTipText(Inventar.tooltip[d]);
                            if (Inventar.tooltip[d] == "" || $("#maus").css("background-image") != "none") {
                                $(".tooltip").hide();
                            }
                            intervalId = window.setInterval(() => {
                                Inventar.setToolTipText(Inventar.tooltip[d]);
                                $(".tooltip").show();
                                if (Inventar.tooltip[d] == "" || $("#maus").css("background-image") != "none") {
                                    $(".tooltip").hide();
                                }
                            }, 20);
                            $(".tooltip").css({
                                left: event.clientX - 6 - $(".tooltip").width() - 10,
                                top: event.clientY + 6
                            });
                            $(document).on("mousemove.meiner", (event) => {
                                $(".tooltip").css({
                                    left: event.clientX - 6 - $(".tooltip").width() - 10,
                                    top: event.clientY + 6+$(document).scrollTop()
                                });
                            });
                        }
                    })
                    $("#" + d).on("mouseout", (event) => {
                        $(".tooltip").hide();
                        window.clearInterval(intervalId);
                        $(document).off("mousemove.meiner");
                    })
                    // $("#" + d).on("click", (event) => {
                    //     // $(".tooltip").hide();
                    //     // $(document).off("mousemove.meiner");
                    // })
                }
            }
        }
    }
    static setToolTipText(text: string) {
        let id = Math.random();
        let letzteFarbe = 0;
        let farbe = "ffffff"
        let link = ""
        // $(".tooltip").append("<div id='chat" + id + "'></div>")
        $(".tooltip").children().empty();
        $(".tooltip").text("");
        let div=$("<span style='display:flex'></span>");
        $(".tooltip").append(div);
        let i;
        for (i = 0; i < text.length; i++) {
            let c = text.charAt(i);
            if (c == "~") {
                let id2: string = "" + Math.floor(Math.random() * 1000000000);
                if(farbe=="raibow"){
                    div.append("<span id='hgf" + id2 + "' class='tooltipspan rainbow'></span>")
                }else{
                    div.append("<span id='hgf" + id2 + "' class='tooltipspan' style='color:#" + farbe + "'></span>")
                }
                $("#hgf" + id2).text(text.slice(letzteFarbe, i));
                farbe = text.slice(i + 1, i + 7);
                letzteFarbe = i + 7;
            }
            if (c == "\n") {
                let id2: string = "" + Math.floor(Math.random() * 1000000000);
                if(farbe=="raibow"){
                    div.append("<div id='hgf" + id2 + "' class='tooltipspan rainbow'></div>")
                }else{
                    div.append("<div id='hgf" + id2 + "' class='tooltipspan' style='color:#" + farbe + "'></div>")
                }
                $("#hgf" + id2).text(text.slice(letzteFarbe, i));
                // farbe = text.slice(i + 1, i + 7);
                letzteFarbe = i + 1;
                // $(".tooltip").append("<div><br></div>");
                div=$("<span style='display:flex'></span>");
                $(".tooltip").append(div);
            }
            ///say advanced {Hi!}KLICK HIER!,; nicht hier!
        }
        let id2: string = "" + Math.floor(Math.random() * 1000000000);
        if(farbe=="raibow"){
            div.append("<span id='hgf" + id2 + "' class='tooltipspan rainbow'></span>")
        }else{
            div.append("<span id='hgf" + id2 + "' class='tooltipspan' style='color:#" + farbe + "'></span>")
        }
        $("#hgf" + id2).text(text.slice(letzteFarbe, i))
        // $('.tooltip').scrollTop($('.tooltip')[0].scrollHeight);
    }
    public JQuery(x: number, y: number) {
        return $($($(this.e.children()[y - 1]).children()[x - 1]).children()[0]);
    }
    // public invalidate(CInventar:Inv){

    // }
}
export class Itemslot {
    e: JQuery<HTMLDivElement>;
    i: Item;
    static i: Item;
    static id: number = 0;
    constructor(e: JQuery<HTMLDivElement>) {
        let d: Number = Itemslot.id++;
        e.append("<div class='ItemSlotAußen'><div class='ItemSlot' id='" + d + "'></div></div>")
        this.e = $("#" + d);
        if (Math.random() > 0.5) {
            this.i = new Erde(this);
        }
        this.e.bind("contextmenu", (event) => {
            event.preventDefault();
            // this.click(event);
        });
        // this.e.on("click", (event) => {
        //     // this.click(event);
        // });
        let $itemslots = this.e
        $itemslots.on("mouseover", (event) => {
            if (this.i != null && Itemslot.i == null) {
                $(".tooltip").show();
                Inventar.setToolTipText(this.i.b);
                $(document).on("mousemove.meiner", (event) => {
                    $(".tooltip").css({
                        left: event.clientX - 6 - $(".tooltip").width() - 10,
                        top: event.clientY + 6
                    });
                });
            }
        })
        $itemslots.on("mouseout", (event) => {
            $(".tooltip").hide();
            $(document).off("mousemove.meiner");
        })
    }
    public click(event: JQuery.ClickEvent<HTMLDivElement, undefined, HTMLDivElement, HTMLDivElement> | JQuery.ContextMenuEvent<HTMLDivElement, undefined, HTMLDivElement, HTMLDivElement>) {
        let i: Item = this.i;
        if (this.i != null && Itemslot.i != null && this.i.ItemType == Itemslot.i.ItemType) {
            if (event.button == 2) {
                this.i.Amount++;
                Itemslot.i.Amount--;
                if (this.i.Amount > this.i.StackAmount) {
                    Itemslot.i.Amount += this.i.Amount - this.i.StackAmount;
                    this.i.Amount -= this.i.Amount - this.i.StackAmount;
                }
            } else if (event.button == 0) {
                this.i.Amount += Itemslot.i.Amount;
                Itemslot.i.Amount = 0;
                if (this.i.Amount > this.i.StackAmount) {
                    Itemslot.i.Amount += this.i.Amount - this.i.StackAmount;
                    this.i.Amount -= this.i.Amount - this.i.StackAmount;
                }
            }
        } else if (this.i == null && Itemslot.i != null) {
            if (event.button == 2) {
                let i: Item = Item.kopieren(Itemslot.i, this)
                i.Amount = 1;
                Itemslot.i.Amount--;
                this.i = i;
            } else if (event.button == 0) {

                this.i = Itemslot.i;
                Itemslot.i = i;
            }
        } else if (this.i != null && Itemslot.i == null) {
            if (event.button == 2) {
                let i: Item = Item.kopieren(this.i, null)
                i.Amount /= 2;
                i.Amount = Math.round(i.Amount + 0.1)
                this.i.Amount /= 2;
                this.i.Amount = Math.round(this.i.Amount - 0.1)
                Itemslot.i = i;
            } else if (event.button == 0) {
                this.i = Itemslot.i;
                Itemslot.i = i;
            }
        }
        if (Itemslot.i != null && Itemslot.i.Amount <= 0) {
            Itemslot.i.i = null;
            Itemslot.i = null;
        }
        if (this.i != null && this.i.Amount <= 0) {
            this.i.i = null;
            this.i = undefined;
        }
        this.e.css({
            'background-image': "none"
        });
        this.e.text("");
        $("#maus").css({
            'background-image': "none"
        });
        $("#maus").text("");
        if (Itemslot.i != null) {
            Itemslot.i.i = null;
            Itemslot.i.zeichne();
        }
        if (this.i != null) {
            this.i.i = this;
            this.i.zeichne();
        }
        if (Itemslot.i != null) {
            $(".tooltip").hide();
        }
    }
}
export class Item {
    s: JQuery<HTMLDivElement>;
    b: string;
    d: string;
    e: string;
    i: Itemslot;
    ItemType: number;
    StackAmount: number;
    Amount: number = 1;
    grafik: String;
    constructor() {
    }
    public zeichne() {
        this.b = this.e;
        if (this.i != null) {
            this.s = this.i.e;
        }
        if (this.i == null) {
            $("#maus").css({
                'background-image': "url('assets/items/blocks/" + this.grafik + "')"
            });
            $("#maus").text(this.Amount + "");
        } else {
            this.s.css({
                'background-image': "url('assets/items/blocks/" + this.grafik + "')"
            });
            this.s.text(this.Amount + "");
        }
    }
    public copystats(i: Item) {
        this.s = i.s;
        this.b = i.b;
        this.i = i.i;
        this.ItemType = i.ItemType;
        this.StackAmount = i.StackAmount;
        this.Amount = i.Amount;
        this.grafik = i.grafik;
    }
    public static kopieren(i: Item, it: Itemslot): Item {
        let item: Item;
        if (i.ItemType == 1) {
            item = new Erde(it);
        } else {
            let exception: Item;
            exception.zeichne();
            return null;
        }
        item.copystats(i);
        return item;
    }
}
//Itemtyp:
//1:Erde
export class Erde extends Item {
    constructor(i: Itemslot) {
        super();
        this.i = i;
        if (this.i != null) {
            this.s = this.i.e
        }
        this.d = "Erde";
        this.e = "dirt";
        this.ItemType = 1;
        this.StackAmount = 5;
        this.grafik = "Erde.png"
        this.zeichne();
    }
}