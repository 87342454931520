// import { Statisch } from "./Statisch.js";

// import { collapseTextChangeRangesAcrossMultipleVersions, textChangeRangeIsUnchanged } from "typescript";
// import { formatDiagnosticsWithColorAndContext, isConstructorDeclaration } from "typescript";
// import { MyServer } from "../server/Server";
export class C {
    static dblue: string = "~0000bb";
    static red: string = "~dd0000";
    static gold: string = "~ffdd00";
    static cyan: string = "~0033ff";
    static lblue: string = "~4444ff";
    static white: string = "~ffffff";
    static white2: string = "~dddddd";
    static green: string = "~00ff00";
    static hred: string = "~ff0000";
    static yellow: string = "~ffff00";
}
export class ClientData {
    stats: number[] = [];
    timer: number = 0;
    constructor(public webSocket: WebSocket, public name: string, public pasword: string, public geld: number, public x: number, public y: number, public online: boolean, public rang: string, public banzeit: number, public banreason: string, public md: moreData, public skins: skin[], public Inventar: Inventar, public cInventar: ClientInventory, public selectedHotbarSlot: number, public items: Item[], public selArmSlot: number, public health: number, public mana: number, public bbt: BBT, public skin2: skin) {
        if (this.Inventar != null) {
            setInterval(() => {
                if (md != null && Rang.findRank(rang) != null) {
                    md.n = Rang.findRank(rang).look + "<" + name + ">";
                }
            }, 500)
            if (this.skins == null) {
                this.skins = []
                this.skins.push(skin.Sskin)
            }
            if (this.Inventar != null) {
                this.Inventar.c = this;
            }
        }
    }
    public duplicate() {
        let md = new moreData();
        md.f = this.md.f;
        md.fr = this.md.fr;
        md.n = this.md.n;
        md.map = this.md.map;
        md.guild = this.md.guild;
        md.pInfo = this.md.pInfo;
        let c = new ClientData(this.webSocket, this.name, this.pasword, this.geld, this.x, this.y, this.online, this.rang, this.banzeit, this.banreason, md, this.skins, this.Inventar, this.cInventar, this.selectedHotbarSlot, this.items, this.selArmSlot, this.health, this.mana, this.bbt, this.skin2)
        c.Inventar = new Inventar(c)
        return c;
    }
    addStat(stat: stats, a: number) {
        this.stats[Item.stats.indexOf(stat)] += a;
    }
    addStats(s: stat[]) {
        for (let i = 0; i < s.length; i++) {
            const e = s[i];
            this.addStat(e.stat, e.amount);
        }
    }
    getStat(stat: stats): number {
        return this.stats[Item.stats.indexOf(stat)]
    }
    calcStats() {
        // let h = this.getStat(stats.health) == this.health;
        // let m = this.getStat(stats.intelligence) == this.mana;
        for (let i = 0; i < Item.stats.length; i++) {
            const e = Item.stats[i];
            this.stats[i] = 0;
        }
        let it: Item[] = [];
        if ((<Item>this.Inventar.itemslots[this.selectedHotbarSlot].i) != null) {
            let he = Itemtyp.findtyp((<Item>this.Inventar.itemslots[this.selectedHotbarSlot].i).itemtyp).typ;
            if (he.includes(itemtypes.tool)&&!he.includes(itemtypes.orb)) {
                it.push((<Item>this.Inventar.itemslots[this.selectedHotbarSlot].i));
            }
        }
        if (this.selArmSlot != 0) {
            for (let i = 0; i < 4; i++) {
                if (this.items[this.selArmSlot * 4 - 4 + i] != null) {
                    it.push(this.items[this.selArmSlot * 4 - 4 + i]);
                }
            }
        }
        for (let j = 0; j < it.length; j++) {
            const h = it[j];
            h.calcStats(this);
            if (h.stats == null) {
                h.stats = [];
            }
            for (let i = 0; i < h.stats.length; i++) {
                const e = h.stats[i];
                this.addStat(Item.stats[i], e);
            }
        }
        this.addStat(stats.crit_chance, 30);
        this.addStat(stats.sea_creature_chance, 30);
        this.addStat(stats.crit_damage, 100);
        this.addStat(stats.strength, 100);
        this.addStat(stats.health, 100);
        this.addStat(stats.speed, 100);
        this.addStat(stats.attack_speed, 100);
        // this.addStat(stats.mining_speed, 100);
        // this.addStat(stats.foraging_speed, 100);
        this.addStat(stats.range, 100);
        this.addStat(stats.magic_find, 100);
        this.addStat(stats.intelligence, 100);
        this.addStat(stats.health_regen, 3);
        this.addStat(stats.mana_regen, 100);
        this.addStat(stats.defence, 100);
        this.addStat(stats.support_multiplier, 100);
        this.addStat(stats.damage_multiplier, 100);
        // this.addStat(stats.intelligence, 100);
        this.addStat(stats.ability_damage, 100);
        for (let i = 0; i < Entity.entities.length; i++) {
            const e = Entity.entities[i];
            if (e instanceof Projectile && (<Projectile>e).radius > 0) {
                let d = Math.sqrt((this.x - e.x) * (this.x - e.x) + (this.y - e.y) * (this.y - e.y));
                if (e.radius > d) {
                    for (let i = 0; i < e.buffs.length; i++) {
                        const e2 = e.buffs[i];
                        this.addStat(e2.stat, e2.amount * (Inventar.server.findPlayer(e.owner).getStat(stats.support_multiplier)) / 100);
                    }
                }
            }
        }
        for (let i = 0; i < this.md.effects.length; i++) {
            const e = this.md.effects[i];
            for (let i = 0; i < EffectType.effects.length; i++) {
                const e2 = EffectType.effects[i];
                if (e.effect.length >= e2.name.length && e.effect.slice(0, e2.name.length) == e2.name) {
                    this.addStats(e2.stats(e.tier));
                }
            }
        }
        if(this.health>this.getStat(stats.health)){
            this.health = this.getStat(stats.health);
        }
        if(this.mana>this.getStat(stats.intelligence)){
            this.mana = this.getStat(stats.intelligence);
        }
        // if (h) {
        //     this.health = this.getStat(stats.health);
        // }
        // if (m) {
        //     this.mana = this.getStat(stats.intelligence);
        // }
    }
    takeDMG(damage: number) {
        this.health -= damage * 100 / this.getStat(stats.defence);
    }
    act() {
        this.timer++;
        if (this.timer % 15 == 0) {
            this.health += this.getStat(stats.health_regen);
            this.mana += this.getStat(stats.intelligence) * this.getStat(stats.mana_regen) / 3000;
        }
        if (this.health > this.getStat(stats.health)) {
            this.health = this.getStat(stats.health);
        }
        if (this.mana > this.getStat(stats.intelligence)) {
            this.mana = this.getStat(stats.intelligence);
        }
        this.health = Math.floor(this.health);
        this.mana = Math.floor(this.mana);
        if (this.health <= 0) {
            this.health = this.getStat(stats.health)
            this.mana = this.getStat(stats.intelligence)
            this.x = 0;
            this.y = 0;
            Inventar.server.msg("~ff0000you died!", this.name, true);
            Inventar.server.update(this.name, 0, 0);
            for (let i = 0; i < Gegner.entities.length; i++) {
                const e = Gegner.entities[i];
                if (e.typ == "Testgegner" && (<Gegner>e).type.boss && ((<Gegner>e).clients.indexOf(this.name)) != -1) {
                    (<Gegner>e).damage[((<Gegner>e).clients.indexOf(this.name))] /= 2;
                }
                if (e.typ == "Testgegner" && (<Gegner>e).type.tags.includes(tag.slayer_boss) && ((<Gegner>e).ziel) == this.name) {
                    Entity.entities.splice(Entity.entities.indexOf(e), 1);
                    Inventar.server.msg("~ff0000you failed your slayer quest!", this.name, true);
                }
            }
        }
    }
}
export class moreData {
    f: string[] = [];
    fr: string[] = [];
    n: string
    map: number
    guild: string
    messages: string[] = [];
    pInfo: string[] = [];
    effects: Effect[] = [];
    miningBlockX: number = Number.NaN;
    miningBlockY: number = Number.NaN;
    mineTime: number = 0;
    left: boolean = false;
    right: boolean = false;
    mouseX: number = 0;
    mouseY: number = 0;
    skin: skin = skin.Sskin;
    lasthit: number = 0;
    canclick: boolean = true;
    constructor() {
        // this.skin = skin.Sskin;
    }
}
export class Rang {
    static r: Rang[] = [];
    constructor(public name: string, public look: string, public color: string, public strength: number) {
        Rang.r.push(this);
    }
    public static findRank(name: string) {
        return this.r.find((value) => {
            return value.name == name;
        });
    }
}
export class Vote {
    static next: Vote;
    static current: Vote;
    constructor(public cands: Candidate[]) {

    }
}
export class Candidate {
    //mögl. Zeichen für farbe,...
    //✺, ‡, ✹
    constructor(public name: string, public secretdescription: string, public description: string, public chance: number, public onElect: () => void) {
        if (description == "") {
            description = secretdescription
        }
        if (secretdescription == "") {
            secretdescription = description;
        }
    }
    static addCands() {
        // new Candidate("nogard","")
    }
}
export class map {
    static bb: number = 32;
    constructor(public s: string[][], public x: number, public y: number, public n: number) {

    }
    setblock(x: number, y: number, st: string) {
        let bx;
        let by;
        x -= this.x;
        y -= this.y;
        if (x < 0) {
            let j1 = -x;
            let j2 = this.s[0].length;
            for (let i = 0; i < j1; i++) {
                let a: string[] = [];
                for (let i2 = 0; i2 < j2; i2++) {
                    a.push("");
                }
                this.s.push(a);
            }
            this.move(-x, 0)
            bx = x;
            this.x = x + this.x;
            bx = 0;
        } else if (x >= this.s.length) {
            let j1 = x - this.s.length + 1;
            let j2 = this.s[0].length;
            for (let i = 0; i < j1; i++) {
                let a: string[] = [];
                for (let i2 = 0; i2 < j2; i2++) {
                    a.push("");
                }
                this.s.push(a);
            }
            bx = x;
        } else {
            bx = x;
        }
        if (y < 0) {
            let j4 = -y;
            let j3 = this.s.length
            for (let i = 0; i < j3; i++) {
                for (let i2 = 0; i2 < j4; i2++) {
                    this.s[i].push("");
                }
            }
            this.move(0, -y)
            by = y;
            this.y = y + this.y;
            by = 0;
        } else if (y >= this.s[0].length) {
            let j3 = this.s.length;
            let j4 = y - this.s[0].length + 1
            for (let i = 0; i < j3; i++) {
                for (let i2 = 0; i2 < j4; i2++) {
                    this.s[i].push("");
                }
            }
            by = y;
        } else {
            by = y;
        }
        this.s[bx][by] = st;
    }
    public move(x: number, y: number) {
        if (x != 0) {
            if (x > 0) {
                for (let i = 0; i < this.s.length; i++) {
                    let z = this.s[this.s.length - x - i - 1];
                    if (z == null) {
                        z = []
                        for (let i = 0; i < this.s[0].length; i++) {
                            z.push("")
                        }
                    }
                    this.s[this.s.length - i - 1] = z
                }
            }
        }
        if (y != 0) {
            if (y > 0) {
                for (let i2 = 0; i2 < this.s.length; i2++) {
                    for (let i = 0; i < this.s[0].length; i++) {
                        let z = this.s[i2][this.s[0].length - y - i - 1]
                        if (z == null) {
                            z = "";
                        }
                        this.s[i2][this.s[0].length - i - 1] = z
                    }
                }
            }
        }
    }
}
export class serverspeichern {
    constructor(public codes: string[], public adminCodes: string[], public clients: ClientData[] = [], public maps: map[], public Ränge: Rang[], public Sskin: skin, public blacklistedwords: string[], public ah: AhItem[], public bd: number, public ga: number, public comPr: commProjServerSave[]) {

    }
}
export class AhItem {
    static idhelp: number = 0;
    id: number = AhItem.idhelp++;
    constructor(public i: Item, public seller: string, public auction: boolean, public price: number, public buyer: string, public time: number) {

    }
}
export class skin {
    static Sskin: skin
    static hx = 6;
    static hy = 6;
    static ax = 4;
    static ay = 10;
    static bx = 6;
    static by = 10;
    static ix = 12;
    static iy = 26;
    constructor(public s: string) {

    }
    static getnumber(x: number, y: number, s: string[]) {
        if (y < skin.hx * skin.hy) {
            return s[x + skin.hx * y];
        } else if (y < 10) {
            return s[9 + x + y * 7];//geht nicht
        } else {
            return s[44 + x + y * 3];
        }
    }
    static getpixel(n: number) {
        let x = skin.ax;
        let y = 0;
        for (let i = 0; i < n; i++) {
            x++;
            let b;
            while (x < skin.ax && (y < skin.hy || y > skin.hy + skin.ay - 1)) {
                x++;
            }
            if (x > skin.ax - 1 && x < skin.ax + skin.hx) {
                b = true;
            }
            if (y > skin.hy - 1 && y < skin.hy + skin.ay) {
                b = true;
            }
            if (x > skin.ax * 2 + skin.hx - 1) {
                b = false
            }
            if (!b) {
                x = -1;
                y++;
            }
            if (x < 0) {
                x = 0
            }
            while (x < skin.ax && (y < skin.hy || y > skin.hy + skin.ay - 1)) {
                x++;
            }
        }
        return new Punkt(x, y);
    }
}
export class Punkt {
    constructor(public x: number, public y: number) {

    }
}

export class Inventar {
    maus: Item;
    itemslots: Itemslot[] = []
    static server: any;
    menu: Menu;
    ahItem: AhItem;
    page: number = 0;
    coll: Collection;
    last: Menu[] = [];
    recipe: Craft[];
    lastRecipe: Craft[][] = [];
    reforges: string[]
    npcShopItems: NPCShopItem[] = [];
    ggner: EnemyType;
    compro: string;
    constructor(public c: ClientData) {
        for (let i = 0; i < 10 * 9; i++) {
            this.itemslots.push(new Itemslot(this))
        }
        this.loadMenu(Menu.main);
    }
    buildClientInv() {
        let clits: ClientItem[] = []
        for (let i = 0; i < 10 * 9; i++) {
            clits.push(this.buildClientItem(this.itemslots[i].i))
        }
        let ClientInv = new ClientInventory(this.buildClientItem(this.maus), clits);
        return ClientInv;
    }
    buildClientItem(i: Itemslotfüller) {
        if (i instanceof Item) {
            if (Itemtyp.findtyp(i.itemtyp) == null) {
                return null;
            }
            let ui = i.UI(this.c);
            return new ClientItem(Itemtyp.findtyp(i.itemtyp).Name, Itemtyp.findtyp(i.itemtyp).texture, ui, i.itemtyp == "enchanted_book" && EnchantType.toArray(i.s[0]).length > 0 && EnchantType.toArray(i.s[0])[0] != null ? EnchantType.toArray(i.s[0])[0].tier : i.Amount)
        } else if (i instanceof Icon) {
            let h = i.ui(this.c, i);
            return new ClientItem("", i.texture, h, i.Amount)
        }
    }
    anvilHelp(): string[] {
        if (this.itemslots[26 + 3 + 9 * 4].i != null && this.itemslots[26 + 7 + 9 * 4].i != null && (<Item>this.itemslots[26 + 7 + 9 * 4].i).itemtyp == "enchanted_book") {
            let h1 = EnchantType.toArray((<Item>this.itemslots[26 + 3 + 9 * 4].i).s[0]);
            let h2 = EnchantType.toArray((<Item>this.itemslots[26 + 7 + 9 * 4].i).s[0]);
            for (let i = 0; i < h2.length; i++) {
                const e = h2[i];
                let h = h1.find((value) => { return value.enchant == e.enchant });
                let bo = false;
                for (let index = 0; index < EnchantType.findEnchant(e.enchant).types.length; index++) {
                    const eh = EnchantType.findEnchant(e.enchant).types[index];
                    if (Itemtyp.findtyp((<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp).typ.includes(eh)) {
                        bo = true;
                        break;
                    }
                }
                if (h == null && (bo || (<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp == "enchanted_book")) {
                    h1.push(e);
                }
                if (h == null) {
                    continue;
                }
                if (e.tier > h.tier) {
                    h.tier = e.tier;
                }
                if (e.tier == h.tier && EnchantType.findEnchant(e.enchant).maxTier > e.tier) {
                    h.tier++;
                }
            }
            let g = EnchantType.toString(h1);
            let j = (<Item>this.itemslots[26 + 3 + 9 * 4].i).s.slice();
            if (g == j[0]) {
                return null;
            }
            j[0] = g;
            return j;
        }
        if (this.itemslots[26 + 3 + 9 * 4].i != null && this.itemslots[26 + 7 + 9 * 4].i != null && (<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp == "enchanted_book" && (<Item>this.itemslots[26 + 7 + 9 * 4].i).itemtyp == "enchanted_book_ubgrade") {
            let h1 = EnchantType.toArray((<Item>this.itemslots[26 + 3 + 9 * 4].i).s[0]);
            let h2 = (<Item>this.itemslots[26 + 7 + 9 * 4].i).s[0];//enchant
            let h3 = (<Item>this.itemslots[26 + 7 + 9 * 4].i).s[1];//tier
            for (let i = 0; i < h1.length; i++) {
                const e = h1[i];
                if (e.enchant == h2 && (e.tier + 1) + "" == h3) {
                    e.tier++;
                    break;
                }
            }
            let h = (<Item>this.itemslots[26 + 3 + 9 * 4].i).s.slice();
            h[0] = EnchantType.toString(h1)
            if (h == (<Item>this.itemslots[26 + 3 + 9 * 4].i).s) {
                return null;
            }
            return h;
        }
        if (this.itemslots[26 + 3 + 9 * 4].i != null && this.itemslots[26 + 7 + 9 * 4].i != null && Itemtyp.findtyp((<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp).typ && (<Item>this.itemslots[26 + 7 + 9 * 4].i).itemtyp == "rune" && ((<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp == "rune" || ReforgeType.arrayCompare(Itemtyp.findtyp((<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp).typ, RuneType.findRune((<Item>this.itemslots[26 + 7 + 9 * 4].i).s[4]).types))) {
            let h2 = (<Item>this.itemslots[26 + 7 + 9 * 4].i).s[4];//rune
            let h3 = (<Item>this.itemslots[26 + 7 + 9 * 4].i).s[5];//tier
            let h4 = (<Item>this.itemslots[26 + 3 + 9 * 4].i).s[4];//rune
            let h5 = (<Item>this.itemslots[26 + 3 + 9 * 4].i).s[5];//tier
            let h = (<Item>this.itemslots[26 + 3 + 9 * 4].i).s.slice();
            if (h2 == h4) {
                if (h3 == h5) {
                    h[5] = Math.min(RuneType.findRune(h2).maxTier, (Number.parseFloat(h3) + 1)) + "";
                } else {
                    h[5] = Math.max(Number.parseFloat(h3), Number.parseFloat(h5)) + "";
                }
            } else {
                h[4] = h2;
                h[5] = h3;
            }
            if (h == (<Item>this.itemslots[26 + 3 + 9 * 4].i).s) {
                return null;
            }
            return h;
        }
        if (this.itemslots[26 + 3 + 9 * 4].i != null && this.itemslots[26 + 7 + 9 * 4].i != null && Itemtyp.findtyp((<Item>this.itemslots[26 + 7 + 9 * 4].i).itemtyp).typ.includes(itemtypes.reforge_stone)) {
            let j = (<Item>this.itemslots[26 + 3 + 9 * 4].i).s.slice();
            let g = (<Item>this.itemslots[26 + 7 + 9 * 4].i).s[2]
            if (g == j[2]) {
                return null;
            }
            j[2] = g;
            return j;
        }
        return null;
    }
    loadMenu(menu: Menu, goBackUbdate: boolean = true) {
        let that = this;
        if (this.menu == Menu.craft) {
            for (let x = 0; x < 3; x++) {
                for (let y = 0; y < 3; y++) {
                    Inventar.server.give(this.c.name, <Item>(this.itemslots[28 + (x) + 9 * (y + 2)].i), false);
                }
            }
        }
        if (this.menu == Menu.anvil) {
            Inventar.server.give(this.c.name, <Item>(this.itemslots[26 + 3 + 9 * 4].i), false);
            Inventar.server.give(this.c.name, <Item>(this.itemslots[26 + 7 + 9 * 4].i), false);
        }
        if (this.menu == Menu.npcReforge) {
            Inventar.server.give(this.c.name, <Item>(this.itemslots[26 + 5 + 9 * 3].i), false);
        }
        for (let i = 0; i < 54; i++) {
            let e = this.itemslots[i + 4 * 9];
            e.i = new Icon((key, c) => {
            }, (c) => {
                return "";
            }, "Platzhalter hellgrün.png", 1);
        }
        if (menu == Menu.main) {
            this.itemslots[26 + 4 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.skills)
            }, (c) => {
                return "skills";
            }, "Skills.png", 1);
            this.itemslots[26 + 5 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.craft)
            }, (c) => {
                return "crafting";
            }, "blocks/Crafting_table.png", 1);
            this.itemslots[26 + 5 + 9 * 2].i = new Icon((key, c) => {
            }, (c) => {
                let s = "stats:\n"+C.white2;
                c.calcStats();
                for (let i = 0; i < Item.stats.length; i++) {
                    const e = Item.stats[i];
                    s = s + Item.statnames[i] + ": " +Item.statcolors[i]+ c.stats[i] + "\n"+C.white2
                }
                return s;
            }, "Stats Logo.png", 1);
            this.itemslots[26 + 5 + 9 * 4].i = new Icon((key, c) => {
                that.loadMenu(Menu.wardrobe)
            }, (c) => {
                return "wardrobe";
            }, "golden chestplate.png", 1);
            this.itemslots[26 + 6 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections)
            }, (c) => {
                return "collections";
            }, "Collection Book.png", 1);
            this.itemslots[26 + 7 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.storage)
            }, (c) => {
                return "storage";
            }, "Collection Book.png", 1);
            this.itemslots[26 + 7 + 9 * 4].i = new Icon((key, c) => {
                that.loadMenu(Menu.minions)
            }, (c) => {
                return "minions";
            }, "Skills.png", 1);
        }
        if (menu == Menu.comm_proj) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            let c = CommunityProject.findComPro(this.compro);
            this.itemslots[26 + 1 + 9 * 1].i = new Icon((key, c) => {
            }, (cl) => {
                return c.name + "\nthis is a community project.\nYou can donate Items to help complete\nit and you might even get an extra reward\nat the end, depending on how much you\ncontributed!";
            }, "blocks/Platzhalter Dunkelgrün.png", 1);
            let h = Inventar.server.ce.find((value) => { return value.name == c.name });
            if (h == null) {
                Inventar.server.ce.push(new commProjServerSave(c.name, [], 0))
                h = Inventar.server.ce.find((value) => { return value.name == c.name });
            }
            this.itemslots[26 + 2 + 9 * 1].i = new Icon((key, c) => {
            }, () => {
                return "~00ff00your community project points: " + Inventar.server.ce.find((value) => { return value.name == c.name }).clients[Inventar.server.clients.indexOf(this.c)] + "\ntotal points: " + Inventar.server.ce.find((value) => { return value.name == c.name }).points + " / " + CommunityProject.findComPro(c.name).reqPoints;
            }, "blocks/Platzhalter Dunkelgrün.png", 1);
            for (let i = 0; i < c.comItems.length; i++) {
                const e = c.comItems[i];
                this.itemslots[26 + 1 + 9 * 2 + i].i = new Icon((key, cl) => {
                    if (Inventar.server.count(this.c.name, e.item) >= e.amount) {
                        Inventar.server.clear(this.c.name, e.item, e.amount);
                        let h = Inventar.server.ce.find((value) => { return value.name == c.name });
                        if (h.clients.length <= Inventar.server.clients.indexOf(this.c) || h.clients[Inventar.server.clients.indexOf(this.c)] == null) {
                            h.clients[Inventar.server.clients.indexOf(this.c)] = 0;
                        }
                        if (Number.isNaN(h.points)) {
                            h.points = 0;
                        }
                        h.clients[Inventar.server.clients.indexOf(this.c)] += e.points;
                        h.points += e.points;
                        if (h.points >= CommunityProject.findComPro(c.name).reqPoints) {
                            for (let j = 0; j < Inventar.server.clients.length; j++) {
                                const e2 = Inventar.server.clients[j];
                                if (e2.Inventar.menu == Menu.comm_proj) {
                                    e2.Inventar.loadMenu(Menu.main);
                                }
                            }
                            CommunityProject.findComPro(c.name).onEnd(Inventar.server.ce.find((value) => { return value.name == c.name }).clients);
                        }
                    }
                }, (c) => {
                    return "~00ff00" + e.item + " * " + e.amount + "\n=> " + e.points + "points";
                }, "Platzhalter rot.png", 1);
            }
        }
        if (menu == Menu.npcReforge) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 5 + 9 * 4].i = new Icon((key, c) => {
                if (this.itemslots[26 + 5 + 9 * 3].i != null) {
                    let ranInt = Math.floor(Math.random() * this.reforges.length);
                    (<Item>this.itemslots[26 + 5 + 9 * 3].i).s[2] = this.reforges[ranInt];
                }
            }, (c) => {
                return "~ffff00click to reforge item above";
            }, "Eisenbarren.png", 1);
            this.itemslots[26 + 5 + 9 * 3].i = null;
        }
        if (menu == Menu.minions) {
            this.page = 0;
            this.itemslots[26 + 2 + 9 * 6].i = new Icon((key, c) => {
                if (this.page >= 1) {
                    that.page--;
                    // that.loadMenu(Menu.storage);
                }
            }, (c) => {
                return this.page >= 1 ? "~00ff00go to page " + (this.page) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Rechts.png", 1);
            let maxslots = Number.parseFloat(this.c.md.pInfo[2]);
            this.itemslots[26 + 8 + 9 * 6].i = new Icon((key, c) => {
                if (that.page < maxslots / 9) {
                    that.page++;
                    // that.loadMenu(Menu.storage);
                }
            }, (c) => {
                return that.page < maxslots / 9 ? "~00ff00go to page " + (this.page + 2) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Links.png", 1);
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            for (let i = 1; i < 10; i++) {
                for (let j = 0; j < 5; j++) {
                    let unlocked2 = maxslots >= i + this.page * 9;
                    this.itemslots[26 + i + 9 * (5 - j)].i = new Icon((key, c) => {
                        let unlocked = maxslots >= i + this.page * 9;
                        if (unlocked && key == "left" && ((c.Inventar.maus != null && Itemtyp.findtyp(c.Inventar.maus.itemtyp).typ.includes(j != 4 ? (j != 0 ? itemtypes.minion_ubgrade : itemtypes.minion_storage) : itemtypes.minion)) || (c.Inventar.maus == null && c.items[(i + this.page * 9) * (5 + 9 * 5) - 5 + j + 500] != null))) {
                            let help = c.items[(i + this.page * 9) * (5 + 9 * 5) - 5 + j + 500];
                            c.items[(i + this.page * 9) * (5 + 9 * 5) - 5 + j + 500] = c.Inventar.maus;
                            c.Inventar.maus = help;
                        }
                        if (unlocked && key == "right") {
                            if (j == 0) {
                                this.page = this.page * 9 + i;
                                this.loadMenu(Menu.viewMinionChest)
                            }
                        }
                    }, (c, it) => {
                        let unlocked = maxslots >= i + this.page * 9;
                        it.texture = c.items[(i + this.page * 9) * (5 + 9 * 5) - 5 + j + 500] != null ? Itemtyp.findtyp(c.items[(i + this.page * 9) * (5 + 9 * 5) - 5 + j + 500].itemtyp).texture : (unlocked ? "Platzhalter hellgrün.png" : "Platzhalter rot.png");
                        return unlocked ? (c.items[(i + this.page * 9) * (5 + 9 * 5) - 5 + j + 500] != null ? c.items[(i + this.page * 9) * (5 + 9 * 5) - 5 + j + 500].UI(c) : "~00ff00empty " + (j != 4 ? (j != 0 ? "ubgrade" : "storage") : "minion") + " slot") : "~ff0000you haven't unlocked this slot yet.";
                    }, unlocked2 ? "Platzhalter grün" : "Platzhalter rot.png", 1);
                }
            }
        }
        if (menu == Menu.viewMinionChest) {
            let slots = (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500] != null ? Minion.findMinion(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[0]).storage[Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1])] : 0) + (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500] != null ? Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500].s[0]) : 0);
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.minions)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 9 + 9 * 6].i = new Icon((key, c) => {
                let h = (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500] != null ? Minion.findMinion(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[0]).cost[Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1])] : null);
                if (h != null && Inventar.server.count(c.name, h.item) >= h.amount) {
                    this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1] = "" + (Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1]) + 1);
                    slots = (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500] != null ? Minion.findMinion(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[0]).storage[Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1])] : 0) + (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500] != null ? Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500].s[0]) : 0);
                    Inventar.server.clear(c.name, h.item, h.amount);
                }
            }, (c) => {
                let h = (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500] != null ? Minion.findMinion(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[0]).cost[Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1])] : null);
                return h == null ? "~00ff00this minion is ubgraded to the max level." : "~ffff00lvl:" + Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1]) + " ~ffffffubgrade: " + h.amount + " * " + h.item;
            }, "Skills.png", 1);
            this.itemslots[26 + 2 + 9 * 6].i = new Icon((key, c) => {
                if (this.page > 1) {
                    that.page--;
                    slots = (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500] != null ? Minion.findMinion(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[0]).storage[Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1])] : 0) + (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500] != null ? Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500].s[0]) : 0);
                    // that.loadMenu(Menu.storage);
                }
            }, (c) => {
                return this.page > 1 ? "~00ff00go to page " + (this.page - 1) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Rechts.png", 1);
            let maxslots = Number.parseFloat(this.c.md.pInfo[2]);
            this.itemslots[26 + 8 + 9 * 6].i = new Icon((key, c) => {
                if (that.page < maxslots) {
                    that.page++;
                    slots = (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500] != null ? Minion.findMinion(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[0]).storage[Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 4 + 500].s[1])] : 0) + (this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500] != null ? Number.parseFloat(this.c.items[(this.page) * (5 + 9 * 5) - 5 + 0 + 500].s[0]) : 0);
                    // that.loadMenu(Menu.storage);
                }
            }, (c) => {
                return that.page < maxslots ? "~00ff00go to page " + (this.page + 1) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Links.png", 1);
            for (let i = 1; i < 10; i++) {
                for (let j = 0; j < 5; j++) {
                    this.itemslots[26 + i + 9 * (5 - j)].i = new Icon((key, c) => {
                        if (key == "left" && (c.Inventar.maus != null) || (c.Inventar.maus == null && c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500] != null)) {
                            // let help = c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500];
                            // c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500] = c.Inventar.maus;
                            // c.Inventar.maus = help;
                            if (Inventar.server.canGive(c.name, c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500])) {
                                Inventar.server.give(c.name, c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500], true);
                                c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500] = null;
                            } else {
                                Inventar.server.msg("~ff0000you don't have enough free space in your Inventory to add all items.", c.name, true);
                            }
                        }
                    }, (c, it) => {
                        it.texture = c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500] != null ? Itemtyp.findtyp(c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500].itemtyp).texture : (j * 9 + i <= slots ? "" : "Platzhalter rot.png");
                        it.Amount = c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500] != null ? c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500].Amount : 1;
                        return c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500] != null ? c.items[this.page * (5 + 9 * 5) + i + j * 9 + 500].UI(c) : (j * 9 + i <= slots ? "" : "this slot is locked.");
                    }, j * 9 + i <= slots ? "" : "Platzhalter rot.png", 1);
                }
            }
        }
        if (menu == Menu.storage) {
            let maxslots = Number.parseFloat(this.c.md.pInfo[0]);
            if (this.menu != Menu.storage) {
                this.page = 1;
            }
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 2 + 9 * 6].i = new Icon((key, c) => {
                if (this.page > 1) {
                    that.page--;
                    that.loadMenu(Menu.storage);
                }
            }, (c) => {
                return this.page > 1 ? "~00ff00go to page " + (this.page - 1) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 8 + 9 * 6].i = new Icon((key, c) => {
                if (that.page * 45 < maxslots) {
                    that.page++;
                    that.loadMenu(Menu.storage);
                }
            }, (c) => {
                for (let j = 0; j < 5; j++) {
                    for (let i = 1; i < 10; i++) {
                        if (!(j * 9 - 1 + i + (this.page - 1) * 5 * 9 >= maxslots)) {
                            this.c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9] = <Item>this.itemslots[26 + i + 9 * (5 - j)].i;
                        }
                    }
                }
                return that.page * 45 < maxslots ? "~00ff00go to page " + (this.page + 1) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Links.png", 1);
            for (let j = 0; j < 5; j++) {
                for (let i = 1; i < 10; i++) {
                    if (j * 9 - 1 + i + (this.page - 1) * 5 * 9 >= maxslots) {
                        this.itemslots[26 + i + 9 * (5 - j)].i = new Icon((key, c) => {
                        }, (c) => {
                            return "~ff0000you haven't unlocked this slot yet.";
                        }, "Platzhalter rot.png", 1);
                    } else {
                        this.itemslots[26 + i + 9 * (5 - j)].i = this.c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9];
                        // this.itemslots[26 + i + 9 * (5 - j)].i = new Icon((key, c) => {
                        //     if ((c.Inventar.maus != null) || (c.Inventar.maus == null && c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9] != null)) {
                        //         let help = c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9];
                        //         c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9] = c.Inventar.maus;
                        //         c.Inventar.maus = help;
                        //     }
                        // }, (c, it) => {
                        //     it.texture = c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9] != null ? Itemtyp.findtyp(c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9].itemtyp).texture : "";
                        //     return c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9] != null ? c.items[i * 5 - 5 + j + 36 + (this.page - 1) * 5 * 9].UI(c) : "";
                        // }, "Platzhalter rot.png", 1);
                    }
                }
            }
        }
        if (menu == Menu.wardrobe) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            for (let i = 1; i < 10; i++) {
                this.itemslots[26 + i + 9 * 5].i = new Icon((key, c) => {
                    if (c.selArmSlot == i) {
                        c.selArmSlot = 0;
                    } else {
                        c.selArmSlot = i;
                    }
                }, (c, it) => {
                    it.texture = c.selArmSlot == i ? "Platzhalter hellgrün.png" : "Platzhalter grau.png";
                    return "slot " + i;
                }, "Platzhalter grau.png", 1);
                for (let j = 0; j < 4; j++) {
                    this.itemslots[26 + i + 9 * (4 - j)].i = new Icon((key, c) => {
                        if ((c.Inventar.maus != null && Itemtyp.findtyp(c.Inventar.maus.itemtyp).typ.includes(j == 0 ? itemtypes.schuhe : (j == 1 ? itemtypes.hose : (j == 2 ? itemtypes.burstplatte : itemtypes.helm)))) || (c.Inventar.maus == null && c.items[i * 4 - 4 + j] != null)) {
                            let help = c.items[i * 4 - 4 + j];
                            c.items[i * 4 - 4 + j] = c.Inventar.maus;
                            c.Inventar.maus = help;
                        }
                    }, (c, it) => {
                        it.texture = c.items[i * 4 - 4 + j] != null ? Itemtyp.findtyp(c.items[i * 4 - 4 + j].itemtyp).texture : "Platzhalter rot.png";
                        return c.items[i * 4 - 4 + j] != null ? c.items[i * 4 - 4 + j].UI(c) : "~00ff00empty " + (j == 0 ? "boots" : (j == 1 ? "leggings" : (j == 2 ? "chestplate" : "helmet"))) + " slot";
                    }, "Platzhalter rot.png", 1);
                }
                // this.itemslots[26 + i + 9 * 3].i = new Icon((key, c) => {

                // }, (c) => {
                //     return "empty leggings slot";
                // }, "Platzhalter rot.png", 1);
                // this.itemslots[26 + i + 9 * 2].i = new Icon((key, c) => {

                // }, (c) => {
                //     return "empty chestplate slot";
                // }, "Platzhalter rot.png", 1);
                // this.itemslots[26 + i + 9 * 1].i = new Icon((key, c) => {

                // }, (c) => {
                //     return "empty helmet slot";
                // }, "Platzhalter rot.png", 1);
            }
        }
        if (menu == Menu.collections) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 5 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections_combat)
            }, (c) => {
                return "~00ff00combat";
            }, "eisenschwert.png", 1);
            this.itemslots[26 + 4 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections_mining)
            }, (c) => {
                return "~00ff00mining";
            }, "Eisenspitzhacke.png", 1);
            this.itemslots[26 + 6 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections_foraging)
            }, (c) => {
                return "~00ff00foraging";
            }, "Eisenaxt.png", 1);
            this.itemslots[26 + 5 + 9 * 4].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections_farming)
            }, (c) => {
                return "~00ff00farming";
            }, "Eisensense.png", 1);
        }
        if (menu == Menu.collections_combat) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            let i2 = 0;
            for (let i = 0; i < Collection.collections.length; i++) {
                const e = Collection.collections[i];
                if (e.category == ColCat.combat) {
                    this.itemslots[26 + i2 % 9 + 1 + 9 * (Math.floor(i2 / 9) + 1)].i = new Icon((key, c) => {
                        this.coll = e;
                        that.loadMenu(Menu.viewCollection)
                    }, (c) => {
                        return e.item;
                    }, Itemtyp.findtyp(e.item) != null ? Itemtyp.findtyp(e.item).texture : "", 1);
                    i2++;
                }
            }
        }
        if (menu == Menu.collections_mining) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            let i2 = 0;
            for (let i = 0; i < Collection.collections.length; i++) {
                const e = Collection.collections[i];
                if (e.category == ColCat.mining) {
                    this.itemslots[26 + i2 % 9 + 1 + 9 * (Math.floor(i2 / 9) + 1)].i = new Icon((key, c) => {
                        this.coll = e;
                        that.loadMenu(Menu.viewCollection)
                    }, (c) => {
                        return e.item;
                    }, Itemtyp.findtyp(e.item) != null ? Itemtyp.findtyp(e.item).texture : "", 1);
                    i2++;
                }
            }
        }
        if (menu == Menu.collections_foraging) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            let i2 = 0;
            for (let i = 0; i < Collection.collections.length; i++) {
                const e = Collection.collections[i];
                if (e.category == ColCat.foraging) {
                    this.itemslots[26 + i2 % 9 + 1 + 9 * (Math.floor(i2 / 9) + 1)].i = new Icon((key, c) => {
                        this.coll = e;
                        that.loadMenu(Menu.viewCollection)
                    }, (c) => {
                        return e.item;
                    }, Itemtyp.findtyp(e.item) != null ? Itemtyp.findtyp(e.item).texture : "", 1);
                    i2++;
                }
            }
        }
        if (menu == Menu.collections_farming) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            let i2 = 0;
            for (let i = 0; i < Collection.collections.length; i++) {
                const e = Collection.collections[i];
                if (e.category == ColCat.farming) {
                    this.itemslots[26 + i2 % 9 + 1 + 9 * (Math.floor(i2 / 9) + 1)].i = new Icon((key, c) => {
                        this.coll = e;
                        that.loadMenu(Menu.viewCollection)
                    }, (c) => {
                        return e.item;
                    }, Itemtyp.findtyp(e.item) != null ? Itemtyp.findtyp(e.item).texture : "", 1);
                    i2++;
                }
            }
        }
        if (menu == Menu.viewRecipe) {
            let a2: Item[] = [];
            let cr = Collection.allRecipes();
            cr.push(...Craft.crafts);
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.goBack();
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 2 + 9 * 6].i = new Icon((key, c) => {
                if (this.page > 0) {
                    that.page--;
                    that.loadMenu(Menu.viewRecipe, false);
                }
            }, (c) => {
                return this.page > 0 ? "~00ff00go to page " + (this.page) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 8 + 9 * 6].i = new Icon((key, c) => {
                if (that.page < this.recipe.length - 1) {
                    that.page++;
                    that.loadMenu(Menu.viewRecipe, false);
                }
            }, (c) => {
                return that.page < this.recipe.length - 1 ? "~00ff00go to page " + (this.page + 2) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Links.png", 1);
            if (that.recipe == null || that.recipe[that.page] == null) {
                return;
            }
            for (let x = 0; x < 3; x++) {
                for (let y = 0; y < 3; y++) {
                    if (that.recipe[that.page].recipe[x + 3 * y] == "") {
                        this.itemslots[26 + 2 + x + 9 * (2 + y)].i = new Icon((key, c) => {
                        }, (c) => {
                            return "";
                        }, "", 1);
                        a2.push(null)
                        continue;
                    }
                    let n = 1;
                    let a = that.recipe[that.page].recipe[x + 3 * y];
                    let b: boolean = false;
                    for (let i = 0; i < that.recipe[that.page].recipe[x + 3 * y].length; i++) {
                        const e = that.recipe[that.page].recipe[x + 3 * y][i];
                        if (e == "*") {
                            n = Number.parseFloat(that.recipe[that.page].recipe[x + 3 * y].slice(0, i));
                            a = that.recipe[that.page].recipe[x + 3 * y].slice(i + 1, that.recipe[that.page].recipe[x + 3 * y].length);
                        }
                    }
                    let h = new Item(a, n, []);
                    a2.push(h);
                    let re: Craft[] = [];
                    for (let i = 0; i < cr.length; i++) {
                        const e = cr[i];
                        if (e.itemtyp == h.itemtyp) {
                            re.push(e);
                        }
                    }
                    if (Itemtyp.findtyp(h.itemtyp) == null) {
                        console.log("missing Item: \"" + h.itemtyp + "\"")
                    }
                    this.itemslots[26 + 2 + x + 9 * (2 + y)].i = new Icon((key, c) => {
                        if (re.length > 0) {
                            that.recipe = re;
                            that.loadMenu(Menu.viewRecipe);
                        }
                    }, (c) => {
                        return h.UI(c);
                    }, Itemtyp.findtyp(h.itemtyp).texture, h.Amount);
                }
            }
            let h = that.recipe[that.page].item([], this.c);
            this.itemslots[26 + 8 + 9 * 3].i = new Icon((key, c) => {
            }, (c) => {
                return h.UI(c);
            }, Itemtyp.findtyp(h.itemtyp).texture, h.Amount);
        }
        if (menu == Menu.viewCollection) {
            that.page = 0;
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.goBack();
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 2 + 9 * 6].i = new Icon((key, c) => {
                if (this.page > 0) {
                    that.page--;
                }
            }, (c) => {
                return this.page > 1 ? "~00ff00go to page " + (this.page - 1) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 8 + 9 * 6].i = new Icon((key, c) => {
                if ((that.page + 1) * 45 < this.coll.rewards.length) {
                    that.page++;
                }
            }, (c) => {
                return this.page < this.coll.rewards.length ? "~00ff00go to page " + (this.page + 1) : "~ff0000you can't go any further in this direction.";
            }, "Pfeil Links.png", 1);
            for (let i = 0; i < 45; i++) {
                if (i + this.page * 5 * 9 >= this.coll.cost.length) {
                    break;
                }
                let e = this.coll.cost[i + this.page * 9 * 5];
                let e2 = (i == 0 && this.page == 0) || this.coll.category != ColCat.skills ? 0 : this.coll.cost[i - 1 + this.page * 9 * 5];
                let exp = Collection.getClientCollectionAmount(this.c, this.coll.item)
                this.itemslots[26 + i + 1 + 9 * 1].i = new Icon((key, c) => {
                    if (this.coll.rewards[i + this.page * 9 * 5] != null && this.coll.rewards[i + this.page * 9 * 5].length > 0) {
                        e = this.coll.cost[i + this.page * 9 * 5];
                        that.recipe = this.coll.rewards[i + this.page * 9 * 5];
                        that.loadMenu(Menu.viewRecipe)
                    }
                }, (c, ic) => {
                    e2 = (i == 0 && this.page == 0) || this.coll.category != ColCat.skills ? 0 : this.coll.cost[i - 1 + this.page * 9 * 5];
                    e = this.coll.cost[i + this.page * 9 * 5];
                    exp = Collection.getClientCollectionAmount(this.c, this.coll.item)
                    let s: string = "";
                    if (this.coll.rewards[i + this.page * 9 * 5] == null) {
                        ic.texture = "Platzhalter hellgrün.png";
                        return "";
                    }
                    for (let j = 0; j < this.coll.rewards[i + this.page * 9 * 5].length; j++) {
                        const e2 = this.coll.rewards[i + this.page * 9 * 5][j];
                        let h = Item.getName(e2.itemtyp);
                        if (h == null) {
                            console.log("missing item: " + e2.itemtyp);
                        }
                        let ha = "";
                        while (h != ha) {
                            ha = h;
                            h = h.replace("_", " ");
                        }
                        s = s + h + "\n";
                    }
                    let a = 30;
                    s = s + "\n~00ff00";
                    for (let k = 0; k < a; k++) {
                        if (a * exp / e >= k && a * exp / e < k + 1) {
                            s = s + "~ff0000";
                        }
                        s = s + "-";
                    }
                    s = s + "~ffffff\n" + (exp - e2) + " / " + (e - e2) + "(" + (Math.floor(exp / e * 100) < 100 ? Math.floor(exp / e * 100) : 100) + "%)";
                    return s;
                }, "Platzhalter " + (exp >= e ? "hellgrün" : "rot") + ".png", 1);
            }
        }
        if (menu == Menu.anvil) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 3 + 9 * 3].i = new Icon((key, c) => {

            }, (c) => {
                return "";
            }, "Platzhalter grau.png", 1);
            this.itemslots[26 + 4 + 9 * 3].i = new Icon((key, c) => {

            }, (c) => {
                return "";
            }, "Platzhalter grau.png", 1);
            this.itemslots[26 + 5 + 9 * 3].i = new Icon((key, c) => {
                if (this.anvilHelp() != null) {
                    this.maus = new Item((<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp, (<Item>this.itemslots[26 + 3 + 9 * 4].i).Amount, this.anvilHelp());
                    this.itemslots[26 + 7 + 9 * 4].i = null;
                    this.itemslots[26 + 3 + 9 * 4].i = null;
                }
            }, (c, i) => {
                if (this.anvilHelp() != null) {
                    let h = new Item((<Item>this.itemslots[26 + 3 + 9 * 4].i).itemtyp, (<Item>this.itemslots[26 + 3 + 9 * 4].i).Amount, this.anvilHelp());
                    i.texture = Itemtyp.findtyp(h.itemtyp).texture;
                    return h.UI(c);
                } else {
                    i.texture = "";
                    return "";
                }
            }, "", 1);
            this.itemslots[26 + 6 + 9 * 3].i = new Icon((key, c) => {

            }, (c) => {
                return "";
            }, "Platzhalter grau.png", 1);
            this.itemslots[26 + 7 + 9 * 3].i = new Icon((key, c) => {

            }, (c) => {
                return "";
            }, "Platzhalter grau.png", 1);
            this.itemslots[26 + 7 + 9 * 4].i = null;
            this.itemslots[26 + 3 + 9 * 4].i = null;
            // this.itemslots[26 + 5 + 9 * 3].i = null;
            // this.itemslots[26 + 5 + 9 * 5].i = null;
            // this.itemslots[26 + 5 + 9 * 4].i = new Icon((key, c) => {
            //     if(this.itemslots[26 + 5 + 9 * 3].i!=null&&this.itemslots[26 + 5 + 9 * 5].i!=null&&(<Item>this.itemslots[26 + 5 + 9 * 3].i).itemtyp=="enchanted_book"){
            //         for (let i = 0; i < (<Item>this.itemslots[26 + 5 + 9 * 3].i).s.length; i++) {
            //             const e = (<Item>this.itemslots[26 + 5 + 9 * 3].i).s[i];
            //             if(e==","){

            //             }
            //         }
            //         this.itemslots[26 + 5 + 9 * 3].i=null;
            //     }
            // }, (c) => {
            //     return "click to combine these two items! (someone pls draw an arrow that points down!)";
            // }, "Pfeil Rechts.png", 1);
        }
        if (menu == Menu.skills) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            let i2 = 0;
            for (let i = 0; i < Collection.collections.length; i++) {
                const e = Collection.collections[i];
                if (e.category == ColCat.skills) {
                    this.itemslots[26 + i2 % 9 + 1 + 9 * (Math.floor(i2 / 9) + 1)].i = new Icon((key, c) => {
                        this.coll = e;
                        that.loadMenu(Menu.viewCollection)
                    }, (c) => {
                        return e.item;
                    }, Itemtyp.findtyp(e.item) != null ? Itemtyp.findtyp(e.item).texture : "Skills.png", 1);
                    i2++;
                }
            }
        }
        if (menu == Menu.npc_shop) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.collections)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 5 + 9 * 6].i = null;
            this.itemslots[26 + 4 + 9 * 6].i = new Icon((key, c) => {
                if (this.itemslots[26 + 5 + 9 * 6].i != null) {
                    c.geld += Itemtyp.findtyp((<Item>this.itemslots[26 + 5 + 9 * 6].i).itemtyp).npcSellValue * (<Item>this.itemslots[26 + 5 + 9 * 6].i).Amount;
                    this.itemslots[26 + 5 + 9 * 6].i = null;
                }
            }, (c) => {
                return "~ff0000click to sell the item in the middle slot! upon doing this, you will gain ~ffff00" + ((<Item>this.itemslots[26 + 5 + 9 * 6].i) == null ? 0 : Itemtyp.findtyp((<Item>this.itemslots[26 + 5 + 9 * 6].i).itemtyp).npcSellValue * (<Item>this.itemslots[26 + 5 + 9 * 6].i).Amount) + " ~ff0000coins.";
            }, "blocks/ERROR.png", 1);
            for (let i = 0; i < this.npcShopItems.length; i++) {
                const e = this.npcShopItems[i];
                let it: Item = (typeof e.item == 'string') ? new Item(e.item, 1, []) : e.item();
                this.itemslots[26 + 1 + 9 * 1 + i].i = new Icon((key, c) => {
                    if (c.geld >= e.cost) {
                        for (let i = 0; i < e.items.length; i++) {
                            const e2 = e.items[i];
                            if (Inventar.server.count(c.name, e2.itemtyp) < e2.Amount) {
                                Inventar.server.msg("~ff0000you don't have enough " + e2.name() + "~ff0000.", c.name, true)
                                return;
                            }
                        }
                        for (let i = 0; i < e.items.length; i++) {
                            const e2 = e.items[i];
                            Inventar.server.clear(c.name, e2.itemtyp, e2.Amount);
                        }
                        it = (typeof e.item == 'string') ? new Item(e.item, 1, []) : e.item();
                        c.geld -= e.cost;
                        Inventar.server.give(c.name, it, false);
                    } else {
                        Inventar.server.msg("~ff0000you don't have enough coins.", c.name, true)
                    }
                }, (c) => {
                    let s = "";
                    for (let i = 0; i < e.items.length; i++) {
                        const e2 = e.items[i];
                        s = s + "\n" + e2.name() + " * " + (Inventar.server.count(this.c.name, e2.itemtyp) < e2.Amount ? "~ff0000" : "~00ff00") + e2.Amount;
                    }
                    return it.UI(c) + "\n~ffffffcost: ~ffff00" + e.cost + s;
                }, Itemtyp.findtyp(it.itemtyp).texture, 1);
            }
        }
        if (menu == Menu.ah_main) {
            this.itemslots[26 + 3 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_buy)
            }, (c) => {
                return "~00ff00buy";
            }, "Platzhalter gelb.png", 1);
            this.itemslots[26 + 5 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_viewBids)
            }, (c) => {
                return "~00ff00items you bid on";
            }, "Goldschwert.png", 1);
            this.itemslots[26 + 7 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_viewOwnAuctions)
            }, (c) => {
                return "~00ff00sell";
            }, "Goldschaufel.png", 1);
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
        }
        if (menu == Menu.ah_buy || menu == Menu.ah_viewBids || menu == Menu.ah_viewOwnAuctions) {
            that.page = 0;
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 2 + 9 * 6].i = new Icon((key, c) => {
                if (this.page > 0) {
                    this.page--;
                }
            }, (c) => {
                return "~00ff00page " + (this.page + 1) + "\nclick to go back a page";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 8 + 9 * 6].i = new Icon((key, c) => {
                if (this.page * 5 * 9 < Inventar.server.ah.length) {
                    this.page++;
                }
            }, (c) => {
                return "~00ff00page " + (this.page + 1) + "\nclick to go a page further";
            }, "Pfeil Links.png", 1);
            let i = 0;
            for (let y = 0; y < 5; y++) {
                for (let x = 0; x < 9; x++) {
                    if (i >= Inventar.server.ah.length) {
                        this.itemslots[36 + x + 9 * y].i = new Icon((key, c) => { }, (c) => { return ""; }, "", 1);
                        i++;
                        continue;
                    }
                    let it = Inventar.server.ah[i];
                    let bo: boolean = true;
                    while (menu == Menu.ah_viewBids && it.buyer != this.c.name && !(i >= Inventar.server.ah.length)) {
                        i++;
                        bo = false;
                    }
                    while (menu == Menu.ah_viewOwnAuctions && it.seller != this.c.name && !(i >= Inventar.server.ah.length)) {
                        i++;
                        bo = false;
                    }
                    if (bo) {
                        this.itemslots[36 + x + 9 * y].i = new Icon((key, c) => {
                            if (c.name != it.seller) {
                                this.ahItem = it;
                                this.loadMenu(Menu.ah_buy_item);
                            }
                        }, (c, i) => { i.Amount = it.i.Amount; return it.i.UI(c) + "\n~00ff00" + (it.auction ? "auction" : "buy it now") + "\nprice: ~ffff00" + it.price + "\n~00ff00seller: " + it.seller + "\nremaining time: " + Math.floor(it.time / 3600000) + "h " + Math.floor((it.time - Math.floor(it.time / 3600000) * 3600000) / 60000) + "m " + Math.floor((it.time - Math.floor(it.time / 60000) * 60000) / 1000) + "s"; }, Itemtyp.findtyp(it.i.itemtyp).texture, 1);
                    } else {
                        this.itemslots[36 + x + 9 * y].i = new Icon((key, c) => { }, (c) => { return ""; }, "", 1);
                    }
                    i++;
                }
            }
        }
        if (menu == Menu.view_rare_drops) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.goBack();
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            for (let i = 0; i < this.ggner.drops.length; i++) {
                const e = this.ggner.drops[i];
                this.itemslots[26 + i % 9 + 1 + 1 + 9 * (Math.floor(i / 9) + 1 + 1)].i = new Icon((key, c) => {

                }, (c) => {
                    return "" + e.item + "\n" + (e.rarity * 100) + "%\n" + (e.minCount * e.maxcount == 1 ? "" : e.minCount + " - " + e.maxcount + "");
                }, Itemtyp.findtyp(this.coll.item) != null ? Itemtyp.findtyp(this.coll.item).texture : "Skills.png", 1);
            }
        }
        if (menu == Menu.view_slayer) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.slayers_main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 3 + 9 * 4].i = new Icon((key, c) => {
                that.loadMenu(Menu.viewCollection)
            }, (c) => {
                let h = 0;
                let h2 = Collection.getClientCollectionAmount(c, this.coll.item);
                let coll = Collection.findCollection(this.coll.item);
                if (coll == null) {
                    return;
                }
                for (let i = 0; i < coll.cost.length; i++) {
                    const e = coll.cost[i];
                    if (h2 < e) {
                        break;
                    } else {
                        h++;
                    }
                }
                return "lvl. " + h;
            }, "Goldschaufel.png", 1);
            let i2 = 0;
            for (let i = 0; i < 7; i++) {
                this.itemslots[26 + 2 + i + 9 * 2].i = new Icon((key, c) => {
                }, (c) => {
                    return "~ff0000COMING SOON";
                }, "Platzhalter rot.png", 1);
            }
            for (let i = 0; i < this.coll.bosses.length; i++) {
                const e = this.coll.bosses[i];
                this.itemslots[26 + i2 % 9 + 1 + 1 + 9 * (Math.floor(i2 / 9) + 1 + 1)].i = new Icon((key, c) => {
                    if (this.c.md.pInfo[3] != "") {
                        Inventar.server.msg("~ff0000you already gave an active slayer quest. If you start another one now, your progress will be lost.", this.c.name, true);
                    }
                    that.ggner = e;
                    that.loadMenu(Menu.confirmSlayerQuestStart)
                }, (c) => {
                    return this.coll.item + " " + EnchantType.numberToString(i + 1) + "\nhealth: ~ff0000" + e.health;
                }, Itemtyp.findtyp(this.coll.item) != null ? Itemtyp.findtyp(this.coll.item).texture : "Skills.png", 1);
                i2++;
            }
        }
        if (menu == Menu.confirmSlayerQuestStart) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.view_slayer)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 7 + 9 * 3].i = new Icon((key, c) => {
                that.loadMenu(Menu.slayers_main)
            }, (c) => {
                return "~ff0000cancel";
            }, "Platzhalter rot.png", 1);
            this.itemslots[26 + 5 + 9 * 4].i = new Icon((key, c) => {
                // this.ggner = this.coll.bosses[this.coll.bosses.length - 1];
                that.loadMenu(Menu.view_rare_drops)
            }, (c) => {
                return "~00ff00rare drops";
            }, "goldschwertgut.png", 1);
            this.itemslots[26 + 3 + 9 * 3].i = new Icon((key, c) => {
                that.c.md.pInfo[3] = this.coll.item;
                that.c.md.pInfo[4] = this.coll.bosses.indexOf(that.ggner) + "";
                that.c.md.pInfo[5] = "0";
                that.loadMenu(Menu.view_slayer)
            }, (c) => {
                return "~00ff00confirm";
            }, "Goldsense.png", 1);
        }
        if (menu == Menu.slayers_main) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            for (let i = 0; i < 9; i++) {
                this.itemslots[26 + i + 1 + 9 * 4].i = new Icon((key, c) => { }, (c, ic) => {
                    if (this.c.md.pInfo[3] != "") {
                        ic.texture = Number.parseFloat(c.md.pInfo[5]) >= ((Number.parseFloat(c.md.pInfo[4]) + 1) * 500) / 9 * (i + 1) ? "Platzhalter grau.png" : "Platzhalter rot.png";
                        return Number.parseFloat(c.md.pInfo[5]) + " / " + ((Number.parseFloat(c.md.pInfo[4]) + 1) * 500) + " exp";
                    } else {
                        ic.texture = "Platzhalter hellgrün.png";
                        return "";
                    }
                }, "Pfeil Rechts.png", 1);
            }
            let i2 = 0;
            for (let i = 0; i < Collection.collections.length; i++) {
                const e = Collection.collections[i];
                if (e.category == ColCat.slayer) {
                    this.itemslots[26 + i2 % 9 + 1 + 9 * (Math.floor(i2 / 9) + 1) + 10].i = new Icon((key, c) => {
                        this.coll = e;
                        that.loadMenu(Menu.view_slayer)
                    }, (c) => {
                        return e.item;
                    }, Itemtyp.findtyp(e.item) != null ? Itemtyp.findtyp(e.item).texture : "Skills.png", 1);
                    i2++;
                }
            }
        }
        if (menu == Menu.ah_viewBids) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
        }
        if (menu == Menu.ah_buy_item) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                this.ahItem = null;
                that.loadMenu(Menu.ah_buy)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 5 + 9 * 3].i = new Icon((key, c) => { }, (c, i) => {
                i.Amount = this.ahItem.i.Amount; return this.ahItem.i.UI(c);
            }, Itemtyp.findtyp(this.ahItem.i.itemtyp).texture, 1);
            this.itemslots[26 + 5 + 9 * 5].i = new Icon((key, c) => {
                if (this.ahItem.auction) {
                    if (Number.parseFloat((<Icon>(this.itemslots[26 + 5 + 9 * 5].i)).help[0]) <= this.ahItem.price) {
                        return;
                    }
                    let price = Number.parseFloat((<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0]);
                    if (price <= c.geld) {
                        if (this.ahItem.buyer != "") {
                            Inventar.server.findPlayer(this.ahItem.buyer).geld += this.ahItem.price;
                            if (c.name != this.ahItem.buyer) {
                                Inventar.server.msg("~ff0000" + c.name + " outbid you by " + (price - this.ahItem.price), this.ahItem.buyer, true);
                                Inventar.server.update(this.ahItem.buyer);
                            }
                        }
                        c.geld -= price;
                        this.ahItem.price = price;
                        this.ahItem.buyer = c.name;
                        Inventar.server.msg("~00ff00" + c.name + " just bid " + price + " coins on your " + this.ahItem.i.itemtyp + ".", this.ahItem.seller, true);
                        // that.loadMenu(Menu.ah_buy)
                    }
                } else {
                    if (this.ahItem.price <= c.geld) {
                        c.geld -= this.ahItem.price;
                        Inventar.server.update(c.name);
                        Inventar.server.give(c.name, this.ahItem.i, false);
                        Inventar.server.findPlayer(this.ahItem.seller).geld += this.ahItem.price;
                        Inventar.server.update(this.ahItem.seller);
                        Inventar.server.msg("~00ff00" + c.name + " just bought your " + this.ahItem.i.itemtyp + " for " + this.ahItem.price + " coins.", this.ahItem.seller, true);
                        Inventar.server.ah.splice(Inventar.server.ah.indexOf(this.ahItem), 1);
                        this.ahItem = null;
                        that.loadMenu(Menu.ah_buy)
                    }
                }
            }, (c) => {
                if (this.ahItem.auction) {
                    if (Number.parseFloat((<Icon>(this.itemslots[26 + 5 + 9 * 5].i)).help[0]) <= this.ahItem.price) {
                        (<Icon>(this.itemslots[26 + 5 + 9 * 5].i)).help[0] = (this.ahItem.price + 1) + "";
                    }
                    return "~00ff00click to bid\ncurrent highest bid: " + this.ahItem.price + (this.ahItem.buyer == "" ? "" : " by " + this.ahItem.buyer) + "\nyour bid: " + (<Icon>(this.itemslots[26 + 5 + 9 * 5].i)).help[0] + "\ntype \"/# [your bid]\" to change the amount" + "\nremaining time: " + Math.floor(this.ahItem.time / 3600000) + "h " + Math.floor((this.ahItem.time - Math.floor(this.ahItem.time / 3600000) * 3600000) / 60000) + "m " + Math.floor(this.ahItem.time - Math.floor((this.ahItem.time) / 60000) * 60000) / 1000 + "s";
                } else {
                    return "buy this item for " + this.ahItem.price;
                }
            }, "Platzhalter gelb.png", 1);
            (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0] = (this.ahItem.price + 1) + "";
        }
        if (menu == Menu.ah_sell) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_viewOwnAuctions)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 5 + 9 * 2].i = null;
            this.itemslots[26 + 5 + 9 * 4].i = new Icon((key, c) => {
                if (this.itemslots[26 + 5 + 9 * 2].i != null) {
                    Inventar.server.ah.push(new AhItem(<Item>this.itemslots[26 + 5 + 9 * 2].i, c.name, (<Icon>this.itemslots[26 + 3 + 9 * 5].i).help[0] == "a", Number.parseFloat((<Icon>this.itemslots[26 + 7 + 9 * 5].i).help[0]), "", Number.parseFloat((<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0])));
                    (<Item>(this.itemslots[26 + 5 + 9 * 2].i)).i = null;
                    this.itemslots[26 + 5 + 9 * 2].i = null;
                }
            }, (c) => {
                return "~00ff00confirm";
            }, "Platzhalter gelb.png", 1);
            this.itemslots[26 + 3 + 9 * 5].i = new Icon((key, c, i) => {
                if (i.help[0] == "a") {
                    i.help[0] = "b";
                } else if (i.help[0] == "b") {
                    i.help[0] = "a";
                }
            }, (c, i) => {
                if (i.help.length == 0) {
                    i.help[0] = "a";
                }
                if (i.help[0] == "a") {
                    return "auction\nclick to swich to buy it now";
                } else {
                    return "buy it now\nclick to swich to auction";
                }
            }, "Platzhalter grau.png", 1);
            this.itemslots[26 + 7 + 9 * 5].i = new Icon((key, c) => {
            }, (c, i) => {
                return "set price by typing \"/# [your desired price]\"\ncurrent price: ~ffff00" + i.help[0];
            }, "goldschwertgut.png", 1);
            this.itemslots[26 + 5 + 9 * 5].i = new Icon((key, c) => {
            }, (c, i) => {
                return "set time by typing for example \"/# 1h,5m,17s\" to set the time to 1 hour 5 minutes and 17 seconds\ncurrent time: " + Math.floor((Number.parseFloat(i.help[0])) / 3600000) + "h " + Math.floor(((Number.parseFloat(i.help[0])) - Math.floor((Number.parseFloat(i.help[0])) / 3600000) * 3600000) / 60000) + "m " + Math.floor((Number.parseFloat(i.help[0])) - Math.floor(((Number.parseFloat(i.help[0]))) / 60000) * 60000) / 1000 + "s";
            }, "goldaxt.png", 1);
            (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0] = "3600000";
            (<Icon>this.itemslots[26 + 7 + 9 * 5].i).help[0] = "100";
        }
        if (menu == Menu.ah_viewOwnAuctions) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 9 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.ah_sell)
            }, (c) => {
                return "create new Auction";
            }, "Goldschaufel.png", 1);
        }
        if (menu == Menu.presents_send) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            for (let i = 0; i < 9 * 5; i++) {
                this.itemslots[26 + 1 + 9 * 1 + i].i = null;
            }
            this.itemslots[26 + 5 + 9 * 6].i = new Icon((key, c) => {
                for (let i = 0; i < 9 * 5; i++) {
                    if (this.itemslots[26 + 1 + 9 * 1 + i].i != null && (<Item>this.itemslots[26 + 1 + 9 * 1 + i].i).itemtyp == "present") {
                        Inventar.server.give((<Item>this.itemslots[26 + 1 + 9 * 1 + i].i).s[1], (<Item>this.itemslots[26 + 1 + 9 * 1 + i].i), true)
                        Inventar.server.msg("~00ff00you received a present from " + c.name + ".\nNote: \"" + (<Item>this.itemslots[26 + 1 + 9 * 1 + i].i).s[2] + "~00ff00\"", (<Item>this.itemslots[26 + 1 + 9 * 1 + i].i).s[1], true)
                        this.itemslots[26 + 1 + 9 * 1 + i].i = null;
                    }
                }
            }, (c) => {
                return "click to send all presents above to their receiver";
            }, "blocks/Platzhalter Dunkelgrün.png", 1);
            this.itemslots[26 + 9 + 9 * 6].i = new Icon((key, c) => {
                if (this.maus != null && this.maus.itemtyp == "present") {
                    Inventar.server.give(this.maus.s[1], this.maus, true)
                    Inventar.server.msg("~00ff00you received a present from " + c.name + ".\nNote: \"" + this.maus.s[2] + "~00ff00\"", this.maus.s[1], true)
                    this.maus = null;
                }
            }, (c) => {
                return "~00ff00drag a present here to send it INSTANTLY!";
            }, "", 1);
        }
        if (menu == Menu.presents_main) {
            this.itemslots[26 + 5 + 9 * 5].i = new Icon((key, c, i) => {
                if (i.help[2] == "n") {
                    i.help[2] = "r";
                } else {
                    i.help[2] = "n";
                }
            }, (c, i) => {
                if (i.help.length == 0) {
                    i.help[2] = "n";
                }
                if (i.help[2] == "n") {
                    return "type /# [note] to change the note of the presents you are packing\nclick to swich to receiver\nnote: \"" + (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[1] + "\"\nreceiver: \"" + (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0] + "\"";
                } else {
                    return "type /# [receiver] to change the receiver of the presents you are packing\nclick to swich to note\nnote: \"" + (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[1] + "\"\nreceiver: \"" + (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0] + "\"";
                }
            }, "Platzhalter grau.png", 1);
            (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[2] = "r";
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 9 + 9 * 6].i = new Icon((key, c) => {
                this.npcShopItems = [new NPCShopItem(() => { return new Item("empty_present", 1, []) }, 5, [])];
                that.loadMenu(Menu.npc_shop)
            }, (c) => {
                return "~00ff00buy presents";
            }, "Goldschaufel.png", 1);
            this.itemslots[26 + 8 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.presents_send)
            }, (c) => {
                return "~00ff00send presents";
            }, "Goldschwert.png", 1);
            this.itemslots[26 + 4 + 9 * 3].i = new Icon((key, c) => { }, (c) => {
                return "";
            }, "Pfeil Links.png", 1);
            this.itemslots[26 + 6 + 9 * 3].i = new Icon((key, c) => { }, (c) => {
                return "";
            }, "Pfeil Links.png", 1);
            (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0] = "";
            (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[1] = "";
            this.itemslots[26 + 7 + 9 * 3].i = new Icon((key, c) => {
                if (this.itemslots[26 + 3 + 9 * 3].i != null && presents > 0) {
                    if (Inventar.server.findPlayer((<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0]) != null && this.maus == null) {
                        let it = new Item("present", 1, [this.c.name, (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0], (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[1], (<Item>this.itemslots[26 + 3 + 9 * 3].i).itemtyp, (<Item>this.itemslots[26 + 3 + 9 * 3].i).Amount + "", ...(<Item>this.itemslots[26 + 3 + 9 * 3].i).s])
                        this.maus = it;
                        presents--;
                        this.itemslots[26 + 3 + 9 * 3].i = null;
                    }
                }
            }, (c, i) => {
                if (this.itemslots[26 + 3 + 9 * 3].i != null && presents > 0) {
                    if (Inventar.server.findPlayer((<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0]) != null) {
                        let it = new Item("present", 1, [this.c.name, (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[0], (<Icon>this.itemslots[26 + 5 + 9 * 5].i).help[1], ...(<Item>this.itemslots[26 + 3 + 9 * 3].i).s])
                        i.texture = "Geschenk.png";
                        return it.UI(this.c);
                    }
                }
                i.texture = "";
                return "";
            }, "", 1);
            let presents = 0;
            this.itemslots[26 + 3 + 9 * 3].i = null;
            this.itemslots[26 + 5 + 9 * 3].i = new Icon((key, c) => {
                if (this.maus != null && this.maus.itemtyp == "empty_present") {
                    if (key == "left") {
                        presents += this.maus.Amount;
                        this.maus = null;
                    } else if (key == "right") {
                        presents += 1;
                        this.maus.Amount--;
                        if (this.maus.Amount <= 0) {
                            this.maus = null;
                        }
                    }
                } else if (this.maus == null && presents > 0) {
                    if (key == "left") {
                        this.maus = new Item("empty_present", (presents > 64 ? 64 : presents), []);
                        presents -= (presents > 64 ? 64 : presents);
                    }
                }
            }, (c, i) => {
                i.Amount = presents < 1 ? 1 : presents
                i.texture = presents <= 0 ? "" : "Geschenk.png";
                return presents <= 0 ? "put empty presents here!" : "empty present";
            }, "", 1);
        }
        if (menu == Menu.craft) {
            this.itemslots[26 + 1 + 9 * 6].i = new Icon((key, c) => {
                that.loadMenu(Menu.main)
            }, (c) => {
                return "~ff0000back";
            }, "Pfeil Rechts.png", 1);
            this.itemslots[26 + 6 + 9 * 3].i = new Icon((key, c) => {
            }, (c) => {
                return "";
            }, "Pfeil Links.png", 1);
            for (let x = 0; x < 3; x++) {
                for (let y = 0; y < 3; y++) {
                    this.itemslots[26 + x + 2 + 9 * (y + 2)].i = null;
                }
            }
            this.itemslots[26 + 8 + 9 * 3].i = new Icon((key, c, ic) => {
                let crafts: Craft[] = Craft.crafts.slice();
                crafts.push(...Collection.getRecipes(c));
                for (let i = 0; i < crafts.length; i++) {
                    const cr = crafts[i];
                    let a: Item[] = [];
                    for (let x = 0; x < 3; x++) {
                        for (let y = 0; y < 3; y++) {
                            a.push(<Item>(c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i));
                        }
                    }
                    if (c.Inventar.maus == null) {
                        if (this.canCraft(cr.recipe, c)) {
                            this.craft(cr.recipe, c);
                            c.Inventar.maus = cr.item(a, c);
                            ic.ui(c, ic);
                            return;
                        }
                    } if (c.Inventar.maus != null && c.Inventar.maus.itemtyp == cr.itemtyp && c.Inventar.maus.Amount + cr.amount <= Itemtyp.findtyp(cr.itemtyp).StackAmount) {
                        if (this.canCraft(cr.recipe, c)) {
                            this.craft(cr.recipe, c);
                            c.Inventar.maus.Amount += cr.amount;
                            ic.ui(c, ic);
                            return;
                        }
                    }
                }
            }, (c, it) => {
                // let s: string[] = [];
                // s[1] = ""; s[2] = ""; s[3] = "";
                // s[4] = ""; s[5] = "1*oak_planks"; s[6] = "";
                // s[7] = ""; s[8] = "1*oak_planks"; s[9] = "";
                // // if(c.Inventar.itemslots[28+(2)+9*(2)].i!=null&&(<Item>(c.Inventar.itemslots[28+(2)+9*(2)].i)).itemtyp=="oak_planks"&&c.Inventar.itemslots[28+(2)+9*(3)].i!=null&&(<Item>(c.Inventar.itemslots[28+(2)+9*(3)].i)).itemtyp=="oak_planks"&&c.Inventar.itemslots[28+(1)+9*(2)].i==null)
                it.texture = "blocks/ERROR.png";
                it.Amount = 1;
                let crafts: Craft[] = Craft.crafts.slice();
                crafts.push(...Collection.getRecipes(c));
                for (let i = 0; i < crafts.length; i++) {
                    const cr = crafts[i];
                    if (this.canCraft(cr.recipe, this.c)) {
                        let a: Item[] = [];
                        for (let x = 0; x < 3; x++) {
                            for (let y = 0; y < 3; y++) {
                                a.push(<Item>(c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i));
                            }
                        }
                        let i = cr.item(a, c);
                        it.texture = Itemtyp.findtyp(i.itemtyp).texture;
                        it.Amount = i.Amount;
                        return i.UI(c);
                    }
                }
                return "this is the item you are crafting";
            }, "blocks/ERROR.png", 1);
        }
        this.menu = menu;
        if (goBackUbdate) {
            if (menu == Menu.viewRecipe) {
                this.lastRecipe.push(this.recipe)
            }
            this.last.push(menu);
            Inventar.server.update(this.c.name);
        }
    }
    goBack() {
        this.recipe = this.lastRecipe[this.lastRecipe.length - 2];
        this.loadMenu(this.last[this.last.length - 2])
        this.lastRecipe.splice(this.lastRecipe.length - 3, 2)
        this.last.splice(this.last.length - 3, 2);
    }
    canCraft(s: string[], c: ClientData): boolean {
        for (let x = 0; x < 3; x++) {
            for (let y = 0; y < 3; y++) {
                let n = 1;
                let a = s[x + 3 * y];
                let b: boolean = false;
                for (let i = 0; i < s[x + 3 * y].length; i++) {
                    const e = s[x + 3 * y][i];
                    if (e == "*") {
                        n = Number.parseFloat(s[x + 3 * y].slice(0, i));
                        a = s[x + 3 * y].slice(i + 1, s[x + 3 * y].length);
                    }
                }
                // if(s[x+3*y].length==0){

                // }
                if (a == "" && c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i != null) {
                    return false;
                }
                if (c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i != null) {
                    if ((<Item>(c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i)).itemtyp == a && (<Item>(c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i)).Amount >= n) {

                    } else {
                        return false;
                    }
                } else if (a != "") {
                    return false;
                }
            }
        }
        return true;
    }
    craft(s: string[], c: ClientData) {
        for (let x = 0; x < 3; x++) {
            for (let y = 0; y < 3; y++) {
                let n = 1;
                let a = s[x + 3 * y];
                for (let i = 0; i < s[x + 3 * y].length; i++) {
                    const e = s[x + 3 * y][i];
                    if (e == "*") {
                        n = Number.parseFloat(s[x + 3 * y].slice(0, i));
                        a = s[x + 3 * y].slice(i + 1, s[x + 3 * y].length);
                    }
                }
                if (<Item>c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i != null) {
                    (<Item>c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i).Amount -= n;
                    if ((<Item>c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i).Amount == 0) {
                        c.Inventar.itemslots[28 + (x) + 9 * (y + 2)].i = null;
                    }
                }
            }
        }
    }
}
export class Craft {
    static crafts: Craft[] = [];
    constructor(public recipe: string[], public item: (items: Item[], c: ClientData) => Item, public itemtyp: string, public amount: number) {
        Craft.crafts.push(this);
    }
    static fill() {
        new Craft([
            "oak_planks", "", "",
            "oak_planks", "", "",
            "", "", ""
        ], (item, c) => { return new Item("stick", 4, []) }, "stick", 4);
        new Craft([
            "oak_log", "", "",
            "", "", "",
            "", "", ""
        ], (item, c) => { return new Item("oak_planks", 4, []) }, "oak_planks", 4);
        new Craft([
            "", "fighter's_blade", "",
            "fighter's_handle", "", "",
            "", "", ""
        ], (item, c) => { return new Item("fighter_short_sword", 1, []) }, "fighter_short_sword", 1);
        let l1 = 0;
        while (Craft.crafts.length != l1) {
            l1 = Craft.crafts.length;
            for (let i = 0; i < Craft.crafts.length; i++) {
                const c = Craft.crafts[i];
                let c2 = c.recipe.slice();
                if (c2[6] + c2[7] + c2[8] == "") {
                    c2[6] = c2[3];
                    c2[7] = c2[4];
                    c2[8] = c2[5];
                    c2[3] = c2[0];
                    c2[4] = c2[1];
                    c2[5] = c2[2];
                    c2[0] = "";
                    c2[1] = "";
                    c2[2] = "";
                    this.crafts.push(new Craft(c2, c.item, c.itemtyp, c.amount));
                }
                c2 = c.recipe.slice();
                if (c2[2] + c2[5] + c2[8] == "") {
                    c2[2] = c2[1];
                    c2[5] = c2[4];
                    c2[8] = c2[7];
                    c2[1] = c2[0];
                    c2[4] = c2[3];
                    c2[7] = c2[6];
                    c2[0] = "";
                    c2[3] = "";
                    c2[6] = "";
                    this.crafts.push(new Craft(c2, c.item, c.itemtyp, c.amount));
                }
            }
            for (let i = 0; i < Craft.crafts.length; i++) {
                const c1 = Craft.crafts[i];
                for (let j = 0; j < Craft.crafts.length; j++) {
                    const c2 = Craft.crafts[j];
                    if (c1 != c2 && c1.item == c2.item && c1.recipe[0] == c2.recipe[0] && c1.recipe[1] == c2.recipe[1] && c1.recipe[2] == c2.recipe[2] && c1.recipe[3] == c2.recipe[3] && c1.recipe[4] == c2.recipe[4] && c1.recipe[5] == c2.recipe[5] && c1.recipe[6] == c2.recipe[6] && c1.recipe[7] == c2.recipe[7] && c1.recipe[8] == c2.recipe[8]) {
                        Craft.crafts.splice(j, 1);
                        j--;
                    }
                }
            }
        }
    }
}
export enum Menu {
    main, collections, skills, craft, collections_combat, collections_farming, collections_foraging, collections_fishing, collections_mining, ah_main, ah_buy, ah_sell, ah_viewBids, ah_viewOwnAuctions, ah_buy_item, wardrobe, storage,
    anvil, viewCollection, viewRecipe, minions, viewMinionChest,
    npc_shop,
    slayers_main, view_slayer, view_rare_drops,
    confirmSlayerQuestStart,
    npcReforge,
    presents_main,
    presents_send,
    comm_proj
}
export class ClientInventory {
    constructor(public maus: ClientItem, public itemslots: ClientItem[] = []) {

    }
}
export class ClientItem {
    constructor(public name: string, public texture: string, public text: string, public Amount: number) {

    }
}
export class Itemslot {
    i: Itemslotfüller;
    constructor(public inventar: Inventar) {

    }
    onClick(key: string, c: ClientData) {
        if (this.i != null && this.i instanceof Icon) {
            this.i.onClick(key, c);
        } else if (this.i instanceof Item || this.i == null) {
            let it = Itemtyp.findtyp((<Item>this.i)?.itemtyp);
            let i: Item = (<Item>this.i);
            if (this.i != null && this.inventar.maus != null && (<Item>this.i)?.itemtyp == this.inventar.maus.itemtyp) {
                if (it.StackAmount == 1) {
                    if (key == "right" || key == "left") {
                        let it = <Item>this.i;
                        this.i = this.inventar.maus
                        this.inventar.maus = it;
                    }
                } else {
                    if (key == "right") {
                        (<Item>this.i).Amount++;
                        this.inventar.maus.Amount--;
                        if ((<Item>this.i)?.Amount > it.StackAmount) {
                            this.inventar.maus.Amount += (<Item>this.i)?.Amount - it.StackAmount;
                            (<Item>this.i).Amount -= (<Item>this.i)?.Amount - it.StackAmount;
                        }
                    } else if (key == "left") {
                        (<Item>this.i).Amount += this.inventar.maus.Amount;
                        this.inventar.maus.Amount = 0;
                        if ((<Item>this.i).Amount > it.StackAmount) {
                            this.inventar.maus.Amount += (<Item>this.i).Amount - it.StackAmount;
                            (<Item>this.i).Amount -= (<Item>this.i).Amount - it.StackAmount;
                        }
                    }
                }
            } else if (this.i == null && this.inventar.maus != null) {
                if (key == "right") {
                    // let i: Item = Item.kopieren(this.inventar.maus, this)
                    let i = new Item(this.inventar.maus.itemtyp, 1, this.inventar.maus.s)
                    i.Amount = 1;
                    this.inventar.maus.Amount--;
                    this.i = i;
                } else if (key == "left") {
                    this.i = this.inventar.maus;
                    this.inventar.maus = i;
                }
            } else if (this.i != null && this.inventar.maus == null) {
                if (key == "right") {
                    // let i: Item = Item.kopieren(this.i, null)
                    if (Itemtyp.findtyp((<Item>this.i).itemtyp).abilities.find((v) => { return v.button == "invright" }) != null) {
                        Ability.useItemAbility((<Item>this.i), c.md.mouseX, c.md.mouseY, c, "invright")
                    } else {
                        let i = new Item((<Item>this.i).itemtyp, (<Item>this.i).Amount, (<Item>this.i).s)
                        i.Amount /= 2;
                        i.Amount = Math.round(i.Amount + 0.1);
                        (<Item>this.i).Amount /= 2;
                        (<Item>this.i).Amount = Math.round((<Item>this.i).Amount - 0.1)
                        this.inventar.maus = i;
                    }
                } else if (key == "left") {
                    this.i = this.inventar.maus;
                    this.inventar.maus = i;
                }
            } else if (this.i != null && this.inventar.maus != null) {
                if (key == "right" || key == "left") {
                    let it = <Item>this.i;
                    this.i = this.inventar.maus
                    this.inventar.maus = it;
                }
            }
            if (this.inventar.maus != null && this.inventar.maus.Amount <= 0) {
                this.inventar.maus.i = null;
                this.inventar.maus = null;
            }
            if (this.i != null && this.i instanceof Item && this.i.Amount <= 0) {
                this.i.i = null;
                this.i = undefined;
            }
            if (this.inventar.maus != null) {
                this.inventar.maus.i = null;
            }
            if (this.i != null) {
                this.i.i = this;
            }
        }
        // if (this.inventar.maus != null && this.itemtyp == this.inventar.maus.itemtyp) {
        //     if (key = "right") {
        //         this.Amount++;
        //         this.inventar.maus.Amount--;
        //         if (this.Amount > it.StackAmount) {
        //             this.inventar.maus.Amount += this.Amount - it.StackAmount;
        //             this.Amount -= this.Amount - it.StackAmount;
        //         }
        //     } else if (key == "left") {
        //         this.Amount += this.inventar.maus.Amount;
        //         this.inventar.maus.Amount = 0;
        //         if (this.Amount > it.StackAmount) {
        //             this.inventar.maus.Amount += this.Amount - it.StackAmount;
        //             this.Amount -= this.Amount - it.StackAmount;
        //         }
        //     }
        // } else if (this.i == null && this.inventar.maus != null) {
        //     if (key = "right") {
        //         // let i:Item=Item.kopieren(this.inventar.maus,this)
        //         // i.Amount=1;
        //         this.inventar.maus.Amount--;
        //         // this.i=i;
        //         let i = new Item(this.itemtyp, 1, this.inventar, this.itemslot)
        //         this.itemslot.i = i;
        //     } else if (key == "left") {
        //         this.itemslot.i = this.inventar.maus;
        //         this.inventar.maus = this;
        //     }
        // } else if (this.i != null && this.inventar.maus == null) {
        //     if (key = "right") {
        //         // let i:Item=Item.kopieren(this.i,null)
        //         // i.Amount/=2;
        //         // i.Amount=Math.round(i.Amount+0.1)
        //         // this.inventar.maus=i;
        //         let i = new Item(this.itemtyp, Math.round((this.Amount / 2)-0.1), this.inventar, this.itemslot)
        //         this.Amount /= 2;
        //         this.Amount = Math.round(this.Amount - 0.1)
        //         this.inventar.maus=i;
        //     } else if (key == "left") {
            //         this.itemslot.i = this.inventar.maus;
            //         this.inventar.maus = this;
        //     }
        // }
        // if (this.inventar.maus != null && this.inventar.maus.Amount <= 0) {
        //     this.inventar.maus.i = null;
        //     this.inventar.maus = null;
        // }
        // if (this.i != null && this.Amount <= 0) {
        //     this.i.i==null
        // }
        // if (this.i == null && this.Amount <= 0) {
        //     this.inventar.maus==null
        // }
        // if(this.i!=null){
        //     this.i.i=this;
        // }
    }
}
/*
black  protector
brown  tough
cyan   wise
green  lucky
pink   young
purple supporting
red    unstable
orange strong
white  old
yellow superior

strong
old
protector
young
wise
superior
lucky
supporting
tough
unstable
*/
export class Itemtyp {
    static its: Itemtyp[] = [];
    constructor(public texture: string, public StackAmount: number, public Name: string, public on: (key: string) => void, public ui: (CD: ClientData, it: Item) => string, public typ: itemtypes[], public stats: stat[]|((c:ClientData)=>stat[]), public rarity: rarity, public npcSellValue: number = 0, public abilities: Ability[] = []) {
        Itemtyp.its.push(this);
    }
    static Itemtypfüllen() {
        new Itemtyp("blocks/Erde.png", 64, "dirt", () => { }, () => { return "" }, [itemtypes.block], [], rarity.common);
        new Itemtyp("blocks/steinziegel.png", 64, "stone_bricks", () => { }, () => { return "" }, [itemtypes.block], [], rarity.common);
        new Itemtyp("blocks/steinziegel.png", 64, "stone", () => { }, () => { return "" }, [itemtypes.block], [], rarity.common);
        new Itemtyp("blocks/Eichenholzbretter.png", 64, "oak_planks", () => { }, () => { return "" }, [itemtypes.block], [], rarity.common);
        new Itemtyp("blocks/Holzstamm.png", 64, "oak_log", () => { }, () => { return "" }, [itemtypes.block], [], rarity.common);
        new Itemtyp("blocks/Holzstamm.png", 64, "enchanted_oak_log", () => { }, () => { return "" }, [itemtypes.block], [], rarity.uncommon);
        new Itemtyp("blocks/Crafting_table.png", 64, "crafting_table", () => { }, () => { return "" }, [itemtypes.block], [], rarity.common);
        new Itemtyp("holzschwert.png", 1, "wooden_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 10)], rarity.common);
        new Itemtyp("Holzschwert schlecht.png", 1, "rough_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 3)], rarity.common);
        new Itemtyp("steinschwert.png", 1, "stone_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 5)], rarity.common);
        new Itemtyp("Steinaxt.png", 1, "stone_axe", () => { }, () => { return "" }, [itemtypes.axe, itemtypes.tool], [new stat(stats.foraging_speed, 10), new stat(stats.breaking_power, 1)], rarity.common);
        new Itemtyp("Steinschaufel.png", 1, "stone_shovel", () => { }, () => { return "" }, [itemtypes.shovel, itemtypes.tool], [], rarity.common);
        new Itemtyp("Steinsense.png", 1, "stone_scythe", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 10)], rarity.common);
        new Itemtyp("Steinsense.png", 1, "stone_hoe", () => { }, () => { return "" }, [itemtypes.hoe, itemtypes.tool], [new stat(stats.farming_fortune, 10), new stat(stats.breaking_power, 1)], rarity.common);
        new Itemtyp("Steinsense.png", 1, "linear_wheat_hoe", () => { }, () => { return "" }, [itemtypes.hoe, itemtypes.tool], [new stat(stats.farming_fortune, 100), new stat(stats.breaking_power, 1)], rarity.rare);
        new Itemtyp("Eisensense.png", 1, "triangular_wheat_hoe", () => { }, () => { return "" }, [itemtypes.hoe, itemtypes.tool], [new stat(stats.farming_fortune, 150), new stat(stats.breaking_power, 1)], rarity.epic);
        new Itemtyp("Goldsense.png", 1, "tetrahedral_wheat_hoe", () => { }, () => { return "" }, [itemtypes.hoe, itemtypes.tool], [new stat(stats.farming_fortune, 200), new stat(stats.breaking_power, 1)], rarity.legendary);
        new Itemtyp("Steinspitzhacke.png", 1, "stone_pickaxe", () => { }, () => { return "" }, [itemtypes.pickaxe, itemtypes.tool], [new stat(stats.mining_speed, 10), new stat(stats.breaking_power, 1)], rarity.common);
        new Itemtyp("Steinspitzhacke.png", 1, "pickaxe_made_of_stone", () => { }, () => { return "" }, [itemtypes.pickaxe, itemtypes.tool], [new stat(stats.mining_speed, 20), new stat(stats.breaking_power, 1)], rarity.common);
        new Itemtyp("Steinspitzhacke.png", 1, "rotten_pickaxe", () => { }, () => { return "" }, [itemtypes.pickaxe, itemtypes.tool], [new stat(stats.mining_speed, 30), new stat(stats.breaking_power, 1)], rarity.common);
        new Itemtyp("Steinspitzhacke.png", 1, "bone_pickaxe", () => { }, () => { return "" }, [itemtypes.pickaxe, itemtypes.tool], [new stat(stats.mining_speed, 20), new stat(stats.breaking_power, 1)], rarity.common);
        new Itemtyp("eisenschwert.png", 1, "iron_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 30)], rarity.common);
        new Itemtyp("Eisenaxt.png", 1, "wooden_axe", () => { }, () => { return "" }, [itemtypes.axe, itemtypes.tool], [new stat(stats.foraging_speed, 20), new stat(stats.breaking_power, 1)], rarity.common);
        new Itemtyp("Eisenaxt.png", 1, "iron_axe", () => { }, () => { return "" }, [itemtypes.axe, itemtypes.tool], [], rarity.common);
        new Itemtyp("Eisenschaufel.png", 1, "iron_shovel", () => { }, () => { return "" }, [itemtypes.shovel, itemtypes.tool], [], rarity.common);
        new Itemtyp("Eisensense.png", 1, "iron_scythe", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 10)], rarity.common);
        new Itemtyp("Eisenspitzhacke.png", 1, "iron_pickaxe", () => { }, () => { return "" }, [itemtypes.pickaxe, itemtypes.tool], [], rarity.common);
        new Itemtyp("diamantschwert.png", 1, "diamond_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 50)], rarity.uncommon);
        new Itemtyp("Diamantaxt.png", 1, "diamond_axe", () => { }, () => { return "" }, [itemtypes.axe, itemtypes.tool], [], rarity.uncommon);
        new Itemtyp("Diamantschaufel.png", 1, "diamond_shovel", () => { }, () => { return "" }, [itemtypes.shovel, itemtypes.tool], [], rarity.uncommon);
        new Itemtyp("Diamantsense.png", 1, "diamond_scythe", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 10)], rarity.uncommon);
        new Itemtyp("Diamantspitzhacke.png", 1, "diamond_pickaxe", () => { }, () => { return "" }, [itemtypes.pickaxe, itemtypes.tool], [], rarity.uncommon);
        new Itemtyp("Goldschwert.png", 1, "golden_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 10)], rarity.common);
        new Itemtyp("Goldschwert.png", 1, "zombie_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 10)], rarity.rare, 0, [new Ability(0, 0, "hit", (c, x, y) => { c.health += 10; return true })]);
        new Itemtyp("Goldaxt.png", 1, "golden_axe", () => { }, () => { return "" }, [itemtypes.axe, itemtypes.tool], [], rarity.common);
        new Itemtyp("Goldschaufel.png", 1, "golden_shovel", () => { }, () => { return "" }, [itemtypes.shovel, itemtypes.tool], [], rarity.common);
        new Itemtyp("Goldsense.png", 1, "golden_scythe", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 10)], rarity.common);
        new Itemtyp("Goldspitzhacke.png", 1, "golden_pickaxe", () => { }, () => { return "" }, [itemtypes.pickaxe, itemtypes.tool], [], rarity.common);
        new Itemtyp("goldschwertgut.png", 1, "fire_staff", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.intelligence, 100)], rarity.epic, 0, [new Ability(3, 30, "right", (c, x, y, i) => { new Projectile(1, c.x, c.y, "", 60, x, y, i, () => { return true; }, "mobs/Huhn.png", c.name, 10, (g) => { return true; }, 10, [], 0, true); return true })]);
        new Itemtyp("Platzhalter hellgrün.png", 1, "weak_power_orb", () => { }, () => { return "" }, [itemtypes.tool,itemtypes.orb], [], rarity.uncommon, 0, [new Ability(0, 100, "right", (c, x, y, i) => { for (let i = 0; i < Entity.entities.length; i++) { const e = Entity.entities[i]; if (e instanceof Projectile && (<Projectile>e).buffs.length > 0 && e.owner == c.name) { Entity.entities.splice(i, 1); i--; } } new Projectile(1, x, y, "", 30 * 30, 0, 0, i, (that) => { that.text = (Math.floor(that.time / 30) + 1) + ""; return true; }, "items/Platzhalter hellgrün.png", c.name, 0, (g) => { return false; }, NaN, [new stat(stats.health_regen, 3), new stat(stats.strength, 20)], 500); return true })]);
        new Itemtyp("diamantschwert.png", 1, "aspect_of_the_end", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 500), new stat(stats.crit_damage, 100), new stat(stats.strength, 50)], rarity.epic, 0, [new Ability(0, 50, "right", (c, x, y) => { Inventar.server.update(c.name, x, y); return true })]);
        new Itemtyp("steinschwert.png", 1, "fighter_short_sword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.crit_chance, -20), new stat(stats.strength, 50), new stat(stats.damage, 15), new stat(stats.range, -50)], rarity.rare);
        new Itemtyp("stick.png", 64, "stick", () => { }, () => { return "" }, [itemtypes.material], [], rarity.common);
        new Itemtyp("stick.png", 64, "wooden_handle", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Geschenk.png", 1, "present", () => { }, (CD, it) => { return "from: " + it.s[0] + "\nto: " + it.s[1] + "\nnote: " + it.s[2] + "\n\nright click to open!" }, [itemtypes.consumable], [], rarity.special, 0, [new Ability(0, 0, "invright", (c, x, y, i) => { if (i.s[1] == c.name) { let it = new Item(i.s[3], Number.parseFloat(i.s[4]), i.s.slice(5, i.s.length)); Inventar.server.msg("~00ff00you opened a present from " + i.s[0] + ". it contained " + i.s[4] + " * " + i.s[3] + ".", c.name, true); Inventar.server.give(c.name, it, false); return true; } return false; })]);
        new Itemtyp("Geschenk.png", 64, "empty_present", () => { }, () => { return "" }, [itemtypes.material], [], rarity.common);
        new Itemtyp("stick.png", 64, "boney_handle", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("stick.png", 64, "mythical_handle", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Summening Gem.png", 1, "summoning_gem", () => { }, () => { return "" }, [itemtypes.material], [], rarity.epic);
        new Itemtyp("Summening Gem.png", 1, "compressed_summoning_gem", () => { }, () => { return "" }, [itemtypes.material], [], rarity.legendary);
        new Itemtyp("Eisensense.png", 1, "dragon_claw", () => { }, (cd, it) => { it.s[2] = "sharp"; return "" }, [itemtypes.reforge_stone], [], rarity.rare);
        new Itemtyp("Platzhalter hellgrün.png", 64, "strong_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("strong_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "old_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("old_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "protector_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("protector_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "young_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("young_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "wise_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("wise_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "superior_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("superior_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "lucky_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("lucky_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "supporting_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("supporting_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "tough_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("tough_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "unstable_dragon_armour_token", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y) => { let r = Math.floor(Math.random() * 4); let a = ""; if (r == 0) a = "boots"; if (r == 1) a = "leggings"; if (r == 2) a = "chestplate"; if (r == 3) a = "helmet"; Inventar.server.give(c.name, new Item("unstable_dragon_" + a, 1, []), true); return true; })]);
        new Itemtyp("Dragonfragment orange.png", 64, "strong_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment white.png", 64, "old_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment black.png", 64, "protector_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment pink.png", 64, "young_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment cyan.png", 64, "wise_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment yellow.png", 64, "superior_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment green.png", 64, "lucky_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment purple.png", 64, "supporting_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment brown.png", 64, "tough_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Dragonfragment red.png", 64, "unstable_dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("diamond boots.png", 1, "strong_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 50), new stat(stats.health, 50), new stat(stats.strength, 20), new stat(stats.crit_damage, 20)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "strong_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 50), new stat(stats.health, 50), new stat(stats.strength, 20), new stat(stats.crit_damage, 20)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "strong_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 50), new stat(stats.health, 50), new stat(stats.strength, 20), new stat(stats.crit_damage, 20)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "strong_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 50), new stat(stats.health, 50), new stat(stats.strength, 20), new stat(stats.crit_damage, 20)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "old_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 40), new stat(stats.health, 50)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "old_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 40), new stat(stats.health, 50)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "old_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 40), new stat(stats.health, 50)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "old_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 40), new stat(stats.health, 50)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "protector_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 100), new stat(stats.health, 150)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "protector_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 100), new stat(stats.health, 150)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "protector_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 100), new stat(stats.health, 150)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "protector_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 100), new stat(stats.health, 150)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "young_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.speed, 40)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "young_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.speed, 40)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "young_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.speed, 40)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "young_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.speed, 40)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "wise_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.intelligence, 80)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "wise_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.intelligence, 80)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "wise_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.intelligence, 80)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "wise_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.intelligence, 80)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "superior_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 100), new stat(stats.health, 150), new stat(stats.strength, 80), new stat(stats.crit_damage, 80), new stat(stats.magic_find, 10)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "superior_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 100), new stat(stats.health, 150), new stat(stats.strength, 80), new stat(stats.crit_damage, 80), new stat(stats.magic_find, 10)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "superior_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 100), new stat(stats.health, 150), new stat(stats.strength, 80), new stat(stats.crit_damage, 80), new stat(stats.magic_find, 10)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "superior_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 100), new stat(stats.health, 150), new stat(stats.strength, 80), new stat(stats.crit_damage, 80), new stat(stats.magic_find, 10)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "lucky_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.magic_find, 15)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "lucky_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.magic_find, 15)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "lucky_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.magic_find, 15)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "lucky_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.magic_find, 15)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "supporting_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.support_multiplier, 25)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "supporting_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.support_multiplier, 25)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "supporting_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.support_multiplier, 25)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "supporting_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 20), new stat(stats.health, 30), new stat(stats.support_multiplier, 25)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "tough_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 200), new stat(stats.health, 300), new stat(stats.strength, -20), new stat(stats.crit_damage, -20), new stat(stats.speed, -10)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "tough_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 200), new stat(stats.health, 300), new stat(stats.strength, -20), new stat(stats.crit_damage, -20), new stat(stats.speed, -10)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "tough_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 200), new stat(stats.health, 300), new stat(stats.strength, -20), new stat(stats.crit_damage, -20), new stat(stats.speed, -10)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "tough_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 200), new stat(stats.health, 300), new stat(stats.strength, -20), new stat(stats.crit_damage, -20), new stat(stats.speed, -10)], rarity.legendary);
        new Itemtyp("diamond boots.png", 1, "unstable_dragon_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, -20), new stat(stats.health, -20), new stat(stats.strength, 50), new stat(stats.crit_damage, 50)], rarity.legendary);
        new Itemtyp("diamond leggings.png", 1, "unstable_dragon_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, -20), new stat(stats.health, -20), new stat(stats.strength, 50), new stat(stats.crit_damage, 50)], rarity.legendary);
        new Itemtyp("diamond chestplate.png", 1, "unstable_dragon_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, -20), new stat(stats.health, -20), new stat(stats.strength, 50), new stat(stats.crit_damage, 50)], rarity.legendary);
        new Itemtyp("diamond helmet.png", 1, "unstable_dragon_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, -20), new stat(stats.health, -20), new stat(stats.strength, 50), new stat(stats.crit_damage, 50)], rarity.legendary);
        new Itemtyp("blocks/ERROR.png", 1, "helmet_of_invincibility", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 1000000)], rarity.one_of_a_kind);
        new Itemtyp("Platzhalter rot.png", 64, "dragon_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.common);
        new Itemtyp("Geldbeutel.png", 64, "small_money_bag", () => { }, () => { return "contains 100 coins" }, [itemtypes.material], [], rarity.common, 5);
        new Itemtyp("stick.png", 64, "fighter's_handle", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("steinschwert.png", 64, "fighter's_blade", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("steinschwert.png", 64, "boney_longsword", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.damage, 50), new stat(stats.strength, 50), new stat(stats.crit_damage, 100), new stat(stats.range, 50)], rarity.epic);
        // new Itemtyp("Snowflake.png", 1, "enchanted_snow_flake", () => { }, () => { return "" }, [itemtypes.tool], [], rarity.special, 0, [new Ability(0, 0, "right_hold", (c, x, y) => { new Snow_part(c.md.map, c.x + (Math.random() * 2 - 1) * 400, c.y + (Math.random() * 2 - 1) * 300); return true; })]);
        new Itemtyp("Snowflake.png", 1, "enchanted_snow_flake", () => { }, () => { return "" }, [itemtypes.tool], [], rarity.special, 0, [new Ability(0, 0, "right_hold", (c, x, y) => { if (Inventar.server.ticks % 5 == 0) { Inventar.server.sendParticles(c.md.map, [new SendParticle(c.x, c.y, 800, 600, false, 0, 1, 0, 0, false, "items/Snowflake.png", 0, 1, "sprite", 5, 0, 0, 1, 1, 90, 30, 0.5)]); return true; } return false; })]);
        new Itemtyp("stick.png", 64, "wand_of_mending", () => { }, () => { return "" }, [itemtypes.tool], [], rarity.common, 0, [new Ability(0, 50, "right", (c, x, y) => { EffectType.giveEffectC(c.name, "regeneration1", 5, 5); return true })]);
        new Itemtyp("stick.png", 64, "wand_of_healing", () => { }, () => { return "" }, [itemtypes.tool], [], rarity.uncommon, 0, [new Ability(0, 50, "right", (c, x, y) => { EffectType.giveEffectC(c.name, "regeneration1", 5, 10); return true })]);
        new Itemtyp("diamond boots.png", 1, "diamond_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 100)], rarity.uncommon);
        new Itemtyp("diamond leggings.png", 1, "diamond_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 100)], rarity.uncommon);
        new Itemtyp("diamond chestplate.png", 1, "diamond_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 100)], rarity.uncommon);
        new Itemtyp("diamond helmet.png", 1, "diamond_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 100)], rarity.uncommon);
        new Itemtyp("golden boots.png", 1, "golden_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("golden leggings.png", 1, "golden_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("golden chestplate.png", 1, "golden_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("golden chestplate.png", 1, "researcher_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.health, 500), new stat(stats.defence, 100)], rarity.rare);
        new Itemtyp("golden helmet.png", 1, "golden_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("golden boots.png", 1, "supporter_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.support_multiplier, 20)], rarity.epic);
        new Itemtyp("golden leggings.png", 1, "supporter_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.support_multiplier, 20)], rarity.epic);
        new Itemtyp("golden chestplate.png", 1, "supporter_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.support_multiplier, 20)], rarity.epic);
        new Itemtyp("golden helmet.png", 1, "supporter_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.support_multiplier, 20)], rarity.epic);
        new Itemtyp("iron boots.png", 1, "iron_boots", () => { }, () => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("iron leggings.png", 1, "iron_leggings", () => { }, () => { return "" }, [itemtypes.hose], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("iron chestplate.png", 1, "iron_chestplate", () => { }, () => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("iron helmet.png", 1, "iron_helmet", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.defence, 100)], rarity.common);
        new Itemtyp("iron helmet.png", 1, "fighter_hat", () => { }, () => { return "" }, [itemtypes.helm], [new stat(stats.strength, 20), new stat(stats.crit_damage, -50)], rarity.uncommon);
        new Itemtyp("Collection Book.png", 1, "enchanted_book", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Enchanted Book Upgrade.png", 1, "enchanted_book_ubgrade", () => { }, (cd, it) => { return "upgrades " + it.s[0] + " " + EnchantType.numberToString(Number.parseFloat(it.s[1]) - 1) + " to " + it.s[0] + " " + EnchantType.numberToString(Number.parseFloat(it.s[1])) }, [itemtypes.material], [], rarity.epic);
        new Itemtyp("Verrottetes Fleisch.png", 64, "rotten_flesh", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Researcher Fragment.png", 64, "researcher_fragment", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Wheat.png", 64, "wheat", () => { }, () => { return "" }, [itemtypes.material], [], rarity.common);
        new Itemtyp("Wheat.png", 64, "enchanted_wheat", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Wheat.png", 64, "magical_wheat", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Wheat.png", 64, "wheat_soul", () => { }, () => { return "" }, [itemtypes.material], [], rarity.epic);
        new Itemtyp("Verrottetes Fleisch.png", 64, "enchanted_rotten_flesh", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Collection Book.png", 1, "bug_destroyer", () => { }, (cd, it) => { return "awarded to:" + it.s[0] + ", edition:" + it.s[1] }, [itemtypes.material], [], rarity.special);
        new Itemtyp("Collection Book.png", 1, "glich_annihilator", () => { }, (cd, it) => { return "awarded to:" + it.s[0] + ", edition:" + it.s[1] }, [itemtypes.material], [], rarity.special);
        new Itemtyp("Knochen.png", 64, "bone", () => { }, () => { return "" }, [itemtypes.material], [], rarity.common);
        new Itemtyp("blocks/ERROR.png", 1, "block_place_help", () => { }, (cd, it) => { return "block: " + it.s[0] }, [itemtypes.material, itemtypes.tool], [], rarity.one_of_a_kind, 0, [new Ability(0, 0, "right", (c, x, y, i) => { Inventar.server.setblock(x / map.bb + 0.5 + "", y / map.bb + 0.5 + "", i.s[0], c.md.map); return true })]);
        new Itemtyp("blocks/ERROR.png", 1, "block_coord_help", () => { }, (cd, it) => { return "block: " + it.s[0] }, [itemtypes.material, itemtypes.tool], [], rarity.one_of_a_kind, 0, [new Ability(0, 0, "right", (c, x, y, i) => { Inventar.server.msg((x / map.bb + 0.5 + Inventar.server.maps[c.md.map].x) + ", " + (y / map.bb + 0.5 + Inventar.server.maps[c.md.map].y) + "", c.name, true); return true })]);
        new Itemtyp("blocks/ERROR.png", 1, "test", () => { }, () => { return "" }, [itemtypes.material], [], rarity.one_of_a_kind);
        new Itemtyp("Arrow.png", 64, "arrow", () => { }, () => { return "" }, [itemtypes.arrow], [], rarity.common);
        new Itemtyp("stick.png", 64, "revenant_handle", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Christbaumkugel.png", 64, "christmas_tree_ball", () => { }, () => { return "" }, [itemtypes.material], [], rarity.common);
        new Itemtyp("Stern.png", 64, "christmas_tree_star", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Kerze.png", 64, "christmas_tree_candle", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Knochen.png", 64, "enchanted_bone", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Collection Book.png", 64, "revenant_catalyst", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Verrottetes Fleisch.png", 64, "revenant_flesh", () => { }, () => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Verrottetes Fleisch.png", 64, "revenant_viscera", () => { }, () => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Collection Book.png", 1, "potion", () => { }, () => { return "" }, [itemtypes.consumable], [], rarity.common, 0, [new Ability(0, 0, "right", (c, x, y) => { return true; })]);
        new Itemtyp("Skills.png", 1, "minion", () => { }, (CD, it) => { return "Material: " + it.s[0] + " lvl: " + it.s[1] }, [itemtypes.minion], [], rarity.common);
        new Itemtyp("Skills.png", 1, "small_minion_storage", () => { }, (CD, it) => { it.s[0] = "3"; return "capacity: " + it.s[0] + " slots" }, [itemtypes.minion_storage], [], rarity.common);
        new Itemtyp("Skills.png", 1, "medium_minion_storage", () => { }, (CD, it) => { it.s[0] = "9"; return "capacity: " + it.s[0] + " slots" }, [itemtypes.minion_storage], [], rarity.common);
        new Itemtyp("Skills.png", 1, "flycatcher", () => { }, (CD, it) => { return "" }, [itemtypes.minion_ubgrade], [], rarity.epic);
        new Itemtyp("Skills.png", 1, "swordocolypse", () => { }, (CD, it) => { return "" }, [itemtypes.minion_ubgrade], [], rarity.rare);
        new Itemtyp("Bogen.png", 1, "simple_bow", () => { }, (CD, it) => { return "" }, [itemtypes.bow, itemtypes.tool], [new stat(stats.damage, 30)], rarity.uncommon);
        new Itemtyp("Hurricane Bow 2.png", 1, "hurricane_bow", () => { }, (CD, it) => { return "" }, [itemtypes.bow, itemtypes.tool], [new stat(stats.damage, 100), new stat(stats.strength, 100), new stat(stats.crit_damage, 150), new stat(stats.crit_chance, 30)], rarity.legendary);
        new Itemtyp("blocks/Stein.png", 1, "enchanted_stone", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Platzhalter gelb.png", 1, "enchantment_core", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Platzhalter gelb.png", 1, "damaging_dragon_fragment", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Platzhalter gelb.png", 1, "magical_dragon_fragment", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Platzhalter gelb.png", 1, "tanky_dragon_fragment", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("WP.png", 64, "WP", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("EP.png", 64, "EP", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("RP.png", 64, "RP", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("RP.png", 1, "rune", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.rare);
        new Itemtyp("Platzhalter gelb.png", 1, "ultimate_dragon_fragment", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.epic);
        new Itemtyp("Platzhalter gelb.png", 1, "fire_starter", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.uncommon);
        new Itemtyp("Platzhalter gelb.png", 1, "sacrificial_altar", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.epic);
        new Itemtyp("Platzhalter gelb.png", 1, "perfect_hay_bale", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.legendary);
        new Itemtyp("blocks/Stein.png", 1, "mythical_circle_of_stones", () => { }, (CD, it) => { return "" }, [itemtypes.material], [], rarity.legendary);
        new Itemtyp("iron boots.png", 1, "stone_boots", () => { }, (CD, it) => { return "" }, [itemtypes.schuhe], [new stat(stats.defence, 50), new stat(stats.speed, -20)], rarity.rare);
        new Itemtyp("iron leggings.png", 1, "stone_leggings", () => { }, (CD, it) => { return "" }, [itemtypes.hose], [new stat(stats.defence, 50), new stat(stats.speed, -20)], rarity.rare);
        new Itemtyp("iron chestplate.png", 1, "stone_chestplate", () => { }, (CD, it) => { return "" }, [itemtypes.burstplatte], [new stat(stats.defence, 50), new stat(stats.speed, -20)], rarity.rare);
        new Itemtyp("iron helmet.png", 1, "stone_helmet", () => { }, (CD, it) => { return "" }, [itemtypes.helm], [new stat(stats.defence, 50), new stat(stats.speed, -20)], rarity.rare);
        new Itemtyp("Goldschwert.png", 1, "aspect_of_the_dragon", () => { }, (CD, it) => { return "" }, [itemtypes.tool, itemtypes.weapon], [new stat(stats.damage, 100), new stat(stats.crit_damage, 50), new stat(stats.strength, 50)], rarity.rare);
        new Itemtyp("goldschwertgut.png", 1, "aspect_of_the_dragons", () => { }, (CD, it) => { return "" }, [itemtypes.tool, itemtypes.weapon], [new stat(stats.damage, 150), new stat(stats.crit_damage, 100), new stat(stats.strength, 100)], rarity.epic);
        new Itemtyp("blocks/Stein.png", 1, "magical_rock", () => { }, (CD, it) => { return "" }, [itemtypes.tool, itemtypes.weapon], [new stat(stats.intelligence, 20)], rarity.epic, 0, [new Ability(0, 30, "right", (c, x, y, i) => { new Projectile(c.md.map, x, y, "", 40, 0, -20, i, (that) => { that.scale = 1 / 5; if (that.time < 1) { return true } that.vy++; that.y += that.vy; that.time--; return false; }, "items/blocks/Stein.png", c.name, 20, () => { return false; }, NaN, [], 0, true); return true; })]);
        new Itemtyp("blocks/Stein.png", 1, "super_magical_rock", () => { }, (CD, it) => { return "" }, [itemtypes.tool, itemtypes.weapon], [new stat(stats.intelligence, 100)], rarity.legendary, 0, [new Ability(0, 50, "right", (c, x, y, i) => { new Projectile(c.md.map, x, y, "", 40, 0, -20, i, (that) => { that.scale = 1 / 3; if (that.time < 1) { return true } that.vy++; that.y += that.vy; that.time--; return false; }, "items/blocks/Stein.png", c.name, 50, () => { return false; }, NaN, [], 0, true); return true; })]);
        new Itemtyp("goldschwertgut.png", 1, "dragon_fury", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.intelligence, 200)], rarity.epic, 0, [new Ability(0, 20, "right", (c, x, y, i) => { new Projectile(1, c.x, c.y, "", 60, x, y, i, () => { return true; }, "mobs/Huhn.png", c.name, 10, (g) => { return false; }, 40, [], 0, true); return true })]);
        new Itemtyp("goldschwertgut.png", 1, "dragon_staff", () => { }, () => { return "" }, [itemtypes.weapon, itemtypes.tool], [new stat(stats.intelligence, 100)], rarity.epic, 0, [new Ability(0, 30, "right", (c, x, y, i) => { new Projectile(1, c.x, c.y, "", 60, x, y, i, () => { return true; }, "mobs/Malwettbewerb (Drache).png", c.name, 20, (g) => { return true; }, 20, [], 0, true); return true })]);
        new Itemtyp("blocks/Platzhalter Dunkelgrün.png", 64, "lottery_ticket_1", () => { }, () => { return "right click to open!\nmay contain:\nsnow rune I - III\nfrost rune I - III\npoints rune I - III" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y, i) => { let r = Math.random() * 3; let r2 = Math.random(); Inventar.server.give(c.name, new Item("rune", 1, ["", "", "", "", (r > 2 ? "snow" : (r > 1 ? "frost" : "points")) + " rune", (r2 > 0.9 ? "3" : (r2 > 0.6 ? "2" : "1"))]), true); return true; })]);
        new Itemtyp("blocks/Platzhalter Dunkelgrün.png", 64, "lottery_ticket_2", () => { }, () => { return "right click to open!\nmay contain:\nfirework rune I - III\nfire trail rune I - III\ninferno rune I - III" }, [itemtypes.consumable], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y, i) => { let r = Math.random() * 3; let r2 = Math.random(); Inventar.server.give(c.name, new Item("rune", 1, ["", "", "", "", (r > 2 ? "firework" : (r > 1 ? "fire trail" : "inferno")) + " rune", (r2 > 0.9 ? "3" : (r2 > 0.6 ? "2" : "1"))]), true); return true; })]);
        new Itemtyp("Platzhalter hellgrün.png", 64, "quiver", () => { }, (cd, it) => {it.s[4]="arrow"; return "arrows: "+(it.s[5]||"0"); }, [itemtypes.sack], [], rarity.epic, 0, [new Ability(0, 0, "invright", (c, x, y, i) => { let a=Inventar.server.count(c.name,"arrow"); Inventar.server.clear(c.name,"arrow",a); i.s[5]=(Number.parseInt(i.s[5]||"0")+a)+""; return a>0; }),new Ability(0, 0, "right", (c, x, y, i) => { let a=Math.min(Number.parseInt(i.s[5]||"0"),64); Inventar.server.give(c.name,new Item(i.s[4],a,[]),false);i.s[5]=(Number.parseInt(i.s[5]||"0")-a)+""; return a>0; })]);
    }
    static findtyp(t: string) {
        return Itemtyp.its.find((value) => {
            return value.Name == t
        })
    }
}
export enum itemtypes {
    material, helm, burstplatte, hose, schuhe, block, talisman, reforge_stone, consumable, minion, minion_storage, minion_ubgrade,
    bow,
    arrow,
    weapon, pickaxe, axe, shovel, tool,
    hoe,
    orb,
    sack
}
export enum stats {
    damage, strength, crit_damage, crit_chance, mining_speed, foraging_speed, mining_fortune, foraging_fortune, farming_fortune, magic_find, speed, ferocity, fishing_speed, sea_creature_chance, defence, health, range, intelligence, health_regen, mana_regen, support_multiplier,
    damage_multiplier,
    ability_damage,
    attack_speed, breaking_power, arrow_speed
}
export enum rarity {
    common, uncommon, rare, epic, legendary, mythic, divine, special, very_special, one_of_a_kind
}
export class stat {
    constructor(public stat: stats, public amount: number, public multiply: boolean = false, public hide: boolean = false) {

    }
    public static rarityColour(r: rarity) {

    }
}
export abstract class Itemslotfüller {
    i: Itemslot;
    constructor() {

    }
    // abstract onClick(key: string);
}
export class Icon extends Itemslotfüller {
    help: string[] = [];
    constructor(public on: (key: string, c: ClientData, i: Icon) => void, public ui: (c: ClientData, i: Icon) => string, public texture: string, public Amount: number) {
        super()
    }
    onClick(key: string, c: ClientData) {
        this.on(key, c, this)
        if (this.i != null) {
            this.i.i = this;
        }
    }
}
export class Item extends Itemslotfüller {
    static statnames: string[] = [];
    static statcolors: string[] = [];
    static stats: stats[] = Item.fillStats();
    static raritynames: string[] = [];
    static raritycolours: string[] = [];
    static rarityReforgeMult: number[] = [];
    static rarities: rarity[] = Item.fillRarities();
    stats: number[] = [];
    constructor(public itemtyp: string, public Amount: number, public s: string[]) {
        super()
        for (let i = 0; i < Item.stats.length; i++) {
            const e = Item.stats[i];
            this.stats[i] = 0;
        }
    }
    static fillStats(): stats[] {
        let g: stats[] = [];
        g.push(stats.crit_chance);
        g.push(stats.crit_damage);
        g.push(stats.damage);
        g.push(stats.farming_fortune);
        g.push(stats.ferocity);
        g.push(stats.fishing_speed);
        g.push(stats.foraging_fortune);
        g.push(stats.foraging_speed);
        g.push(stats.magic_find);
        g.push(stats.mining_fortune);
        g.push(stats.mining_speed);
        g.push(stats.sea_creature_chance);
        g.push(stats.speed);
        g.push(stats.strength);
        g.push(stats.defence);
        g.push(stats.health);
        g.push(stats.range);
        g.push(stats.intelligence);
        g.push(stats.health_regen);
        g.push(stats.mana_regen);
        g.push(stats.support_multiplier);
        g.push(stats.damage_multiplier);
        g.push(stats.attack_speed);
        g.push(stats.breaking_power);
        g.push(stats.ability_damage);
        g.push(stats.arrow_speed);
        Item.statnames.push("crit chance");
        Item.statnames.push("crit damage");
        Item.statnames.push("damage");
        Item.statnames.push("farming fortune");
        Item.statnames.push("ferocity");
        Item.statnames.push("fishing speed");
        Item.statnames.push("foraging fortune");
        Item.statnames.push("foraging speed");
        Item.statnames.push("magic find");
        Item.statnames.push("mining fortune");
        Item.statnames.push("mining speed");
        Item.statnames.push("sea creature chance");
        Item.statnames.push("speed");
        Item.statnames.push("strength");
        Item.statnames.push("defence");
        Item.statnames.push("health");
        Item.statnames.push("range");
        Item.statnames.push("intelligence");
        Item.statnames.push("health regen");
        Item.statnames.push("mana regen");
        Item.statnames.push("support multiplier");
        Item.statnames.push("damage multiplier");
        Item.statnames.push("attack speed");
        Item.statnames.push("breaking power");
        Item.statnames.push("ability damage");
        Item.statnames.push("arrow speed");
        Item.statcolors.push(C.dblue);
        Item.statcolors.push(C.dblue);
        Item.statcolors.push(C.red);
        Item.statcolors.push(C.gold);
        Item.statcolors.push(C.red);
        Item.statcolors.push(C.lblue);
        Item.statcolors.push(C.gold);
        Item.statcolors.push(C.gold);
        Item.statcolors.push(C.lblue);
        Item.statcolors.push(C.gold);
        Item.statcolors.push(C.gold);
        Item.statcolors.push(C.cyan);
        Item.statcolors.push(C.white);
        Item.statcolors.push(C.red);
        Item.statcolors.push(C.green);
        Item.statcolors.push(C.hred);
        Item.statcolors.push(C.white);
        Item.statcolors.push(C.lblue);
        Item.statcolors.push(C.hred);
        Item.statcolors.push(C.lblue);
        Item.statcolors.push(C.yellow);
        Item.statcolors.push(C.red);
        Item.statcolors.push(C.yellow);
        Item.statcolors.push(C.cyan);
        Item.statcolors.push(C.cyan);
        Item.statcolors.push(C.white);
        return g;
    }
    static fillRarities() {
        let a: rarity[] = [];
        a.push(rarity.common);
        a.push(rarity.uncommon);
        a.push(rarity.rare);
        a.push(rarity.epic);
        a.push(rarity.legendary);
        a.push(rarity.mythic);
        a.push(rarity.divine);
        a.push(rarity.special);
        a.push(rarity.very_special);
        a.push(rarity.one_of_a_kind);
        Item.raritynames.push("common");
        Item.raritynames.push("uncommon");
        Item.raritynames.push("rare");
        Item.raritynames.push("epic");
        Item.raritynames.push("legendary");
        Item.raritynames.push("mythic");
        Item.raritynames.push("divine");
        Item.raritynames.push("special");
        Item.raritynames.push("very special");
        Item.raritynames.push("one of a kind");
        Item.raritycolours.push("~ffffff");
        Item.raritycolours.push("~44ff44");
        Item.raritycolours.push("~0000ff");
        Item.raritycolours.push("~aa00aa");
        Item.raritycolours.push("~dddd00");
        Item.raritycolours.push("~ff44ff");
        Item.raritycolours.push("~4444ff");
        Item.raritycolours.push("~ff0000");
        Item.raritycolours.push("~ff0000");
        Item.raritycolours.push("~raibow");
        Item.rarityReforgeMult.push(0.2);
        Item.rarityReforgeMult.push(0.4);
        Item.rarityReforgeMult.push(0.6);
        Item.rarityReforgeMult.push(0.8);
        Item.rarityReforgeMult.push(1);
        Item.rarityReforgeMult.push(1.25);
        Item.rarityReforgeMult.push(1.5);
        Item.rarityReforgeMult.push(1.75);
        Item.rarityReforgeMult.push(2);
        Item.rarityReforgeMult.push(3);
        return a;
    }
    name(): string {
        if (Itemtyp.findtyp(this.itemtyp) == null) {
            return;
        }
        let name = (Item.raritycolours[Item.rarities.indexOf(Itemtyp.findtyp(this.itemtyp).rarity)]) + (ReforgeType.findReforge(this.s[2]) != null ? ReforgeType.findReforge(this.s[2]).name + " " : "") + Itemtyp.findtyp(this.itemtyp).Name + "~ffffff"
        let namea = "";
        while (name != namea) {
            namea = name;
            name = name.replace("_", " ")
        }
        return name;
    }
    static getName(i: string) {
        if (Itemtyp.findtyp(i) == null) {
            return;
        }
        let name = (Item.raritycolours[Item.rarities.indexOf(Itemtyp.findtyp(i).rarity)]) + Itemtyp.findtyp(i).Name + "~ffffff"
        let namea = "";
        while (name != namea) {
            namea = name;
            name = name.replace("_", " ")
        }
        return name;
    }
    UI(cd: ClientData): string {
        if (Itemtyp.findtyp(this.itemtyp) == null) {
            return;
        }
        this.calcStats(cd);
        let name = this.name();
        let stats = "";
        for (let j = 0; j < this.stats.length; j++) {
            const e = this.stats[j];
            if (e != 0) {
                let s = Item.statnames[j];
                stats = stats + "~bbbbbb" + s + ":" + Item.statcolors[j] + " " + this.stats[j] + "\n"
            }
        }
        let enchants = "\n";
        if (this.s.length > 0) {
            let ehelp = EnchantType.toArray(this.s[0]);
            for (let i = 0; i < ehelp.length; i++) {
                const e = ehelp[i];
                let en = EnchantType.findEnchant(e.enchant);
                if (en == null) {
                    continue;
                }
                enchants = enchants + "\n" + (e.tier >= en.maxSpTier ? "~ffdd00" : (e.tier > en.maxTier ? "~aa00aa" : (e.tier == en.maxTier ? "~0000ff" : (e.tier > 1 ? "~00ff00" : "~bbbbbb")))) + e.enchant + " " + EnchantType.numberToString(e.tier);
                // enchants = enchants + "\n" + (e.tier <= 1 ? "~bbbbbb" : (e.tier < en.maxTier ? "~00ff00" : (e.tier == en.maxTier ? "~0000ff" : (e.tier < en.maxSpTier ? "~0000ff" : "~ffdd00")))) + e.enchant + " " + EnchantType.numberToString(e.tier);
            }
        }
        let rune = RuneType.findRune(this.s[4]) == null ? "" : "\n" + RuneType.findRune(this.s[4]).color + this.s[4] + " " + EnchantType.numberToString(Number.parseFloat(this.s[5]));
        let ui = name + "\n" + stats + enchants + rune + "\n" + Itemtyp.findtyp(this.itemtyp).ui(cd, this) + "\n" + Item.raritycolours[Item.rarities.indexOf(Itemtyp.findtyp(this.itemtyp).rarity)] + Item.raritynames[Item.rarities.indexOf(Itemtyp.findtyp(this.itemtyp).rarity)];
        return ui;
    }
    addStat(stat: stats, a: number) {
        this.stats[Item.stats.indexOf(stat)] += a;
    }
    getStat(stat: stats): number {
        return this.stats[Item.stats.indexOf(stat)]
    }
    calcStats(c: ClientData) {
        let en = EnchantType.toArray(this.s[0]);
        for (let i = 0; i < Item.stats.length; i++) {
            const e = Item.stats[i];
            this.stats[i] = 0;
        }
        for (let i = 0; i < en.length; i++) {
            if (EnchantType.findEnchant(en[i].enchant) == null) {
                continue;
            }
            const e = EnchantType.findEnchant(en[i].enchant).stats(en[i].tier, this, c);
            for (let i = 0; i < e.length; i++) {
                const e2 = e[i];
                this.addStat(e2.stat, e2.amount);
            }
        }
        let h = Itemtyp.findtyp(this.itemtyp);
        if (h == null) {
            console.log("cannot find type " + this.itemtyp)
            return;
        }
        let st=Array.isArray(h.stats)?h.stats:h.stats(c);
        for (let i = 0; i < st.length; i++) {
            const e = st[i];
            this.addStat(e.stat, e.amount);
        }
        if (this.s.length >= 3 && this.s[2] != null && this.s[2] != "" && ReforgeType.findReforge(this.s[2]) != null) {
            let l = ReforgeType.findReforge(this.s[2]);
            for (let i = 0; i < l.stats.length; i++) {
                const e = l.stats[i];
                this.addStat(e.stat, e.amount);
            }
        }
    }
}
export class EntityC {
    constructor(public map: number, public x: number, public y: number, public grafik: string, public text: string, public rotation: number, public scale: number, public id: number, public hitboxc: number[]) {

    }
}
export abstract class Entity {
    static entities: Entity[] = [];
    static id: number = 0;
    id: number = Entity.id++;
    hitboxc: number[];
    scale: number = 1;
    rotation: number = 0;
    constructor(public map: number, public x: number, public y: number, public typ: string, public grafik: string, public text: string) {
        Entity.entities.push(this)
        this.hitboxc = this.hitbox();
    }
    abstract act(c: ClientData[]);
    abstract onClick(c: ClientData, x: number, y: number);
    //x1,x2,y1,y2 relativ zu x und y
    abstract hitbox(): number[];
    static deleteNPCByName(name: string) {
        if (Entity.entities.indexOf(Entity.entities.find((v, i) => { return v.typ == "NPC" && (<NPC>v).name == name })) > -1) {
            Entity.entities.splice(Entity.entities.indexOf(Entity.entities.find((v, i) => { return v.typ == "NPC" && (<NPC>v).name == name })), 1)
        }
    }
}
export class NPC extends Entity {
    constructor(map: number, x: number, y: number, public name: string, grafik: string, public click: (c: ClientData) => void) {
        super(map, x, y, "NPC", grafik, name)
    }
    act(c: ClientData[]) {

    }
    onClick(c: ClientData, x: number, y: number) {
        this.click(c)
    }
    hitbox(): number[] {
        return [-30, 30, -40, 40]
    }
}
export class Damage extends Entity {
    constructor(map: number, x: number, y: number, public name: string, public time: number) {
        super(map, x, y, "Damage", "", name)
    }
    act(c: ClientData[]) {
        this.time--;
        if (this.time <= 0) {
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
        }
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class Snow_part extends Entity {
    time: number = 120;
    constructor(map: number, x: number, public oy: number) {
        super(map, x, oy, "Snow_part", "items/Snowflake.png", "")
        this.scale = 0.1
    }
    act(c: ClientData[]) {
        this.y += 1;
        this.time--;
        if (this.time <= 0) {
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
        }
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class CirRing extends Entity {
    time: number = 0;
    constructor(map: number, public ox: number, public oy: number, public damage: number, public c: string) {
        super(map, ox, oy, "CirRing", "items/blocks/ERROR.png", "")
        this.scale = 300 / 5 / 32
        this.act(Inventar.server.clients);
    }
    act(c: ClientData[]) {
        this.x = Math.sin(this.time * Math.PI / 180) * 300 + this.ox;
        this.y = Math.cos(this.time * Math.PI / 180) * 300 + this.oy;
        if (this.time % 15 == 0 && this.time >= 45) {
            Collection.playerInCircle(this.x, this.y, 150, this.damage, Inventar.server.findPlayer(this.c));
        }
        if (this.time >= 30 * 20) {
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
        }
        this.time++;
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class ZSTNT extends Entity {
    vy: number = -20;
    ay: number = 1;
    time: number = this.vy / this.ay * 2;
    constructor(map: number, x: number, public oy: number, public damage: number) {
        super(map, x, oy, "ZSTNT", "items/blocks/tnt.png", "")
    }
    act(c: ClientData[]) {
        this.y += this.vy;
        this.vy += this.ay;
        if (this.y >= this.oy && this.vy > 0) {
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
            // for (let i = 0; i < Inventar.server.clients.length; i++) {
            //     const e = Inventar.server.clients[i];
            //     if (e.online && e.md.map == this.map && Math.sqrt(Math.pow(e.x - this.x, 2) + Math.pow(e.y - this.oy, 2)) < 150) {
            //         e.takeDMG((150 - Math.sqrt(Math.pow(e.x - this.x, 2) + Math.pow(e.y - this.oy, 2))) * 2);
            //     }
            // }
            Collection.circleHitbox(this.x, this.y, 150, this.damage)
        }
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class liRing extends Entity {
    time: number = 0;
    constructor(map: number, x: number, public oy: number, public damage: number) {
        super(map, x, oy, "liRing", "items/blocks/ERROR.png", "")
        this.scale = 2 / 16 / 5;
    }
    act(c: ClientData[]) {
        this.time++;
        if (this.time >= 30 * 5) {
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
            // for (let i = 0; i < Inventar.server.clients.length; i++) {
            //     const e = Inventar.server.clients[i];
            //     if (e.online && e.md.map == this.map && Math.sqrt(Math.pow(e.x - this.x, 2) + Math.pow(e.y - this.oy, 2)) < this.scale * 16 * 5) {
            //         e.takeDMG(500);
            //     }
            // }
            Collection.circleHitbox(this.x, this.y, this.scale * 16 * 5, this.damage)
        } else {
            this.scale += 2 / 16 / 5;
        }
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class ZSTNT2 extends Entity {
    vy: number = -20;
    ay: number = 1;
    time: number = this.vy / this.ay * 2;
    y2: number = 0;
    constructor(map: number, x: number, public oy: number) {
        super(map, x, oy, "ZSTNT2", "items/blocks/ERROR.png", "")
        // this.y2=oy;
        this.scale = 150 / 16 / 5
    }
    act(c: ClientData[]) {
        this.y2 += this.vy;
        this.vy += this.ay;
        if (this.y2 >= 0 && this.vy > 0) {
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
        }
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class BBT extends Entity {
    constructor(map: number, x: number, y: number, public progress: number) {
        super(map, x, y, "BBT", "", "")
        this.act();
        this.scale = 0.4;
    }
    act() {
        this.grafik = this.progress < 0.1 ? "" : "items/blocks/breaking/breaking_block-" + Math.floor(this.progress * 10) + ".png";
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class Line extends Entity {
    constructor(map: number, x1: number, y1: number, x2: number, y2: number) {
        super(map, (x1 + x2) / 2, (y1 + y2) / 2, "Line", "mobs/line.png", "")
        this.scale = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2)) / 5 / 128;
        this.rotation = -Math.atan2(x1 - x2, y1 - y2);
    }//TODO
    act() {
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
export class PLine extends Entity {
    constructor(map: number, public p1: PFPoint, public p2: PFPoint) {
        super(map, (p1.x + p2.x) / 2, (p1.y + p2.y) / 2, "Line", "mobs/line.png", "")
        this.scale = Math.sqrt(Math.pow(this.p1.x - p2.x, 2) + Math.pow(this.p1.y - p2.y, 2)) / 5 / 128;
        this.rotation = -Math.atan2(this.p1.x - p2.x, this.p1.y - p2.y);
    }//TODO
    act() {
        this.x = (this.p1.x + this.p2.x) / 2;
        this.y = (this.p1.y + this.p2.y) / 2;
        this.scale = Math.sqrt(Math.pow(this.p1.x - this.p2.x, 2) + Math.pow(this.p1.y - this.p2.y, 2)) / 5 / 128;
        this.rotation = -Math.atan2(this.p1.x - this.p2.x, this.p1.y - this.p2.y);
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [0, 0, 0, 0]
    }
}
// export class Projectile extends Entity {
//     constructor(map: number, x: number, y: number, public name: string, public time: number, public vx: number, public vy: number, public act2: (that: Projectile) => void, texture: string, public owner: string, public damage: number, public onHit: (g: Gegner) => boolean = () => { return true }, speed: number = NaN, public buffs: stat[] = [], public radius: number = 0, public m: boolean = false, public arrow: Item = null) {
//         super(map, x, y, "Damage", texture, name)
//         if (!Number.isNaN(speed)) {
//             let l = Math.sqrt((this.x - vx) * (this.x - vx) + (this.y - vy) * (this.y - vy));
//             this.vx = -(this.x - vx) / l * speed;
//             this.vy = -(this.y - vy) / l * speed;
//         }
//     }
//     act(c: ClientData[]) {
//         this.act2(this);
//         for (let i = 0; i < Entity.entities.length; i++) {
//             const e = Entity.entities[i];
//             if (e instanceof Gegner && ((e.hitbox()[0] + e.x < this.hitbox()[0] + this.x && e.hitbox()[1] + e.x > this.hitbox()[0] + this.x) || (e.hitbox()[0] + e.x < this.hitbox()[1] + this.x && e.hitbox()[1] + e.x > this.hitbox()[1] + this.x)) && ((e.hitbox()[2] + e.y < this.hitbox()[2] + this.y && e.hitbox()[3] + e.y > this.hitbox()[2] + this.y) || (e.hitbox()[2] + e.y < this.hitbox()[3] + this.y && e.hitbox()[3] + e.y > this.hitbox()[3] + this.y))) {
//                 e.getDamaged(Inventar.server.findPlayer(this.owner), this.damage, this.m);
//                 if (this.onHit(e)) {
//                     this.time = 0;
//                     break;
//                 }
//             }
//         }
//         this.time--;
//         this.x += this.vx;
//         this.y += this.vy;
//         if (this.time <= 0) {
//             Entity.entities.splice(Entity.entities.indexOf(this), 1);
//         }
//     }
//     onClick(c: ClientData, x: number, y: number) {
//     }
//     hitbox(): number[] {
//         return [-10, 10, -10, 10]
//     }
// }
export class Projectile extends Entity {
    constructor(map: number, x: number, y: number, public name: string, public time: number, public vx: number, public vy: number, public i: Item, public act2: (that: Projectile) => boolean, texture: string, public owner: string, public damage: number, public onHit: (g: Gegner) => boolean = () => { return true }, speed: number = NaN, public buffs: stat[] = [], public radius: number = 0, public m: boolean = false, public arrow: Item = null) {
        super(map, x, y, "Damage", texture, name)
        if (!Number.isNaN(speed)) {
            let l = Math.sqrt((this.x - vx) * (this.x - vx) + (this.y - vy) * (this.y - vy));
            this.vx = -(this.x - vx) / l * speed;
            this.vy = -(this.y - vy) / l * speed;
        }
        this.act(Inventar.server.clients);
    }
    act(c: ClientData[]) {
        if (this.act2(this)) {
            this.actC(c);
            this.actM(c);
        }
    }
    actC(c: ClientData[]) {
        for (let i = 0; i < Entity.entities.length; i++) {
            const e = Entity.entities[i];
            if (e instanceof Gegner && ((e.hitbox()[0] + e.x < this.hitbox()[0] + this.x && e.hitbox()[1] + e.x > this.hitbox()[0] + this.x) || (e.hitbox()[0] + e.x < this.hitbox()[1] + this.x && e.hitbox()[1] + e.x > this.hitbox()[1] + this.x)) && ((e.hitbox()[2] + e.y < this.hitbox()[2] + this.y && e.hitbox()[3] + e.y > this.hitbox()[2] + this.y) || (e.hitbox()[2] + e.y < this.hitbox()[3] + this.y && e.hitbox()[3] + e.y > this.hitbox()[3] + this.y))) {
                e.getDamaged(Inventar.server.findPlayer(this.owner), this.damage, this.m);
                if (this.onHit(e)) {
                    this.time = 0;
                    break;
                }
            }
        }
    }
    actM(c: ClientData[]) {
        this.time--;
        this.x += this.vx;
        this.y += this.vy;
        if (this.time <= 0) {
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
        }
        Ability.useItemAbility(this.i, this.x, this.y, Inventar.server.findPlayer(this.owner), "arrowtick")
    }
    onClick(c: ClientData, x: number, y: number) {
    }
    hitbox(): number[] {
        return [-10, 10, -10, 10]
    }
}
export class Gegner extends Entity {
    ziel: string = "";
    xpos: number = 0;
    ypos: number = 0;
    zielx: number = 0;
    ziely: number = 0;
    vx: number = 0;
    vy: number = 0;
    lvx: number = 0;
    lvy: number = 0;
    vz: number = 0;
    z: number = 0;
    ry: number = 0;
    geschlagen: boolean = false;
    leben: number = 100;
    maxLeben: number = 100;
    hittime: number = 0;
    damage: number[] = [];
    clients: string[] = [];
    playerbound: boolean = false;
    noKnockBack: boolean = false;
    slayerCollection: string = "";
    slayerTier: number = 0;
    effects: Effect[] = [];
    activeAttack: customMobAttack = null;
    t: number = 0;
    t2: number = 0;
    lastHealth: number;
    path: PFPoint[] = null;
    pathDist: number = 0;
    pathSeg: number = 0;
    pfPercentage: number = 0;
    constructor(map: number, x: number, y: number, public type: EnemyType) {
        super(map, x, y, "Testgegner", type.texture, "")
        this.xpos = x;
        this.ypos = y;
        this.ry = this.y;
        this.maxLeben = type.health;
        this.leben = this.maxLeben;
        if (type.tags.indexOf(tag.player_bound) > -1) {
            this.playerbound = true;
        }
        if (type.tags.indexOf(tag.slayer_boss) > -1) {
            this.playerbound = true;
        }
        if (type.tags.indexOf(tag.no_knockback) > -1) {
            this.noKnockBack = true;
        }
        if (type.tags.indexOf(tag.revenant_horror) > -1) {
            this.slayerCollection = "revenant_horror"
        }
        if (type.tags.indexOf(tag.path_find) <= -1) {
            this.type.tags.push(tag.path_find);
        }
        this.lastHealth = this.leben;
    }
    act(c: ClientData[]) {
        if (this.activeAttack == null) {
            let at: customMobAttack[] = [];
            let an: number[] = [];
            let s: number = 0;
            for (let i = 0; i < this.type.customAttacks.length; i++) {
                const a = this.type.customAttacks[i];
                const n = a.getAttackWeight(this, this.t);
                if (n == 1) {
                    this.activeAttack = a;
                    at = null;
                    this.t2 = 0;
                    break;
                }
                if (n > 0) {
                    at.push(a);
                    an.push(n);
                    s += n;
                }
            }
            let r = Math.random() * s;
            if (at != null) {
                for (let i = 0; i < an.length; i++) {
                    r -= an[i];
                    if (r <= 0) {
                        this.activeAttack = at[i];
                        this.t2 = 0;
                        break;
                    }
                }
            }
            if (this.activeAttack == null) {
                this.act2(c);
            }
        }
        if (this.activeAttack != null) {
            if (this.activeAttack.onAttackAct(this, this.t2)) {
                this.activeAttack = null;
            }
        }
        this.t++;
        this.t2++;
        this.lastHealth = this.leben;
        this.hittime--;
        let h = this.leben / this.maxLeben;
        this.text = this.type.name + "\n~" + (h > 0.5 ? "00" : "ff") + (h > 0.25 ? "ff" : "00") + "00" + this.leben + "/~00ff00" + this.maxLeben;//~ff0000~ffff00~00ff00
    }
    act2(c: ClientData[]) {
        let ziel: ClientData = c.find((value) => {
            return value.name == this.ziel;//ziel ohne this=interressanter Fehler
        })
        if (this.vz != 0 || this.z != 0) {
            if (this.type.tags.includes(tag.path_find)) {
                if (PathFinder.checkPointInBlock(Inventar.server.maps[this.map], this.x + this.vx + this.hitbox()[0], this.ry + this.vy + this.hitbox()[2], this.x + this.vx + this.hitbox()[1], this.ry + this.vy + this.hitbox()[3])) {
                    this.z = 0;
                    this.ry = this.y;
                    this.vz = 0;
                    this.vx = 0;
                    this.vy = 0;
                } else {
                    this.z += this.vz;
                    this.y += this.vz;
                    this.x += this.vx;
                    this.ry += this.vy;
                    this.y += this.vy;
                    this.vz += 1;
                    if (this.z >= 0) {
                        this.z = 0;
                        this.ry = this.y;
                        this.vz = 0;
                        this.vx = 0;
                        this.vy = 0;
                    }
                }
            } else {
                this.z += this.vz;
                this.y += this.vz;
                this.x += this.vx;
                this.ry += this.vy;
                this.y += this.vy;
                this.vz += 1;
                if (this.z >= 0) {
                    this.z = 0;
                    this.ry = this.y;
                    this.vz = 0;
                    this.vx = 0;
                    this.vy = 0;
                }
            }
            return;
        }
        if (ziel == null) {
            let nz;
            let na = 250;
            for (let i = 0; i < c.length; i++) {
                let c2 = c[i];
                if (c2.online) {
                    let a = Math.sqrt((this.x - c2.x) * (this.x - c2.x) + (this.y - c2.y) * (this.y - c2.y));
                    if (a <= na) {
                        na = a;
                        nz = c2;
                    }
                }
            }
            if (nz != null) {
                // ziel = nz;
                this.ziel = nz.name;
            }
        }
        if (this.path != null) {
            if (this.path[this.pathSeg] == null) {
                this.path = null;
                this.pathSeg = 0;
            } else if ((ziel != null && Math.sqrt((this.path[this.path.length - 1].x - ziel.x) * (this.path[this.path.length - 1].x - ziel.x) + (this.path[this.path.length - 1].y - ziel.y) * (this.path[this.path.length - 1].y - ziel.y)) > 30)
                || this.x != this.path[this.pathSeg].x
                || this.y != this.path[this.pathSeg].y) {
                this.path = null;
            }
        }
        if (this.path != null) {
            let dist = 5 * this.type.speed;
            while (dist > 0) {
                if (this.pathSeg + 1 >= this.path.length) {
                    this.path = null;
                    break;
                }
                let p1 = this.path[this.pathSeg];
                let p2 = this.path[this.pathSeg + 1];
                let d = Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));//*(1-this.pfPercentage);
                let md = 0;
                if (d > dist) {
                    md = 1 - 5 * this.type.speed / d;
                } else {
                    md = 1;
                    this.pathSeg++;
                    // this.pfPercentage=0;
                }
                dist -= Math.sqrt(Math.pow(this.x - (p1.x * md + p2.x * (1 - md)), 2) + Math.pow(this.y - (p1.y * md + p2.y * (1 - md)), 2));
                this.x = p1.x * md + p2.x * (1 - md);
                this.y = p1.y * md + p2.y * (1 - md);
                p1.x = this.x;
                p1.y = this.y;
            }
        }
        if (ziel != null) {
            if (!ziel.online) {
                if (this.playerbound) {
                    Entity.entities.splice(Entity.entities.indexOf(this), 1);
                    return;
                }
                this.ziel = "";
                this.xpos = this.x;
                this.ypos = this.y;
                return;
            }
            let vx = ziel.x - this.x;
            let vy = ziel.y - this.y;
            let a = Math.sqrt((vx) * (vx) + (vy) * (vy));
            let vx2 = ziel.x - this.xpos;
            let vy2 = ziel.y - this.ypos;
            let a2 = Math.sqrt((vx2) * (vx2) + (vy2) * (vy2));
            if (a < 50) {
                if (this.hittime <= 0) {
                    // ziel.health -= this.type.damage * 100 / ziel.getStat(stats.defence);
                    ziel.takeDMG(this.type.damage);
                    this.hittime = 10;
                }
                return;
            }
            if ((a > 400 && !this.geschlagen) || a > 1000 || a2 > this.type.followRange && !this.playerbound) {
                this.ziel = "";
                // this.xpos = this.x;
                // this.ypos = this.y;
                this.geschlagen = false;

                return;
            }
            if (this.type.tags.includes(tag.path_find)) {
                if (this.path == null) {
                    this.path = PathFinder.pathFind(this, new PFPoint(ziel.x, ziel.y));
                    this.pathSeg = 0;
                }
            } else {
                vx /= a;
                vy /= a;
                vx *= 5 * this.type.speed;
                vy *= 5 * this.type.speed;
                this.x += vx;
                this.ry += vy;
                this.y += vy;
                this.lvx = vx;
                this.lvy = vy;
            }
        } else {
            if (this.type.tags.includes(tag.path_find) && this.path == null) {
                if (Math.random() > 0.98) {
                    let zx;
                    let zy;
                    zx = Math.random() * 200 - 100 + this.xpos;
                    zy = Math.random() * 200 - 100 + this.ypos;
                    let a = Math.sqrt((this.xpos - zx) * (this.xpos - zx) + (this.ypos - zy) * (this.ypos - zy));
                    if (a <= 100) {
                        this.path = PathFinder.pathFind(this, new PFPoint(zx, zy))
                    }
                }
            } else if (!this.type.tags.includes(tag.path_find)) {
                let a = Math.sqrt((this.x - this.zielx) * (this.x - this.zielx) + (this.y - this.ziely) * (this.y - this.ziely));
                let a2 = Math.sqrt((this.xpos - this.zielx) * (this.xpos - this.zielx) + (this.ypos - this.ziely) * (this.ypos - this.ziely));
                if (a > 10 && a2 <= 200) {
                    let vx = this.zielx - this.x;
                    let vy = this.ziely - this.y;
                    vx /= a;
                    vy /= a;
                    vx *= 3 * this.type.speed;
                    vy *= 3 * this.type.speed;
                    this.x += vx;
                    this.ry += vy;
                    this.y += vy;
                } else {
                    if (Math.random() > 0.98) {
                        let zx;
                        let zy;
                        while (true) {
                            zx = Math.random() * 200 - 100 + this.xpos;
                            zy = Math.random() * 200 - 100 + this.ypos;
                            let a = Math.sqrt((this.xpos - zx) * (this.xpos - zx) + (this.ypos - zy) * (this.ypos - zy));
                            if (a <= 100) {
                                this.zielx = zx;
                                this.ziely = zy;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    getDamaged(c: ClientData, d: number = 1, m: boolean = false) {
        c.calcStats();
        if (c != null) {
            if (m) {
                d *= Math.floor(c.getStat(stats.intelligence) * c.getStat(stats.ability_damage) / 1000.0)
            } else {
                d *= Math.floor(((c.getStat(stats.crit_chance) > Math.random() * 100 ? c.getStat(stats.crit_damage) / 100.0 : 1) * c.getStat(stats.damage) * c.getStat(stats.strength) / 100.0) * c.getStat(stats.damage_multiplier) / 100);
            }
        }
        if (d == 0) {
            return;
        }
        let lebenAlt = this.leben;
        this.leben -= d;
        let g = this.clients.indexOf(c.name);
        new Damage(this.map, this.x + Math.random() * 100 - 50, this.y + Math.random() * 100 - 50, d + "", 30);
        if (this.leben <= 0) {
            // let it: Item = (<Item>c.Inventar.itemslots[c.selectedHotbarSlot].i);
            // if (it == null || !Itemtyp.findtyp(it.itemtyp).typ.includes(itemtypes.weapon)) {
            //     return;
            // }
            Ability.useAbilities(c, this.x, this.y, "kill")
            if (g == -1) {
                this.clients.push(c.name);
                this.damage.push(lebenAlt);
            } else {
                this.damage[g] += lebenAlt;
            }
            Entity.entities.splice(Entity.entities.indexOf(this), 1);
            // if (Math.random() < 0.1) {
            //     Inventar.server.give(c.name, new Item("stone_sword", 1, []));
            // }
            if (this.type.tags.includes(tag.slayer_boss_exp) && this.slayerCollection == c.md.pInfo[3]) {
                c.md.pInfo[5] = (Number.parseFloat(c.md.pInfo[5]) + this.type.exp) + "";
                if (Number.parseFloat(c.md.pInfo[5]) >= (Number.parseFloat(c.md.pInfo[4]) + 1) * 500) {
                    let h = new Gegner(c.md.map, this.x, this.y, Collection.findCollection(c.md.pInfo[3]).bosses[Number.parseFloat(c.md.pInfo[4])]);
                    h.slayerCollection = this.slayerCollection;
                    h.slayerTier = Number.parseFloat(c.md.pInfo[4]);
                    c.md.pInfo[3] = "";
                    c.md.pInfo[4] = "";
                    c.md.pInfo[5] = "";
                }
            }
            if (this.playerbound) {
                this.calcDrops(Inventar.server.findPlayer(this.ziel), 1)
                Collection.addClientCollectionAmount(Inventar.server.findPlayer(this.ziel), "combat", this.type.exp)
                if (this.type.tags.includes(tag.slayer_boss)) {
                    let t = this.slayerTier;
                    let f = t == 0 ? 5 : (t == 1 ? 25 : (t == 2 ? 100 : (t == 3 ? 500 : (t == 4 ? 1500 : 0))));
                    Collection.addClientCollectionAmount(Inventar.server.findPlayer(this.ziel), this.slayerCollection, f)
                    Inventar.server.msg("~ff00ffyou defeated " + this.slayerCollection + " " + EnchantType.numberToString(t + 1) + ". you gained " + f + " " + this.slayerCollection + " exp. you now have " + Collection.getClientCollectionAmount(Inventar.server.findPlayer(this.ziel), this.slayerCollection) + " " + this.slayerCollection + " exp.", this.ziel, true)
                }
            } else {
                if (this.type.boss) {
                    for (let i = 0; i < this.clients.length; i++) {
                        const e = this.clients[i];
                        Collection.addClientCollectionAmount(Inventar.server.findPlayer(e), "combat", this.damage[i] * this.type.exp / this.type.health)
                        this.calcDrops(Inventar.server.findPlayer(e), this.damage[i] / this.type.health);
                        Inventar.server.msg("~ff00ffyou helped to defeat " + this.type.name + ". you did " + this.damage[i] + "(" + Math.floor(this.damage[i] / this.type.health * 100) + "%) damage.", e, true)
                    }
                } else {
                    this.calcDrops(c, 1)
                    Collection.addClientCollectionAmount(c, "combat", this.type.exp)
                }
            }
        } else {
            if (g == -1) {
                this.clients.push(c.name);
                this.damage.push(d);
            } else {
                this.damage[g] += d;
            }
        }
    }
    onClick(c: ClientData, x: number, y: number) {
        let it: Item = (<Item>c.Inventar.itemslots[c.selectedHotbarSlot].i);
        if (it == null || !Itemtyp.findtyp(it.itemtyp).typ.includes(itemtypes.weapon)) {
            return;
        }
        Ability.useAbilities(c, x, y, "hit")
        if (!this.type.boss) {
            let a = Math.sqrt((x - c.x) * (x - c.x) + (y - c.y) * (y - c.y));
            let vx = c.x - x;
            let vy = c.y - y;
            vx /= a;
            vy /= a;
            vx *= -7;
            vy *= -7;
            this.vx = vx + this.lvx;
            this.vy = vy + this.lvy;
            this.vz = -3;
        }
        if (!this.playerbound) {
            this.ziel = c.name;
        }
        this.geschlagen = true;
        this.getDamaged(c)
        // this.leben -= (<Item>c.Inventar.itemslots[c.selectedHotbarSlot].i)?(<Item>c.Inventar.itemslots[c.selectedHotbarSlot].i).getStat(stats.damage):0;
    }
    calcDrops(c: ClientData, m: number) {
        for (let i = 0; i < this.type.drops.length; i++) {
            const e = this.type.drops[i];
            if (Math.random() < (e.rarity * c.getStat(stats.magic_find) * m / 100.0) && !(this.type.boss && e.rarity > 1 / (1 - m))) {
                // let a=(Math.floor(Math.random() * (e.maxcount - e.minCount))) + e.minCount
                let a = Math.floor(Math.random() * (e.maxcount - e.minCount + 1) + e.minCount)
                Inventar.server.give(c.name, Gegner.Item(e.item, a), true);
                if (e.rarity < 0.5) {
                    let he = Gegner.Item(e.item, a).name();
                    // he = he.replace("_", " ");
                    Inventar.server.msg("~ffff00RARE DROP! " + he + " ~44bbff(" + (e.rarity * c.getStat(stats.magic_find)) + "%)", c.name, true);
                }
            }
        }
    }
    static Item(i: string | ((a: number) => Item), a: number) {
        return typeof i == "string" ? new Item(i, a, []) : i(a);
    }
    hitbox(): number[] {
        return [-30, 30, -40, 40]
    }
}
export class GegnerSpawner {
    static gs: GegnerSpawner[] = [];
    timer: number = 0;
    g: Gegner[] = [];
    constructor(public x: number, public y: number, public map: number, public max: number, public time: number, public type: EnemyType, public radius: number) {
        GegnerSpawner.gs.push(this);
    }
    act() {
        this.timer++;
        if (this.max > this.g.length) {
            if (this.timer >= this.time / 30) {
                this.timer = 0;
                this.g.push(new Gegner(this.map, this.x + Math.random() * this.radius * 2 - this.radius, this.y + Math.random() * this.radius * 2 - this.radius, this.type));
            }
        }
        for (let i = 0; i < this.g.length; i++) {
            const e = this.g[i];
            if (e.leben <= 0) {
                this.g.splice(i, 1);
                i--;
            }
        }
    }
}
export enum tag {
    player_bound,
    no_knockback,
    slayer_boss,
    slayer_boss_exp,
    revenant_horror,
    path_find,
    dragon
}
export class EnemyType {
    static et: EnemyType[] = [];
    constructor(public health: number, public damage: number, public speed: number, public texture: string, public drops: Drop[], public name: string, public followRange: number, public exp: number, public tags: tag[], public boss: boolean = false, public customAttacks: customMobAttack[] = []) {
        EnemyType.et.push(this);
    }
}
export class Drop {
    constructor(public rarity: number, public minCount: number, public item: string | ((a: number) => Item), public maxcount: number = minCount) {

    }
}
export class Ability {
    constructor(public cooldown: number, public manaCost: number, public button: string, public use: (c: ClientData, x: number, y: number, i: Item, enchTier: number) => boolean) {

    }
    static useAbilities(c: ClientData, x: number, y: number, ab: string) {
        if (c.selArmSlot != 0) {
            for (let i = 0; i < 4; i++) {
                if (c.items[c.selArmSlot * 4 - 4 + i] != null) {
                    // it.push(c.items[c.selArmSlot * 4 - 4 + i]);
                    Ability.useItemAbility(c.items[c.selArmSlot * 4 - 4 + i], x, y, c, ab);
                }
            }
        }
        if ((<Item>c.Inventar.itemslots[c.selectedHotbarSlot].i) != null && Itemtyp.findtyp((<Item>c.Inventar.itemslots[c.selectedHotbarSlot].i).itemtyp).typ.includes(itemtypes.tool)) {
            Ability.useItemAbility((<Item>c.Inventar.itemslots[c.selectedHotbarSlot].i), x, y, c, ab);
        }
    }
    static useItemAbility(i: Item, x: number, y: number, c: ClientData, ab: string) {
        let it: Item = i;
        if (it != null) {
            let en = EnchantType.toArray(it.s[0]);
            for (let i = 0; i < en.length; i++) {
                const e5 = en[i];
                if (EnchantType.findEnchant(e5.enchant) == null) {
                    continue;
                }
                let e3 = EnchantType.findEnchant(e5.enchant).abilities;
                for (let j = 0; j < e3.length; j++) {
                    const e4 = e3[j];
                    if (e4.button == ab) {
                        Ability.useAbility(e4, c, x, y, it, en[i].tier);
                    }
                }
            }
            let a = Itemtyp.findtyp(it.itemtyp).abilities;
            for (let i = 0; i < a.length; i++) {
                const e = a[i];
                if (e.button == ab && c.mana >= e.manaCost) {
                    for (let i = 0; i < en.length; i++) {
                        // a.push(...EnchantType.findEnchant(en[i].enchant).abilities);
                    }
                    Ability.useAbility(e, c, x, y, it, 0);
                    // if (e.use(c, x, y, it,0)) {
                    //     c.mana -= e.manaCost;
                    //     if (Itemtyp.findtyp(it.itemtyp).typ.includes(itemtypes.consumable)) {
                    //         it.Amount--;
                    //         if (it.Amount <= 0) {
                    //             c.Inventar.itemslots[c.selectedHotbarSlot].i = null;
                    //         }
                    //         that.update(click.name);
                    //     }
                    // }
                }
            }
            if (RuneType.findRune(it.s[4]) != null) {
                for (let i = 0; i < RuneType.findRune(it.s[4]).abilities.length; i++) {
                    const a = RuneType.findRune(it.s[4]).abilities[i];
                    if (a.button == ab && c.mana >= a.manaCost) {
                        Ability.useAbility(a, c, x, y, it, Number.parseFloat(it.s[5]));
                    }
                }
            }
        }
    }
    static useAbility(ability: Ability, client: ClientData, x: number, y: number, it: Item, enchTier: number) {
        if (ability.use(client, x, y, it, enchTier)) {
            client.mana -= ability.manaCost;
            if (Itemtyp.findtyp(it.itemtyp).typ.includes(itemtypes.consumable)) {
                it.Amount--;
                if (it.Amount <= 0) {
                    client.Inventar.itemslots[client.Inventar.itemslots.indexOf(client.Inventar.itemslots.find((v) => { return v.i == it }))].i = null;
                }
                Inventar.server.update(client.name);
            }
        }
    }
}
export class Potion {
    constructor(public potion: string, public tier: number, public duration: number) {

    }
}
export class PotionType {
    static potions: PotionType[] = [];
    constructor(public name: string, public maxTier: number, public types: itemtypes[], public conflicting: string[] = []) {

    }
    static fillEnchants() {
        let armour = [itemtypes.burstplatte, itemtypes.helm, itemtypes.hose, itemtypes.schuhe];
    }
    static toArray(s: string): Potion[] {
        if (s == null) {
            return [];
        }
        let name: string[] = [];
        let lvl: number[] = [];
        let duration: number[] = [];
        let last = 0;
        if (s[s.length - 1] != ",") {
            s = s + ",";
        }
        for (let i = 0; i < s.length; i++) {
            const e = s[i];
            if (e == "#") {
                name.push(s.slice(last, i));
                last = i + 1;
            }
            if (e == ",") {
                lvl.push(Number.parseFloat(s.slice(last, i)));
                last = i + 1;
            }
            if (e == ":") {
                duration.push(Number.parseFloat(s.slice(last, i)));
                last = i + 1;
            }
        }
        let r: Potion[] = [];
        for (let i = 0; i < name.length; i++) {
            const e = name[i];
            r.push(new Potion(e, lvl[i], duration[i]));
        }
        return r;
    }
    static toString(s: Potion[]): string {
        let st = "";
        for (let i = 0; i < s.length; i++) {
            const e = s[i];
            st = st + e.potion + "#" + e.tier + ",";
        }
        return st;
    }
    static findPotion(s: String) {
        return PotionType.potions.find((value) => { return value.name == s });
    }
}
export class Effect {
    constructor(public effect: string, public tier: number, public duration: number) {

    }
}
export class EffectType {
    static effects: EffectType[] = [];
    constructor(public name: string, public description: string, public stats: (t: number) => stat[]) {

    }
    static giveEffectC(client: string, effect: string, d: number, t: number) {
        let h = Inventar.server.findPlayer(client).md.effects;
        let e = h.find((value) => { return value.effect == effect });
        if (e == null) {
            h.push(new Effect(effect, t, d));
            Inventar.server.findPlayer(client).calcStats();
            Inventar.server.update(client);
        } else {
            if (e.tier < t) {
                e.tier = t;
                e.duration = d;
                Inventar.server.findPlayer(client).calcStats();
                Inventar.server.update(client);
            } else if (e.tier = t) {
                if (e.duration < d) {
                    e.duration = d;
                    Inventar.server.findPlayer(client).calcStats();
                    Inventar.server.update(client);
                }
            }
        }
    }
    static giveEffectE(entity: Gegner, effect: string, d: number, t: number) {
        let h = entity.effects;
        let e = h.find((value) => { return value.effect == effect });
        if (e == null) {
            h.push(new Effect(effect, t, d));
        } else {
            if (e.tier < t) {
                e.tier = t;
                e.duration = d;
            } else if (e.tier = t) {
                if (e.duration < d) {
                    e.duration = d;
                }
            }
        }
    }
    static getEffectLevel(e: Effect[], effect: string): number {
        let h = e.find((value, index, obj) => { return value.effect == effect });
        return h == null ? 0 : h.tier;
    }
    static getEffectDuration(e: Effect[], effect: string): number {
        let h = e.find((value, index, obj) => { return value.effect == effect });
        return h == null ? 0 : h.duration;
    }
    static fillEffects() {
        EffectType.effects.push(new EffectType("regeneration", "gratually heals you", (t) => { return [new stat(stats.health_regen, t)] }))
    }
    static findEffect(s: String) {
        return EffectType.effects.find((value) => { return value.name == s });
    }
}
export class ReforgeType {
    static reforges: ReforgeType[] = [];
    constructor(public name: string, public appliable: (it: Item) => boolean, public stats: stat[]) {

    }
    static fillReforges() {
        let armour = [itemtypes.burstplatte, itemtypes.helm, itemtypes.hose, itemtypes.schuhe];
        ReforgeType.reforges.push(new ReforgeType("sharp", (it) => { return ReforgeType.arrayCompare([itemtypes.weapon], Itemtyp.findtyp(it.itemtyp).typ) }, [new stat(stats.damage, 10)]))
        ReforgeType.reforges.push(new ReforgeType("slow", (it) => { return ReforgeType.arrayCompare([itemtypes.weapon], Itemtyp.findtyp(it.itemtyp).typ) }, [new stat(stats.attack_speed, -30)]))
        ReforgeType.reforges.push(new ReforgeType("blunt", (it) => { return ReforgeType.arrayCompare([itemtypes.weapon], Itemtyp.findtyp(it.itemtyp).typ) }, [new stat(stats.damage, -30)]))
        ReforgeType.reforges.push(new ReforgeType("broken", (it) => { return ReforgeType.arrayCompare([itemtypes.weapon], Itemtyp.findtyp(it.itemtyp).typ) }, [new stat(stats.range, -30), new stat(stats.damage, -30)]))
        ReforgeType.reforges.push(new ReforgeType("annoying", (it) => { return ReforgeType.arrayCompare([itemtypes.weapon], Itemtyp.findtyp(it.itemtyp).typ) }, [new stat(stats.crit_damage, -100)]))
        ReforgeType.reforges.push(new ReforgeType("bulky", (it) => { return ReforgeType.arrayCompare([itemtypes.weapon], Itemtyp.findtyp(it.itemtyp).typ) }, [new stat(stats.attack_speed, -30)]))
    }
    static findReforge(s: String) {
        return ReforgeType.reforges.find((value) => { return value.name == s });
    }
    static arrayCompare(a1: any[], a2: any[]): boolean {
        for (let i = 0; i < a1.length; i++) {
            const e = a1[i];
            if (a2.includes(e)) {
                return true;
            }
        }
        return false;
    }
}
export class Enchant {
    constructor(public enchant: string, public tier: number) {

    }
}
export class RuneType {
    static runes: RuneType[] = [];
    constructor(public name: string, public maxTier: number, public types: itemtypes[], public maxSpTier: number = maxTier, public abilities: Ability[] = [], public color: string) {
        RuneType.runes.push(this);
    }
    static findRune(rune: string) {
        return RuneType.runes.find((value) => { return value.name == rune });
    }
    static fillRunes() {
        // let armour = [itemtypes.burstplatte, itemtypes.helm, itemtypes.hose, itemtypes.schuhe];
        new RuneType("firework rune", 3, [itemtypes.weapon], 3, [new Ability(0, 0, "kill", (c, x, y, i, t) => { Inventar.server.sendParticles(c.md.map, [new SendParticle(x, y, 0, 0, false, 0, -25, 10, 10, false, "items/Feuerball.png", 0, 1, "sprite", 1, 0, 1, 1, 1, 30, 0, 0, [new SendParticle(NaN, NaN, 0, 0, false, NaN, NaN, 10, 10, true, "items/Feuerball.png", 0, 1, "sprite", t * 3, 0, 0.1, 0.9, 0.9, 60, 15, 0.4)])]); return true; })], "~ff0000")
        new RuneType("frost rune", 3, [itemtypes.bow], 3, [new Ability(0, 0, "arrowtick", (c, x, y, i, t) => { if (Inventar.server.ticks % (8 - 2 * t) == 0) { Inventar.server.sendParticles(c.md.map, [new SendParticle(x, y, 0, 0, false, 0, 1, 10, 10, true, "items/Snowflake.png", 0, 1, "sprite", 1, 0, 0.1, 0.9, 0.9, 60, 15, 0.4)]); return true; } return false; })], "~9999ff")
        new RuneType("snow rune", 3, [itemtypes.weapon], 3, [new Ability(0, 0, "kill", (c, x, y, i, t) => { Inventar.server.sendParticles(c.md.map, [new SendParticle(x, y, 0, 0, false, 0, 1, 10, 10, true, "items/Snowflake.png", 0, 1, "sprite", t * 3, 0, 0.1, 0.9, 0.9, 60, 15, 0.4)]); return true; })], "~9999ff")
        new RuneType("points rune", 3, [itemtypes.helm], 3, [new Ability(0, 0, "tick", (c, x, y, i, t) => { if (Inventar.server.ticks % (40 - 10 * t) == 0) { let h = Math.random() * 3; Inventar.server.sendParticles(c.md.map, [new SendParticle(x, y, 30, 80, true, 0, -1, 0, 0, false, "items/" + (h > 2 ? "W" : (h > 1 ? "E" : "R")) + "P.png", 0, 1, "sprite", 1, 0, 0, 1, 1, 60, 15, 0.4)]); return true; } return false; })], "~0000ff")
        new RuneType("blizard rune", 3, [itemtypes.helm], 3, [new Ability(0, 0, "tick", (c, x, y, i, t) => { if (Inventar.server.ticks % (12 - 3 * t) == 0) { Inventar.server.sendParticles(c.md.map, [new SendParticle(x, y - 80, 60, 10, true, 0, 1, 0, 0, false, "items/Snowflake.png", 0, 1, "sprite", 1, 0, 0, 1, 1, 150, 0, 0.7)]); return true; } return false; })], "~0000ff")
        new RuneType("christmas rune", 3, [itemtypes.helm], 3, [new Ability(0, 0, "tick", (c, x, y, i, t) => { if (Inventar.server.ticks % (40 - 10 * t) == 0) { let h = Math.random() * 3; Inventar.server.sendParticles(c.md.map, [new SendParticle(x, y, 30, 80, true, 0, 0, 0, 0, false, "items/" + (h > 2 ? "Stern" : (h > 1 ? "Kerze" : "Christbaumkugel")) + ".png", 0, 1, "sprite", 1, 0, 0, 1, 1, 60, 15, 0.4)]); return true; } return false; })], "~00ff00")
        new RuneType("fire trail rune", 3, [itemtypes.schuhe], 3, [new Ability(0, 0, "move", (c, x, y, i, t) => { if (Math.random() > 0.7) { Inventar.server.sendParticles(c.md.map, [new SendParticle(x, y + 65, 30, 30, true, 0, 0, 0, 0, false, "items/Feuerball.png", 0, 1, "sprite", 1, 0, 0, 1, 1, 60, 15, 0.4)]); return true; } return false; })], "~ffcc00")
        new RuneType("inferno rune", 3, [itemtypes.burstplatte], 3, [new Ability(0, 0, "tick", (c, x, y, i, t) => { if (Inventar.server.ticks % (12 - 3 * t) == 0) { Inventar.server.sendParticles(c.md.map, [new SendParticle(x + Math.sin(Inventar.server.ticks / 10) * 30, y + 65, 0, 0, false, 0, -2, 0, 0, false, "items/Feuerball.png", 0, 1, "sprite", 1, 0, 0, 1, 1, 60, 0, 0)]); return true; } return false; })], "~ff8800")
    }
}
export class EnchantType {
    static enchants: EnchantType[] = [];
    constructor(public name: string, public maxTier: number, public types: itemtypes[], public stats: (lvl: number, i: Item, c: ClientData) => stat[], public maxSpTier: number = maxTier, public abilities: Ability[] = [], public conflicting: string[] = []) {
        EnchantType.enchants.push(this)
    }
    static fillEnchants() {
        let armour = [itemtypes.burstplatte, itemtypes.helm, itemtypes.hose, itemtypes.schuhe];
        // if (e.enchant == "protection") {
        //     this.addStat(stats.defence, e.tier * 10);
        // }
        // if (e.enchant == "sharpness") {
        //     this.addStat(stats.damage_multiplier, e.tier * 10);
        // }
        // if (e.enchant == "power") {
        //     this.addStat(stats.damage_multiplier, e.tier * 10);
        // }
        // if (e.enchant == "growth") {
        //     this.addStat(stats.health, e.tier * 10);
        // }
        let lh: number[] = [1, 2, 3, 4, 5, 7, 10, 15, 20, 30];
        let eh = [0, 10, 25, 100, 200, 400, 1000, 2000, 4500, 10000, Number.POSITIVE_INFINITY];
        new EnchantType("expertise", 5, [itemtypes.weapon], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.damage_multiplier, lvl * 10)] }, 10, [new Ability(0, 0, "kill", (c, x, y, i, tier) => {
            i.s[3] = Number.isNaN(Number.parseFloat(i.s[3])) ? "0" : ((Number.parseFloat(i.s[3]) + 1) + "");
            let h = EnchantType.toArray(i.s[0]);
            let h1 = h.find((v) => { return v.enchant == "expertise" }).tier;
            let h2 = eh.indexOf(eh.find((v) => { return v > Number.parseFloat(i.s[3]) }));
            h.find((v) => { return v.enchant == "expertise" }).tier = h2;
            if (h1 != h2) {
                Inventar.server.msg("~00ff00your ~0000ffexpertise~00ff00 enchant is now lvl. ~0000ff" + EnchantType.numberToString(h2) + "~00ff00.", c.name, true)
            }
            i.s[0] = EnchantType.toString(h);
            return true;
        })])
        new EnchantType("sharpness", 5, [itemtypes.weapon], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.damage_multiplier, lh[lvl] * 10)] })
        new EnchantType("lifesteal", 5, [itemtypes.weapon], (lvl: number, i: Item, c: ClientData) => { return [] }, 6, [new Ability(0, 0, "hit", (c, x, y, it, tier) => { c.health += lh[tier]; return true })])
        new EnchantType("scavenger", 5, [itemtypes.weapon], (lvl: number, i: Item, c: ClientData) => { return [] }, 5, [new Ability(0, 0, "kill", (c, x, y, it, tier) => { c.geld += lh[tier]; Inventar.server.update(c.name); return true })])
        new EnchantType("power", 5, [itemtypes.bow], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.damage_multiplier, lh[lvl] * 10)] }, 6)
        new EnchantType("snipe", 5, [itemtypes.bow], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.arrow_speed, lh[lvl] * 10)] }, 5)
        new EnchantType("harvesting", 5, [itemtypes.hoe], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.farming_fortune, lh[lvl] * 10)] })
        new EnchantType("efficiency", 5, [itemtypes.pickaxe], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.mining_speed, lh[lvl] * 10)] })
        new EnchantType("fortune", 5, [itemtypes.pickaxe], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.mining_fortune, lh[lvl] * 10)] })
        new EnchantType("foraging", 5, [itemtypes.pickaxe], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.foraging_speed, lh[lvl] * 10)] })
        new EnchantType("luckychop", 5, [itemtypes.pickaxe], (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.foraging_fortune, lh[lvl] * 10)] })
        new EnchantType("protection", 5, armour, (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.defence, lh[lvl] * 10)] })
        new EnchantType("growth", 5, armour, (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.health, lh[lvl] * 10)] })
        new EnchantType("rejuvenate", 5, armour, (lvl: number, i: Item, c: ClientData) => { return [new stat(stats.health_regen, lh[lvl] * 1)] })
        new EnchantType("bloodlust", 5, [itemtypes.burstplatte], (lvl: number, i: Item, c: ClientData) => { return [] }, 5, [new Ability(0, 0, "kill", (c, x, y, i, t) => { EffectType.giveEffectC(c.name, "regeneration2", 60, 1 * t); return true; })])
    }
    static toArray(s: string): Enchant[] {
        if (s == null) {
            return [];
        }
        let name: string[] = [];
        let lvl: number[] = [];
        let last = 0;
        if (s[s.length - 1] != ",") {
            s = s + ",";
        }
        for (let i = 0; i < s.length; i++) {
            const e = s[i];
            if (e == "#") {
                name.push(s.slice(last, i));
                last = i + 1;
            }
            if (e == ",") {
                lvl.push(Number.parseFloat(s.slice(last, i)));
                last = i + 1;
            }
        }
        let r: Enchant[] = [];
        for (let i = 0; i < name.length; i++) {
            const e = name[i];
            r.push(new Enchant(e, lvl[i]));
        }
        return r;
    }
    static toString(s: Enchant[]): string {
        let st = "";
        for (let i = 0; i < s.length; i++) {
            const e = s[i];
            st = st + e.enchant + "#" + e.tier + ",";
        }
        return st;
    }
    static findEnchant(s: String) {
        return EnchantType.enchants.find((value) => { return value.name == s });
    }
    static numberToString(n: number) {
        if (n == 1) {
            return "I";
        }
        if (n == 2) {
            return "II";
        }
        if (n == 3) {
            return "III";
        }
        if (n == 4) {
            return "IV";
        }
        if (n == 5) {
            return "V";
        }
        if (n == 6) {
            return "VI";
        }
        if (n == 7) {
            return "VII";
        }
        if (n == 8) {
            return "VIII";
        }
        if (n == 9) {
            return "IX";
        }
        if (n == 10) {
            return "X";
        }
        if (n == 11) {
            return "XI";
        }
        if (n == 12) {
            return "XII";
        }
        if (n == 13) {
            return "XIII";
        }
        if (n == 14) {
            return "XIV";
        }
        if (n == 15) {
            return "XV";
        }
        if (n == 16) {
            return "XVI";
        }
        if (n == 17) {
            return "XVII";
        }
        if (n == 18) {
            return "XVIII";
        }
        if (n == 19) {
            return "XIX";
        }
        if (n == 20) {
            return "XX";
        }
        if (n == 21) {
            return "XXI";
        }
        if (n == 22) {
            return "XXII";
        }
        if (n == 23) {
            return "XXIII";
        }
        if (n == 24) {
            return "XXIV";
        }
        if (n == 25) {
            return "XXV";
        }
        if (n == 26) {
            return "XXVI";
        }
        if (n == 27) {
            return "XXVII";
        }
        if (n == 28) {
            return "XXVIII";
        }
        if (n == 29) {
            return "XXIX";
        }
        if (n == 30) {
            return "XXX";
        }
        if (n == 31) {
            return "XXXI";
        }
        if (n == 32) {
            return "XXXII";
        }
        if (n == 33) {
            return "XXXIII";
        }
        if (n == 34) {
            return "XXXIV";
        }
        if (n == 35) {
            return "XXXV";
        }
        if (n == 36) {
            return "XXXVI";
        }
        if (n == 37) {
            return "XXXVII";
        }
        if (n == 38) {
            return "XXXVIII";
        }
        if (n == 39) {
            return "XXXIX";
        }
        if (n == 40) {
            return "XL";
        }
        if (n == 41) {
            return "XLI";
        }
        if (n == 42) {
            return "XLII";
        }
        if (n == 43) {
            return "XLIII";
        }
        if (n == 44) {
            return "XLIV";
        }
        if (n == 45) {
            return "XLV";
        }
        if (n == 46) {
            return "XLVI";
        }
        if (n == 47) {
            return "XLVII";
        }
        if (n == 48) {
            return "XLVIII";
        }
        if (n == 49) {
            return "XLIX";
        }
        if (n == 50) {
            return "L";
        }
        if (n == 51) {
            return "LI";
        }
        if (n == 52) {
            return "LII";
        }
        if (n == 53) {
            return "LIII";
        }
        if (n == 54) {
            return "LIV";
        }
        if (n == 55) {
            return "LV";
        }
        if (n == 56) {
            return "LVI";
        }
        if (n == 57) {
            return "LVII";
        }
        if (n == 58) {
            return "LVIII";
        }
        if (n == 59) {
            return "LIX";
        }
        if (n == 60) {
            return "LX";
        }
        if (n == 61) {
            return "LXI";
        }
        if (n == 62) {
            return "LXII";
        }
        if (n == 63) {
            return "LXIII";
        }
        if (n == 64) {
            return "LXIV";
        }
        if (n == 65) {
            return "LXV";
        }
        if (n == 66) {
            return "LXVI";
        }
        if (n == 67) {
            return "LXVII";
        }
        if (n == 68) {
            return "LXVIII";
        }
        if (n == 69) {
            return "LXIX";
        }
    }
}
export enum ColCat {
    combat, foraging, mining, skills,
    slayer,
    farming
}
export class Collection {
    static collections: Collection[] = [];
    constructor(public item: string, public rewards: Craft[][], public cost: number[], public category: ColCat, public bosses: EnemyType[] = []) {
        Collection.collections.push(this);
    }
    static fillCollections() {
        let skillsa1 = [];
        let skillsa2 = [];
        let skillsn2h = [0, 50, 125, 200, 300, 500, 750, 1000, 1500, 2000, 3500, 5000, 7500, 10000, 15000, 20000, 30000, 50000, 75000, 100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1100000, 1200000, 1300000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000, 2000000, 2100000, 2200000, 2300000, 2400000, 2500000, 2600000, 2750000, 2900000, 3100000, 3400000, 3700000, 4000000, 4300000, 4600000, 4900000, 5200000, 5500000, 5800000, 6100000, 6400000, 6700000, 7000000];
        let skillsn2 = [];
        for (let i = 0; i < skillsn2h.length - 1; i++) {
            const e1 = skillsn2h[i];
            const e2 = skillsn2h[i + 1];
            skillsn2.push(e2 - e1);
        }
        let skillsn1 = skillsn2.slice(0, 60);
        for (let i = 0; i < 50; i++) {
            skillsa1.push([]);
        }
        for (let i = 0; i < 60; i++) {
            skillsa2.push([]);
        }
        new Collection("rotten_flesh", [[new Craft([
            "", "32*rotten_flesh", "",
            "32*rotten_flesh", "32*rotten_flesh", "32*rotten_flesh",
            "", "32*rotten_flesh", ""], (item, c) => { return new Item("enchanted_rotten_flesh", 1, []) }, "enchanted_rotten_flesh", 1)], [new Craft([
                "", "32*enchanted_rotten_flesh", "",
                "", "32*enchanted_rotten_flesh", "",
                "", "stick", ""], (item, c) => { return new Item("zombie_sword", 1, []) }, "zombie_sword", 1)], [new Craft([
                    "enchanted_rotten_flesh", "enchanted_rotten_flesh", "enchanted_rotten_flesh",
                    "", "boney_handle", "",
                    "", "boney_handle", ""], (item, c) => { return new Item("rotten_pickaxe", 1, []) }, "rotten_pickaxe", 1)], [new Craft([
                        "", "", "",
                        "", "16*rotten_flesh", "16*rotten_flesh",
                        "", "16*rotten_flesh", "enchantment_core"], (item, c) => { return new Item("enchanted_book", 1, ["lifesteal#1,"]) }, "enchanted_book", 1)], [new Craft([
                            "16*rotten_flesh", "16*rotten_flesh", "16*rotten_flesh",
                            "16*rotten_flesh", "16*rotten_flesh", "16*rotten_flesh",
                            "16*rotten_flesh", "16*rotten_flesh", "16*rotten_flesh"], (item, c) => { return new Item("minion", 1, ["rotten_flesh", "0"]) }, "minion", 1)]], [50, 200, 500, 1000], ColCat.combat);
        new Collection("researcher_fragment", [[new Craft([
            "", "32*researcher_fragment", "",
            "32*researcher_fragment", "32*researcher_fragment", "32*researcher_fragment",
            "", "32*researcher_fragment", ""], (item, c) => { return new Item("summoning_gem", 1, []) }, "summoning_gem", 1)], [new Craft([
                "summoning_gem", "", "summoning_gem",
                "summoning_gem", "summoning_gem", "summoning_gem",
                "summoning_gem", "summoning_gem", "summoning_gem"], (item, c) => { return new Item("researcher_chestplate", 1, []) }, "researcher_chestplate", 1), new Craft([
                    "", "summoning_gem", "",
                    "summoning_gem", "summoning_gem", "summoning_gem",
                    "", "summoning_gem", ""], (item, c) => { return new Item("compressed_summoning_gem", 1, []) }, "compressed_summoning_gem", 1)], [new Craft([
                        "", "", "",
                        "", "16*researcher_fragment", "16*researcher_fragment",
                        "", "16*researcher_fragment", "enchantment_core"], (item, c) => { return new Item("enchanted_book", 1, ["sharpness#1,"]) }, "enchanted_book", 1)], [new Craft([
                            "39*researcher_fragment", "39*researcher_fragment", "",
                            "39*researcher_fragment", "39*researcher_fragment", "",
                            "", "", ""], (item, c) => { return new Item("summoning_gem", 1, []) }, "summoning_gem", 1)], [new Craft([
                                "", "compressed_summoning_gem", "",
                                "", "compressed_summoning_gem", "",
                                "", "boney_handle", ""], (item, c) => { return new Item("dragon_staff", 1, []) }, "dragon_staff", 1)]], [50, 200, 500, 1000, 2000], ColCat.combat);
        new Collection("bone", [[new Craft([
            "16*bone", "", "",
            "16*bone", "", "",
            "", "", ""], (item, c) => { return new Item("boney_handle", 1, []) }, "boney_handle", 1)], [new Craft([
                "", "32*bone", "",
                "32*bone", "32*bone", "32*bone",
                "", "32*bone", ""], (item, c) => { return new Item("enchanted_bone", 1, []) }, "enchanted_bone", 1)], [new Craft([
                    "", "enchanted_bone", "",
                    "", "enchanted_bone", "",
                    "", "boney_handle", ""], (item, c) => { return new Item("boney_longsword", 1, []) }, "boney_longsword", 1)], [new Craft([
                        "enchanted_bone", "enchanted_bone", "enchanted_bone",
                        "", "boney_handle", "",
                        "", "boney_handle", ""], (item, c) => { return new Item("bone_pickaxe", 1, []) }, "bone_pickaxe", 1)], [new Craft([
                            "", "", "",
                            "", "16*bone", "16*bone",
                            "", "16*bone", "enchantment_core"], (item, c) => { return new Item("enchanted_book", 1, ["power#1,"]) }, "enchanted_book", 1)], [new Craft([
                                "16*bone", "16*bone", "16*bone",
                                "16*bone", "16*bone", "16*bone",
                                "16*bone", "16*bone", "16*bone"], (item, c) => { return new Item("minion", 1, ["bone", "0"]) }, "minion", 1)], [new Craft([
                                    "", "boney_handle", "8*enchanted_bone",
                                    "boney_handle", "simple_bow", "8*enchanted_bone",
                                    "", "boney_handle", "8*enchanted_bone"], (item, c) => { return new Item("hurricane_bow", 1, item == null || item.length == 0 ? [] : item[4].s) }, "hurricane_bow", 1)]], [20, 50, 200, 500, 1000, 2000, 4000], ColCat.combat);
        new Collection("oak_log", [[new Craft([
            "", "64*oak_log", "",
            "", "64*oak_log", "",
            "", "64*oak_log", ""], (item, c) => { return new Item("wooden_handle", 1, []) }, "wooden_handle", 1)], [new Craft([
                "", "32*oak_log", "",
                "32*oak_log", "32*oak_log", "32*oak_log",
                "", "32*oak_log", ""], (item, c) => { return new Item("enchanted_oak_log", 1, []) }, "enchanted_oak_log", 1)], [new Craft([
                    "", "enchanted_oak_log", "enchanted_oak_log",
                    "", "wooden_handle", "enchanted_oak_log",
                    "", "wooden_handle", ""], (item, c) => { return new Item("wooden_axe", 1, []) }, "wooden_axe", 1)], [new Craft([
                        "enchanted_oak_log", "enchanted_oak_log", "enchanted_oak_log",
                        "enchanted_oak_log", "", "enchanted_oak_log",
                        "enchanted_oak_log", "enchanted_oak_log", "enchanted_oak_log"], (item, c) => { return new Item("small_minion_storage", 1, []) }, "small_minion_storage", 1)], [new Craft([
                            "", "", "",
                            "", "16*oak_log", "16*oak_log",
                            "", "16*oak_log", "enchantment_core"], (item, c) => { return new Item("enchanted_book", 1, ["scavenger#1,"]) }, "enchanted_book", 1)], [new Craft([
                                "16*oak_log", "16*oak_log", "16*oak_log",
                                "16*oak_log", "16*oak_log", "16*oak_log",
                                "16*oak_log", "16*oak_log", "16*oak_log"], (item, c) => { return new Item("minion", 1, ["oak_log", "0"]) }, "minion", 1)], [new Craft([
                                    "8*enchanted_oak_log", "8*enchanted_oak_log", "8*enchanted_oak_log",
                                    "8*enchanted_oak_log", "small_minion_storage", "8*enchanted_oak_log",
                                    "8*enchanted_oak_log", "8*enchanted_oak_log", "8*enchanted_oak_log"], (item, c) => { return new Item("medium_minion_storage", 1, item == null || item.length == 0 ? [] : item[4].s) }, "medium_minion_storage", 1)]], [20, 50, 200, 500, 1000, 2000, 4000], ColCat.foraging);
        new Collection("wheat", [[new Craft([
            "5*wheat", "5*wheat", "5*wheat",
            "5*wheat", "5*wheat", "5*wheat",
            "5*wheat", "5*wheat", "5*wheat"], (item, c) => { return new Item("fire_starter", 1, []) }, "fire_starter", 1)], [new Craft([
                "", "32*wheat", "",
                "32*wheat", "32*wheat", "32*wheat",
                "", "32*wheat", ""], (item, c) => { return new Item("enchanted_wheat", 1, []) }, "enchanted_wheat", 1)], [new Craft([
                    "", "16*enchanted_wheat", "16*enchanted_wheat",
                    "", "boney_handle", "16*enchanted_wheat",
                    "", "boney_handle", ""], (item, c) => { return new Item("linear_wheat_hoe", 1, []) }, "linear_wheat_hoe", 1)], [new Craft([
                        "enchanted_wheat", "", "enchanted_wheat",
                        "enchanted_wheat", "enchanted_wheat", "enchanted_wheat",
                        "enchanted_wheat", "enchanted_wheat", "enchanted_wheat"], (item, c) => { return new Item("sacrificial_altar", 1, []) }, "sacrificial_altar", 1)], [new Craft([
                            "", "", "",
                            "", "16*wheat", "16*wheat",
                            "", "16*wheat", "enchantment_core"], (item, c) => { return new Item("enchanted_book", 1, ["harvesting#1,"]) }, "enchanted_book", 1)], [new Craft([
                                "16*wheat", "16*wheat", "16*wheat",
                                "16*wheat", "16*wheat", "16*wheat",
                                "16*wheat", "16*wheat", "16*wheat"], (item, c) => { return new Item("minion", 1, ["wheat", "0"]) }, "minion", 1),new Craft([
                            "", "16*enchanted_wheat", "8*magical_wheat",
                            "", "linear_wheat_hoe", "16*enchanted_wheat",
                            "", "wooden_handle", ""], (item, c) => { return new Item("triangular_wheat_hoe", 1, item == null || item.length == 0 ? [] : item[4].s) }, "triangular_wheat_hoe", 1)], [new Craft([
                                    "8*enchanted_wheat", "8*enchanted_wheat", "8*enchanted_wheat",
                                    "8*enchanted_wheat", "", "8*enchanted_wheat",
                                    "8*enchanted_wheat", "8*enchanted_wheat", "8*enchanted_wheat"], (item, c) => { return new Item("perfect_hay_bale", 1, []) }, "perfect_hay_bale", 1)],[new Craft([
                                        "", "5*perfect_hay_bale", "5*wheat_soul",
                                        "", "triangular_wheat_hoe", "5*perfect_hay_bale",
                                        "", "5*wooden_handle", ""], (item, c) => { return new Item("tetrahedral_wheat_hoe", 1, item == null || item.length == 0 ? [] : item[4].s) }, "tetrahedral_wheat_hoe", 1)]], [20, 50, 200, 500, 1000, 2000, 4000, 10000], ColCat.farming);
        new Collection("dragon_fragment", [[new Craft([
            "", "", "",
            "old_dragon_fragment", "protector_dragon_fragment", "tough_dragon_fragment",
            "", "", ""], (item, c) => { return new Item("tanky_dragon_fragment", 1, []) }, "tanky_dragon_fragment", 1), new Craft([
                "", "", "",
                "unstable_dragon_fragment", "superior_dragon_fragment", "strong_dragon_fragment",
                "", "", ""], (item, c) => { return new Item("damaging_dragon_fragment", 1, []) }, "damaging_dragon_fragment", 1), new Craft([
                    "", "supporting_dragon_fragment", "",
                    "young_dragon_fragment", "", "wise_dragon_fragment",
                    "", "lucky_dragon_fragment", ""], (item, c) => { return new Item("magical_dragon_fragment", 1, []) }, "magical_dragon_fragment", 1)], [new Craft([
                        "magical_dragon_fragment", "enchanted_stone", "magical_dragon_fragment",
                        "magical_dragon_fragment", "enchantment_core", "magical_dragon_fragment",
                        "magical_dragon_fragment", "enchanted_stone", "magical_dragon_fragment"], (item, c) => { return new Item("mythical_handle", 1, []) }, "mythical_handle", 1)], [new Craft([
                        "", "", "",
                        "tanky_dragon_fragment", "magical_dragon_fragment", "damaging_dragon_fragment",
                        "", "", ""], (item, c) => { return new Item("ultimate_dragon_fragment", 1, []) }, "ultimate_dragon_fragment", 1)], [new Craft([
                            "", "8*damaging_dragon_fragment", "",
                            "", "8*damaging_dragon_fragment", "",
                            "", "boney_handle", ""], (item, c) => { return new Item("aspect_of_the_dragon", 1, []) }, "aspect_of_the_dragon", 1)], [new Craft([
                                "", "magical_dragon_fragment", "",
                                "magical_dragon_fragment", "enchanted_stone", "magical_dragon_fragment",
                                "", "magical_dragon_fragment", ""], (item, c) => { return new Item("enchantment_core", 1, []) }, "enchantment_core", 1)], [new Craft([
                                    "", "", "",
                                    "", "2*ultimate_dragon_fragment", "2*ultimate_dragon_fragment",
                                    "", "2*ultimate_dragon_fragment", "enchantment_core"], (item, c) => { return new Item("enchanted_book", 1, ["growth#1,"]) }, "enchanted_book", 1)], [new Craft([
                                        "", "32*magical_dragon_fragment", "",
                                        "", "32*magical_dragon_fragment", "",
                                        "", "boney_handle", ""], (item, c) => { return new Item("dragon_fury", 1, []) }, "dragon_fury", 1)], [new Craft([
                                            "", "64*damaging_dragon_fragment", "",
                                            "", "64*damaging_dragon_fragment", "",
                                            "", "aspect_of_the_dragon", ""], (item, c) => { return new Item("aspect_of_the_dragons", 1, item == null || item.length == 0 ? [] : item[7].s) }, "aspect_of_the_dragons", 1)]], [20, 50, 100, 200, 500, 1000, 2000], ColCat.combat);
        new Collection("stone", [[new Craft([
            "", "", "",
            "16*stone", "", "16*stone",
            "16*stone", "", "16*stone"], (item, c) => { return new Item("stone_boots", 1, []) }, "stone_boots", 1), new Craft([
                "16*stone", "16*stone", "16*stone",
                "16*stone", "", "16*stone",
                "16*stone", "", "16*stone"], (item, c) => { return new Item("stone_leggings", 1, []) }, "stone_leggings", 1), new Craft([
                    "16*stone", "", "16*stone",
                    "16*stone", "16*stone", "16*stone",
                    "16*stone", "16*stone", "16*stone"], (item, c) => { return new Item("stone_chestplate", 1, []) }, "stone_chestplate", 1), new Craft([
                        "16*stone", "16*stone", "16*stone",
                        "16*stone", "", "16*stone",
                        "", "", ""], (item, c) => { return new Item("stone_helmet", 1, []) }, "stone_helmet", 1)], [new Craft([
                            "", "32*stone", "",
                            "32*stone", "32*stone", "32*stone",
                            "", "32*stone", ""], (item, c) => { return new Item("enchanted_stone", 1, []) }, "enchanted_stone", 1), new Craft([
                                "strong_dragon_fragment", "supporting_dragon_fragment", "young_dragon_fragment",
                                "old_dragon_fragment", "enchanted_stone", "tough_dragon_fragment",
                                "protector_dragon_fragment", "superior_dragon_fragment", "wise_dragon_fragment"], (item, c) => { return new Item("magical_rock", 1, []) }, "magical_rock", 1)], [new Craft([
                                    "enchanted_stone", "enchanted_stone", "enchanted_stone",
                                    "", "boney_handle", "",
                                    "", "boney_handle", ""], (item, c) => { return new Item("pickaxe_made_of_stone", 1, []) }, "pickaxe_made_of_stone", 1)], [new Craft([
                                        "4*enchanted_stone", "4*enchanted_stone", "4*enchanted_stone",
                                        "4*enchanted_stone", "", "4*enchanted_stone",
                                        "4*enchanted_stone", "4*enchanted_stone", "4*enchanted_stone"], (item, c) => { return new Item("mythical_circle_of_stones", 1, []) }, "mythical_circle_of_stones", 1)], [new Craft([
                                            "", "", "",
                                            "", "16*stone", "16*stone",
                                            "", "16*stone", "enchantment_core"], (item, c) => { return new Item("enchanted_book", 1, ["protection#1,"]) }, "enchanted_book", 1)], [new Craft([
                                                "16*stone", "16*stone", "16*stone",
                                                "16*stone", "16*stone", "16*stone",
                                                "16*stone", "16*stone", "16*stone"], (item, c) => { return new Item("minion", 1, ["stone", "0"]) }, "minion", 1)], [new Craft([
                                                    "ultimate_dragon_fragment", "ultimate_dragon_fragment", "ultimate_dragon_fragment",
                                                    "ultimate_dragon_fragment", "magical_rock", "ultimate_dragon_fragment",
                                                    "ultimate_dragon_fragment", "ultimate_dragon_fragment", "ultimate_dragon_fragment"], (item, c) => { return new Item("super_magical_rock", 1, item == null || item.length == 0 ? [] : item[4].s) }, "super_magical_rock", 1)]], [20, 50, 200, 500, 1000, 2000], ColCat.mining);
        let idle = new customMobAttack("idle", (g, t) => {
            return 0.99;
        }, (g, t) => {
            g.act2(Inventar.server.clients);
            return true;
        })
        let launch = new customMobAttack("launch", (g, t) => {
            return Inventar.server.findPlayer(g.ziel) == null ? 0 : Math.sqrt(Math.pow(Inventar.server.findPlayer(g.ziel).x - g.x, 2) + Math.pow(Inventar.server.findPlayer(g.ziel).y - g.y, 2)) > 300 ? 0.05 : 0;
        }, (g, t) => {
            let z = Inventar.server.findPlayer(g.ziel);
            let d = Math.sqrt(Math.pow(z.x - g.x, 2) + Math.pow(z.y - g.y, 2));
            g.vx = (z.x - g.x) / d * 50;
            g.vy = (z.y - g.y) / d * 50;
            g.vz = -5;
            g.act2(Inventar.server.clients);
            return true;
        })
        new Collection("revenant_horror", [[new Craft([
            "", "", "64*revenant_flesh",
            "", "64*revenant_flesh", "",
            "revenant_handle", "", ""], (item, c) => { return new Item("wand_of_healing", 1, []) }, "wand_of_healing", 1), new Craft([
                "", "", "16*revenant_flesh",
                "", "16*revenant_flesh", "",
                "16*revenant_flesh", "", ""], (item, c) => { return new Item("revenant_handle", 1, []) }, "revenant_handle", 1)], [new Craft([
                    "", "32*revenant_flesh", "",
                    "32*revenant_flesh", "32*enchanted_bone", "32*revenant_flesh",
                    "", "32*revenant_flesh", ""], (item, c) => { return new Item("revenant_viscera", 1, []) }, "revenant_viscera", 1), new Craft([
                        "", "", "2*revenant_viscera",
                        "", "2*revenant_viscera", "",
                        "wand_of_healing", "", ""], (item, c) => { return new Item("wand_of_mending", 1, []) }, "wand_of_mending", 1)], [new Craft([
                            "", "32*revenant_flesh", "",
                            "32*revenant_flesh", "32*enchanted_bone", "32*revenant_flesh",
                            "", "32*revenant_flesh", ""], (item, c) => { return new Item("revenant_viscera", 1, []) }, "revenant_viscera", 1)]], [20, 50, 250], ColCat.slayer, [
            new EnemyType(10000, 150, 1.5, "mobs/zombie.png", [new Drop(1, 1, "revenant_flesh", 3)], "revenant horror I", 100000, 100, [tag.slayer_boss], false, [
                launch, idle]),
            new EnemyType(50000, 500, 3, "mobs/zombie.png", [new Drop(1, 4, "revenant_flesh", 10), new Drop(0.05, 1, "revenant_catalyst")], "revenant horror II", 100000, 100, [tag.slayer_boss], false, [launch, idle,
                new customMobAttack("lightningRing", (g, t) => {
                    return t % (30 * 30) == 0 && g.ziel != "" ? 1 : 0;
                }, (g, t) => {
                    if (t == 0) {
                        new liRing(g.map, g.x, g.y, 1000);
                    }
                    return t >= 30 * 5;
                }), new customMobAttack("throwTNT", (g, t) => {
                    return t % 30 == 0 && g.ziel != "" ? 1 : 0;
                }, (g, t) => {
                    let z = Inventar.server.findPlayer(g.ziel);
                    g.act2(Inventar.server.clients);
                    new ZSTNT(g.map, z.x, z.y, 400);
                    new ZSTNT2(g.map, z.x, z.y);
                    return true;
                })]),
            new EnemyType(300000, 1200, 3, "mobs/zombie.png", [new Drop(1, 4, "revenant_flesh", 10), new Drop(0.1, 1, "revenant_catalyst")], "revenant horror III", 100000, 100, [tag.slayer_boss], false, [
                new customMobAttack("safe circle", (g, t) => {
                    return g.lastHealth > 150000 && g.leben <= 150000 ? 1 : 0;
                }, (g, t) => {
                    g.leben = 150000;
                    if (t == 0) {
                        new CirRing(g.map, g.x, g.y, 500, g.ziel);
                    }
                    return t >= 30 * 20;
                }),
                launch, idle,
                new customMobAttack("lightningRing", (g, t) => {
                    return t % (30 * 30) == 0 && g.ziel != "" ? 1 : 0;
                }, (g, t) => {
                    if (t == 0) {
                        new liRing(g.map, g.x, g.y, 2000);
                    }
                    return t >= 30 * 5;
                }), new customMobAttack("throwTNT", (g, t) => {
                    return t % 30 == 0 && g.ziel != "" ? 1 : 0;
                }, (g, t) => {
                    let z = Inventar.server.findPlayer(g.ziel);
                    g.act2(Inventar.server.clients);
                    new ZSTNT(g.map, z.x, z.y, 800);
                    new ZSTNT2(g.map, z.x, z.y);
                    return true;
                })])]);
        new Collection("combat", skillsa1, skillsn1, ColCat.skills);
        new Collection("mining", skillsa1, skillsn1, ColCat.skills);
        new Collection("foraging", skillsa1, skillsn1, ColCat.skills);
        new Collection("fishing", skillsa1, skillsn1, ColCat.skills);
        new Collection("farming", skillsa1, skillsn1, ColCat.skills);
    }
    static rectangleHitbox(x1: number, y1: number, x2: number, y2: number, damage: number) {
        let hx = 30;
        let hy = 70;
        for (let i = 0; i < Inventar.server.clients.length; i++) {
            const e = Inventar.server.clients[i];
            if ((e.x - hx < x2 && e.x + hx > x1) && (e.y - hy < y2 && e.y + hy > y1)) {
                e.takeDMG(damage);
            }
        }
    }
    static circleHitbox(x: number, y: number, radius: number, damage: number) {
        let hx = 30;
        let hy = 70;
        for (let i = 0; i < Inventar.server.clients.length; i++) {
            const e = Inventar.server.clients[i];
            if (Collection.compDist(e.x + hx, e.y + hy, x, y, radius) || Collection.compDist(e.x - hx, e.y + hy, x, y, radius) || Collection.compDist(e.x + hx, e.y - hy, x, y, radius) || Collection.compDist(e.x - hx, e.y - hy, x, y, radius) || Collection.clientContainsPoint(x + radius, y + radius, e) || Collection.clientContainsPoint(x - radius, y + radius, e) || Collection.clientContainsPoint(x + radius, y - radius, e) || Collection.clientContainsPoint(x - radius, y - radius, e)) {
                e.takeDMG(damage);
            }
        }
    }
    static playerInCircle(x: number, y: number, radius: number, damage: number, c: ClientData) {
        let hx = 30;
        let hy = 70;
        if (!(Collection.compDist(c.x + hx, c.y + hy, x, y, radius) &&
            Collection.compDist(c.x - hx, c.y + hy, x, y, radius) &&
            Collection.compDist(c.x + hx, c.y - hy, x, y, radius) &&
            Collection.compDist(c.x - hx, c.y - hy, x, y, radius))) {
            c.takeDMG(damage);
        }
    }
    static clientContainsPoint(x: number, y: number, c: ClientData) {
        let hx = 30;
        let hy = 70;
        return c.x + hx < x && c.x - hx > x && c.y + hy < y && c.y - hy > y;
    }
    static pointHitbox(x: number, y: number, damage: number) {
        for (let i = 0; i < Inventar.server.clients.length; i++) {
            const e = Inventar.server.clients[i];
            if (this.clientContainsPoint(x, y, e)) {
                e.takeDMG(damage);
            }
        }
    }
    static compDist(x1: number, y1: number, x2: number, y2: number, dist: number): boolean {
        return (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2) <= dist * dist;
    }
    static lineHitbox(x1: number, y1: number, x2: number, y2: number, damage: number) {
        let hx = 30;
        let hy = 70;
        for (let i = 0; i < Inventar.server.clients.length; i++) {
            const e = Inventar.server.clients[i];
            if (Collection.lineCollision(x1, y1, x2, y2, e.x + hx, e.y + hy, e.x + hx, e.y - hy) || Collection.lineCollision(x1, y1, x2, y2, e.x + hx, e.y + hy, e.x - hx, e.y + hy) || Collection.lineCollision(x1, y1, x2, y2, e.x - hx, e.y + hy, e.x - hx, e.y - hy) || Collection.lineCollision(x1, y1, x2, y2, e.x + hx, e.y - hy, e.x - hx, e.y - hy)) {
                e.takeDMG(damage);
            }
        }
    }
    static Linienschnittpunkt(ax: number, bx: number, ay: number, by: number, cx: number, dx: number, cy: number, dy: number) {
        let ex = bx - ax;
        let ey = by - ay;
        let fx = dx - cx;
        let fy = dy - cy;
        let o = cy * ex - ay * ex - cx * ey + ax * ey;
        let u = fx * ey - fy * ex;
        if (u == 0) {
            return;
        }
        let l = o / u;
        let x = cx + l * fx;
        let y = cy + l * fy;
        return [x, y]
    }
    static lineCollision(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) {
        let s = Collection.Linienschnittpunkt(x1, x2, y1, y2, x3, x4, y3, y4);
        return s != null &&
            s[0] > Math.min(x1, x2) &&
            s[0] < Math.max(x1, x2) &&
            s[0] > Math.min(x3, x4) &&
            s[0] < Math.max(x3, x4) &&
            s[1] > Math.min(y1, y2) &&
            s[1] < Math.max(y1, y2) &&
            s[1] > Math.min(y3, y4) &&
            s[1] < Math.max(y3, y4);
    }
    static lineCollision2(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) {
        let s = Collection.Linienschnittpunkt(x1, x2, y1, y2, x3, x4, y3, y4);
        return s != null &&
            s[0] > Math.min(x1, x2) &&
            s[0] < Math.max(x1, x2) &&
            s[0] > Math.min(x3, x4) &&
            s[0] < Math.max(x3, x4) &&
            s[1] > Math.min(y1, y2) &&
            s[1] < Math.max(y1, y2) &&
            s[1] > Math.min(y3, y4) &&
            s[1] < Math.max(y3, y4);
    }
    static toArray(s: string): ColAm[] {
        if (s == null) {
            return [];
        }
        let name: string[] = [];
        let lvl: number[] = [];
        let last = 0;
        if (s[s.length - 1] != ",") {
            s = s + ",";
        }
        for (let i = 0; i < s.length; i++) {
            const e = s[i];
            if (e == ":") {
                name.push(s.slice(last, i));
                last = i + 1;
            }
            if (e == ",") {
                lvl.push(Number.parseFloat(s.slice(last, i)));
                last = i + 1;
            }
        }
        let r: ColAm[] = [];
        for (let i = 0; i < name.length; i++) {
            const e = name[i];
            r.push(new ColAm(e, lvl[i]));
        }
        return r;
    }
    static toString(s: ColAm[]): string {
        let st = "";
        for (let i = 0; i < s.length; i++) {
            const e = s[i];
            st = st + e.item + ":" + e.amount + ",";
        }
        return st;
    }
    static getClientCollectionAmount(c: ClientData, col: string): number {//
        let l1 = Collection.toArray(c.md.pInfo[1]);
        return l1.find((value) => value.item == col) != null ? l1.find((value) => value.item == col).amount : 0;
    }
    static addClientCollectionAmount(c: ClientData, col: string, a: number) {//
        let h = 0;//strong, old, protector, young, wise, superior, lucky, supporting, tough, unstable
        if (["strong_dragon_fragment", "old_dragon_fragment", "protector_dragon_fragment", "young_dragon_fragment", "wise_dragon_fragment", "superior_dragon_fragment", "lucky_dragon_fragment", "supporting_dragon_fragment", "tough_dragon_fragment", "unstable_dragon_fragment"].includes(col)) {
            col = "dragon_fragment"
        }
        let h2 = this.getClientCollectionAmount(c, col);
        let coll = this.findCollection(col);
        if (coll == null) {
            return;
        }
        for (let i = 0; i < coll.cost.length; i++) {
            const e = coll.cost[i];
            if (h2 < e) {
                break;
            } else {
                h++;
            }
        }
        let l1 = Collection.toArray(c.md.pInfo[1]);
        if (l1.find((value) => value.item == col) != null) {
            l1.find((value) => value.item == col).amount += a;
            c.md.pInfo[1] = Collection.toString(l1);
        } else {
            c.md.pInfo[1] += col + ":" + a + ",";
        }
        h2 = this.getClientCollectionAmount(c, col);
        let h3 = 0;
        for (let i = 0; i < coll.cost.length; i++) {
            const e = coll.cost[i];
            if (h2 < e) {
                break;
            } else {
                h3++;
            }
        }
        if (h3 > h) {
            Inventar.server.msg("~6666ff------------------------------------------------------------------------------------", c.name, true)
            Inventar.server.msg("~ffff00you unlocked reward" + (h + 2 == h3 + 1 ? "" : "s") + " " + EnchantType.numberToString(h + 2) + (h + 2 == h3 + 1 ? "" : " to " + EnchantType.numberToString(h3 + 1)) + " in the " + col + " collection!", c.name, true)
            Inventar.server.msg("~6666ff------------------------------------------------------------------------------------", c.name, true)
        }
    }
    static getRecipes(c: ClientData): Craft[] {
        let cr: Craft[] = [];
        for (let i = 0; i < Collection.collections.length; i++) {
            const e = Collection.collections[i];
            for (let i2 = 0; i2 < e.rewards.length; i2++) {
                const e2 = e.rewards[i2];
                const e3 = e.cost[i2];
                if (this.getClientCollectionAmount(c, e.item) >= e3) {
                    cr.push(...e2);
                }
            }
        }
        return cr;
    }
    static allRecipes(): Craft[] {
        let h: Craft[] = [];
        for (let i = 0; i < Collection.collections.length; i++) {
            const e = Collection.collections[i];
            for (let i2 = 0; i2 < e.rewards.length; i2++) {
                h = h.concat(e.rewards[i]);
                // h.push(...e.rewards[i]);
            }
        }
        for (let i = 0; i < h.length; i++) {
            const e = h[i];
            if (e == null) {
                h.splice(i, 1);
                i--;
            }
        }
        return h;
    }
    static findCollection(s: String) {
        return Collection.collections.find((value) => { return s == value.item });
    }
}
export class ColAm {
    constructor(public item: string, public amount: number) {

    }
}
export class Minion {
    static minions: Minion[] = [];
    constructor(public name: string, public duration: number[], public storage: number[], public cost: ColAm[], public genLoot: () => Item) {
        Minion.minions.push(this);
    }
    static fillMinions() {
        let n = [1, 2, 3, 5, 7, 9, 11, 13, 15, 17, 20];
        new Minion("rotten_flesh", [30, 25, 22, 20, 18, 15, 12, 10, 8, 6, 4], n, [new ColAm("rotten_flesh", 128), new ColAm("rotten_flesh", 256), new ColAm("rotten_flesh", 512), new ColAm("enchanted_rotten_flesh", 8), new ColAm("enchanted_rotten_flesh", 16), new ColAm("enchanted_rotten_flesh", 32), new ColAm("enchanted_rotten_flesh", 64), new ColAm("enchanted_rotten_flesh", 128), new ColAm("enchanted_rotten_flesh", 256), new ColAm("enchanted_rotten_flesh", 512)], () => { return new Item("rotten_flesh", 1, []) });
        new Minion("bone", [30, 25, 22, 20, 18, 15, 12, 10, 8, 6, 4], n, [new ColAm("bone", 128), new ColAm("bone", 256), new ColAm("bone", 512), new ColAm("enchanted_bone", 8), new ColAm("enchanted_bone", 16), new ColAm("enchanted_bone", 32), new ColAm("enchanted_bone", 64), new ColAm("enchanted_bone", 128), new ColAm("enchanted_bone", 256), new ColAm("enchanted_bone", 512)], () => { return new Item("bone", 1, []) });
        new Minion("stone", [30, 25, 22, 20, 18, 15, 12, 10, 8, 6, 4], n, [new ColAm("stone", 128), new ColAm("stone", 256), new ColAm("stone", 512), new ColAm("enchanted_stone", 8), new ColAm("enchanted_stone", 16), new ColAm("enchanted_stone", 32), new ColAm("enchanted_stone", 64), new ColAm("enchanted_stone", 128), new ColAm("enchanted_stone", 256), new ColAm("enchanted_stone", 512)], () => { return new Item("stone", 1, []) });
        new Minion("oak_log", [30, 25, 22, 20, 18, 15, 12, 10, 8, 6, 4], n, [new ColAm("oak_log", 128), new ColAm("oak_log", 256), new ColAm("oak_log", 512), new ColAm("enchanted_oak_log", 8), new ColAm("enchanted_oak_log", 16), new ColAm("enchanted_oak_log", 32), new ColAm("enchanted_oak_log", 64), new ColAm("enchanted_oak_log", 128), new ColAm("enchanted_oak_log", 256), new ColAm("enchanted_oak_log", 512)], () => { return new Item("oak_log", 1, []) });
        // new Minion("rotten_flesh", [30, 25, 22, 20, 18, 15, 12, 10, 8, 6, 4], n, [new ColAm("rotten_flesh", 128), new ColAm("rotten_flesh", 256), new ColAm("rotten_flesh", 512), new ColAm("enchanted_rotten_flesh", 8), new ColAm("enchanted_rotten_flesh", 16), new ColAm("enchanted_rotten_flesh", 32), new ColAm("enchanted_rotten_flesh", 64), new ColAm("enchanted_rotten_flesh", 128), new ColAm("enchanted_rotten_flesh", 256), new ColAm("enchanted_rotten_flesh", 512)], () => { return new Item("rotten_flesh", 1, []) });
    }
    static findMinion(minion: string): Minion {
        return Minion.minions.find((value) => { return value.name == minion });
    }
}
export enum Tool {
    pickaxe, hoe, shovel, axe,
    none
}
export class Block {
    static blocks: Block[] = [];
    constructor(public name: string, public duration: number, public loot: Drop[], public tool: Tool, public respawnDuration: number, public replacementblock: string, public serverStartReplacement: string, public exp: number, public breaking_power_req: number, public breakReplacementBlock: string = serverStartReplacement) {
        Block.blocks.push(this);
    }
    static fillBlocks() {
        new Block("dirt", 30, [new Drop(1, 1, "dirt", 1)], Tool.shovel, 5000, "", "dirt", 0, 1);
        new Block("#oak_wood", 30, [new Drop(1, 1, "oak_log", 1)], Tool.axe, 10000, "", "#oak_wood", 10, 1);
        new Block("#stone", 30, [new Drop(1, 1, "stone", 1)], Tool.pickaxe, 10000, "#stone2", "#stone", 10, 1);
        new Block("#stone2", Number.POSITIVE_INFINITY, [], Tool.pickaxe, 0, "", "#stone", 10, 1);
        new Block("#christmas_decoration", 3, [new Drop(1, 1, "christmas_tree_ball", 1), new Drop(0.4, 1, "christmas_tree_candle", 1), new Drop(0.1, 1, "christmas_tree_star", 1)], Tool.pickaxe, 1000, "#cd_2", "", 10, 1, "#christmas_decoration");
        new Block("#cd_2", -2, [], Tool.none, -1, "", "", 0, -1);
        new Block("wheat", 1, [new Drop(1, 1, "wheat", 1)], Tool.hoe, 10000, "", "wheat", 10, 1);
        new Block("#oak_leaves", 10, [], Tool.hoe, 10000, "", "#oak_leaves", 10, 0);
    }
    static findBlock(block: string): Block {
        return Block.blocks.find((value) => { return value.name == block });
    }
    static block(name: string, drop: string, tool: Tool, respawnTime: number, mineDuration: number, exp: number, bpr: number) {
        new Block(name, mineDuration, [new Drop(1, 1, drop, 1)], tool, respawnTime, name + "2", name, exp, bpr);
        new Block(name + "2", -2, [], Tool.none, -1, "", name, 0, -1);
    }
}
export class Event {
    static events: Event[] = [];
    active: boolean = false;
    constructor(public name: string, public isActive: (currentTime: Date) => boolean, public onServerStart: () => void, public onTick: () => void, public onEnd: () => void) {
        Event.events.push(this);
    }
    static fillEvents() {
        new Event("christmas", (ct) => { ct.setFullYear(1900); return ct.getTime() < new Date(0, 0, 0, 0, 0, 0, 0).getTime() == ct.getTime() > new Date(0, 0, 8, 23, 0, 0, 0).getTime(); }, () => {
            // new Event("christmas", (ct) => { ct.setFullYear(1900); return ct.getTime() < new Date(0, 11, 26, 0, 0, 0, 0).getTime() == ct.getTime() > new Date(0, 11, 24, 0, 0, 0, 0).getTime(); }, () => {
            Inventar.server.chat("~0000ffthe christmes event has begun!", true)
            new NPC(1, 0, -875, "Bruno", "mobs/Kämpfer2.png", (c) => {
                // if (Rang.findRank(c.rang).strength > 100) {
                c.Inventar.loadMenu(Menu.presents_main)
                // } else {
                // Inventar.server.msg("~ff0000this Content is currently only accessible as an admin or higher. try again later!", c.name, true)
                // }
            })
            new NPC(1, 250, -875, "Lumberjack", "mobs/Kämpfer2.png", (c) => {
                // if (Rang.findRank(c.rang).strength > 100) {
                c.Inventar.compro = "christmas tree decoration"
                c.Inventar.loadMenu(Menu.comm_proj)
                Inventar.server.npcMsg(c, "See this tree I planted here? I want to decorate it for christmas, but I can't find any decoration. Next to my friend Henry you should find a pile of dirt. That's where I last saw it. If you find anything, please come back to me so we can celebrate christmas with a proper tree!", "Lumberjack")
                // } else {
                // Inventar.server.msg("~ff0000this Content is currently only accessible as an admin or higher. try again later!", c.name, true)
                // }
            })
            new NPC(1, 250, -1125, "tree", "items/blocks/Christbaum ungeschmückt.png", (c) => {
                // if (Rang.findRank(c.rang).strength > 100) {
                // } else {
                // Inventar.server.msg("~ff0000this Content is currently only accessible as an admin or higher. try again later!", c.name, true)
                // }
            })
            new CommunityProject("christmas tree decoration", (c) => {
                new NPC(1, 250, -1125, "christmas tree", "items/blocks/Christbaum geschmückt.png", (c2) => {
                    // if (Rang.findRank(c.rang).strength > 100) {
                    // } else {
                    // Inventar.server.msg("~ff0000this Content is currently only accessible as an admin or higher. try again later!", c.name, true)
                    // }
                    let h = c[Inventar.server.clients.indexOf(c2)];
                    if (h == -1) {
                        Inventar.server.npcMsg(c2, "you have already claimed your reward for helping to decorate my tree.", "???")
                    } else if (h == 0) {
                        Inventar.server.npcMsg(c2, "you haven't helped decorate my tree, but I'll give you something anywaqy. Here you go!", "???")
                        c[Inventar.server.clients.indexOf(c2)] = -1;
                        Inventar.server.give(c2.name, new Item("enchanted_snow_flake", 1, []), true)
                    } else {
                        Inventar.server.npcMsg(c2, "thanks for helping to decorate my tree, Here you go!", "???")
                        c[Inventar.server.clients.indexOf(c2)] = -1;
                        Inventar.server.give(c2.name, new Item("enchanted_snow_flake", 1, []), true)
                        Inventar.server.give(c2.name, new Item("rune", Math.ceil(h / 400), ["", "", "", "", "christmas rune", "1"]), true)
                    }
                })
                Entity.deleteNPCByName("tree")
                Entity.deleteNPCByName("Lumberjack")
                new NPC(1, 250, -875, "Lumberjack", "mobs/Kämpfer2.png", (c) => {
                    // if (Rang.findRank(c.rang).strength > 100) {
                    Inventar.server.npcMsg(c, "Thanks for helping to decorate my tree!", "Lumberjack")
                    // } else {
                    // Inventar.server.msg("~ff0000this Content is currently only accessible as an admin or higher. try again later!", c.name, true)
                    // }
                })
                Inventar.server.chat("~ffff00the christmas tree is now finally decorated. maybe you should try clicking it?", true)
            }, [new ComItem("christmas_tree_ball", 3, 100), new ComItem("christmas_tree_star", 1, 200), new ComItem("christmas_tree_candle", 1, 100)], 50000);
            // Inventar.server.ce.push(new commProjServerSave("christmas tree decoration", [], 0))
            if (Inventar.server.maps != null) {
                Inventar.server.fill(19, 0, 24, 3, "#christmas_decoration", 0)
                Entity.deleteNPCByName("tree")
            }
        }, () => {
            if (Inventar.server.ticks == 0) {
                Inventar.server.fill(19, 0, 24, 3, "#christmas_decoration", 0)
            }
        }, () => {
            let he = Inventar.server.ce.find((v) => { return v.name == "christmas tree decoration" });
            if (he != null) {
                Inventar.server.ce.splice(Inventar.server.ce.indexOf(he), 1);
            }
            Inventar.server.fill(19, 0, 24, 3, "", 0)
            Entity.deleteNPCByName("bruno")
            Entity.deleteNPCByName("Lumberjack")
            Entity.deleteNPCByName("tree")
            Entity.deleteNPCByName("christmas tree")
            CommunityProject.comProj.splice(CommunityProject.comProj.indexOf(CommunityProject.comProj.find((value) => { return value.name == "christmas tree decoration" })))
            // Inventar.server.ce.splice(Inventar.server.ce.indexOf(Inventar.server.ce.find((value) => { return value.name == "christmas tree decoration" })))
        });
    }
    static findEvent(event: string): Event {
        return Event.events.find((value) => { return value.name == event });
    }
}
export class NPCShopItem {
    constructor(public item: string | (() => Item), public cost: number, public items: Item[]) {

    }
}
export class customMobAttack {
    constructor(public name: string, public getAttackWeight: (g: Gegner, t: number) => number, public onAttackAct: (g: Gegner, t: number) => boolean) {

    }
}
export class CommunityProject {
    static comProj: CommunityProject[] = [];
    constructor(public name: string, public onEnd: (c: number[]) => void, public comItems: ComItem[], public reqPoints: number) {
        CommunityProject.comProj.push(this);
    }
    static fillComProj() {

    }
    static findComPro(name: string): CommunityProject {
        return CommunityProject.comProj.find((value) => { return value.name == name });
    }
}
export class commProjServerSave {
    constructor(public name: string, public clients: number[], public points: number) {

    }
}
export class ComItem {
    constructor(public item: string, public amount: number, public points: number) {

    }
}
export class PathFinder {
    static corners: PFCorner[][] = [];
    static hlines: PFHelpCorner[][] = [];
    static lines: PFLine[][] = [];
    static h: number[] = [];
    static pathFind(g: Gegner, ziel: PFPoint): PFPoint[] {
        // g.type.tags.splice(g.type.tags.indexOf(tag.path_find))
        if (Inventar.server.maps[g.map] == null) {
            return null;
        }
        if (this.corners[g.map] == null) {
            this.corners[g.map] = [];
            this.lines[g.map] = [];
            for (let x = 0; x < Inventar.server.maps[g.map].s.length + 1; x++) {
                const e1 = Inventar.server.maps[g.map].s.length <= x ? null : Inventar.server.maps[g.map].s[x];
                const e2 = x - 1 <= 0 ? null : Inventar.server.maps[g.map].s[x - 1];
                for (let y = 0; y < (e2 == null ? e1.length + 1 : e2.length + 1); y++) {
                    const e211 = e1 == null ? "" : (e1.length <= y ? "" : e1[y]);
                    const e221 = e1 == null ? "" : (0 >= y ? "" : e1[y - 1]);
                    const e212 = e2 == null ? "" : (e2.length <= y ? "" : e2[y]);
                    const e222 = e2 == null ? "" : (0 >= y ? "" : e2[y - 1]);
                    let h22 = e211.length > 0 ? e211[0] == "#" : false;
                    let h12 = e221.length > 0 ? e221[0] == "#" : false;
                    let h21 = e212.length > 0 ? e212[0] == "#" : false;
                    let h11 = e222.length > 0 ? e222[0] == "#" : false;
                    if (!h11 && h12 && h21 && h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(-1, -1), false))
                    }
                    if (h11 && !h12 && h21 && h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(-1, 1), false))
                    }
                    if (h11 && h12 && !h21 && h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(1, -1), false))
                    }
                    if (h11 && h12 && h21 && !h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(1, 1), false))
                    }

                    if (h11 && !h12 && !h21 && !h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(1, 1), true))
                    }
                    if (!h11 && h12 && !h21 && !h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(-1, 1), true))
                    }
                    if (!h11 && !h12 && h21 && !h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(1, -1), true))
                    }
                    if (!h11 && !h12 && !h21 && h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(-1, -1), true))
                    }

                    if (!h11 && h12 && h21 && !h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(-1, 1), true))
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(1, -1), true))
                    }
                    if (h11 && !h12 && !h21 && h22) {
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(1, 1), true))
                        PathFinder.corners[g.map].push(new PFCorner(x * map.bb + Inventar.server.maps[g.map].x * map.bb - map.bb, y * map.bb + Inventar.server.maps[g.map].y * map.bb - map.bb, new PFPoint(-1, -1), true))
                    }
                }
            }
        }
        if (PathFinder.hlines[g.map] == null) {
            let sx = PathFinder.corners[g.map].slice();
            for (let i = 0; i < sx.length; i++) {
                const e = sx[i];
                sx[i] = new PFCorner(e.x, e.y, new PFPoint(e.direction.x, e.direction.y), e.convex);
            }
            let sy = sx.slice();
            sx.sort((a, b) => { return a.y - b.y == 0 ? a.x - b.x - a.direction.x : a.y - b.y })
            sy.sort((a, b) => { return a.x - b.x == 0 ? a.y - b.y - a.direction.y : a.x - b.x })
            for (let i = 0; i < sx.length; i += 2) {
                const e1 = sx[i];
                const e2 = sx[i + 1];
                PathFinder.lines[g.map].push(new PFLine(e1, e2));
            }
            for (let i = 0; i < sy.length; i += 2) {
                const e1 = sy[i];
                const e2 = sy[i + 1];
                PathFinder.lines[g.map].push(new PFLine(e1, e2));
            }
            for (let i = 0; i < sx.length; i++) {
                const e = sx[i];
                e.x += e.direction.x == 1 ? g.hitbox()[1] : g.hitbox()[0];
                e.y += e.direction.y == 1 ? g.hitbox()[3] : g.hitbox()[2];
            }
            let l: PFHelpCorner[] = [];
            let h = 2;
            // sx.push(new PFCorner(g.x, g.y, null, true), new PFCorner(ziel.x, ziel.y, null, true))
            for (let i = 0; i < sx.length; i++) {//-x,x,-y,y;
                if (!PathFinder.checkPointInBlock(Inventar.server.maps[g.map], sx[i].x + g.hitbox()[0], sx[i].y + g.hitbox()[2], sx[i].x + g.hitbox()[1], sx[i].y + g.hitbox()[3])) {
                    l.push(new PFHelpCorner(sx[i].x, sx[i].y, [], []));
                    for (let j = 0; j < l.length; j++) {//-x,x,-y,y;
                        if (!(sx[i].x == l[j].x && sx[i].y == l[j].y) && !PathFinder.checkPointInBlock(Inventar.server.maps[g.map], l[j].x + g.hitbox()[0], l[j].y + g.hitbox()[2], l[j].x + g.hitbox()[1], l[j].y + g.hitbox()[3])) {//sx[i].x != l[j].x && sx[i].y != l[j].y && 
                            if (!PathFinder.checkLineInBlock(g, sx[i].x, sx[i].y, l[j].x, l[j].y)) {
                                let len = Math.sqrt(Math.pow(sx[i].x - l[j].x, 2) + Math.pow(sx[i].y - l[j].y, 2));
                                l[l.length - 1].connected.push(l[j]);
                                l[j].connected.push(l[l.length - 1]);
                                l[l.length - 1].length.push(len);
                                l[j].length.push(len);
                                // new Line(0, l[j].x, l[j].y, l[l.length - 1].x, l[l.length - 1].y);
                                // new PLine(0,l[j],l[l.length - 1]);
                                h++;
                            }
                        }
                    }
                }
            }
            PathFinder.hlines[g.map] = l;
            PathFinder.h[g.map] = h;
        }
        let l: PFHelpCorner[] = [];
        for (let i = 0; i < PathFinder.hlines[g.map].length; i++) {
            const e = PathFinder.hlines[g.map][i];
            let n = new PFHelpCorner(e.x, e.y, e.length.slice(), []);
            l.push(n);
            for (let i = 0; i < e.connected.length; i++) {
                const e2 = e.connected[i];
                let id = PathFinder.hlines[g.map].indexOf(e2);
                if (id < l.length) {
                    l[id].connected.push(n);
                    n.connected.push(l[id]);
                }
            }
        }
        let h = PathFinder.h[g.map];
        let sx = [new PFCorner(g.x, g.y, null, true), new PFCorner(ziel.x, ziel.y, null, true)];
        for (let i = 0; i < sx.length; i++) {//-x,x,-y,y;
            if (!PathFinder.checkPointInBlock(Inventar.server.maps[g.map], sx[i].x + g.hitbox()[0], sx[i].y + g.hitbox()[2], sx[i].x + g.hitbox()[1], sx[i].y + g.hitbox()[3])) {
                l.push(new PFHelpCorner(sx[i].x, sx[i].y, [], []));
                for (let j = 0; j < l.length; j++) {//-x,x,-y,y;
                    if (!(sx[i].x == l[j].x && sx[i].y == l[j].y) && !PathFinder.checkPointInBlock(Inventar.server.maps[g.map], l[j].x + g.hitbox()[0], l[j].y + g.hitbox()[2], l[j].x + g.hitbox()[1], l[j].y + g.hitbox()[3])) {//sx[i].x != l[j].x && sx[i].y != l[j].y && 
                        if (!PathFinder.checkLineInBlock(g, sx[i].x, sx[i].y, l[j].x, l[j].y)) {
                            let len = Math.sqrt(Math.pow(sx[i].x - l[j].x, 2) + Math.pow(sx[i].y - l[j].y, 2));
                            l[l.length - 1].connected.push(l[j]);
                            l[j].connected.push(l[l.length - 1]);
                            l[l.length - 1].length.push(len);
                            l[j].length.push(len);
                            // new Line(0, l[j].x, l[j].y, l[l.length - 1].x, l[l.length - 1].y);
                            // new PLine(0,l[j],l[l.length - 1]);
                            h++;
                        }
                    }
                }
            }
        }
        let l2: PFHelpPoint[] = [new PFHelpPoint(g.x, g.y, null, 0, true, l[l.length - 2])];
        // let l2: PFHelpPoint[] = [new PFHelpPoint(g.x, g.y, null, 0, true, l[l.length - 2]), new PFHelpPoint(ziel.x, ziel.y, null, 0, false, l[l.length - 1])];
        if (l2[0].p.connected.includes(l[l.length - 1])) {
            return [new PFPoint(g.x, g.y), new PFPoint(ziel.x, ziel.y)];
        }
        for (let i = 0; i < Math.min(10, h); i++) {
            let le = Number.POSITIVE_INFINITY;
            let i2 = -1;
            let j2 = -1;
            let goal = false;
            for (let i = 0; i < l2.length; i++) {
                const e = l2[i];
                for (let j = 0; j < e.p.connected.length; j++) {
                    const e2 = e.p.connected[j];
                    if (e.p.length[j] + e.length < le) {
                        if (e2.x == ziel.x && e2.y == ziel.y) {
                            le = e.p.length[j] + e.length;
                            i2 = i;
                            j2 = j;
                            goal = true;
                        }
                        if (l2.find((value) => { return value.p == e2 }) == null) {
                            le = e.p.length[j] + e.length;
                            i2 = i;
                            j2 = j;
                            goal = false;
                        }
                    }
                }
            }
            if (j2 >= 0 && !goal) {
                let h2 = l2[i2];
                let he2 = l2[i2].p.connected[j2];
                l2.push(new PFHelpPoint(he2.x, he2.y, h2, le, h2.ggner, he2));
                // if(l2.length>0)new Line(0, he2.x, he2.y, l2[l2.length - 2].x, l2[l2.length - 2].y);
            }
            if (j2 >= 0 && goal) {
                const e = l2[i2];
                const e2 = e.p.connected[j2];
                let eh = e;
                let a: PFPoint[] = [new PFPoint(e2.x, e2.y), new PFPoint(e.x, e.y)];
                while (true) {
                    if (eh.path != null) {
                        eh = eh.path;
                        a.push(new PFPoint(eh.x, eh.y));
                    } else {
                        break;
                    }
                }
                let eh2 = l2.find((value) => { return value.p == e2 });
                // let a2: PFPoint[] = [new PFPoint(e2.x, e2.y)];
                a.reverse();
                // a.push(new PFPoint(e2.x, e2.y))
                // for (let i = 1; i < a.length; i++) {
                //     const e1 = a[i - 1];
                //     const e2 = a[i];
                //     new Line(0, e1.x, e1.y, e2.x, e2.y)
                // }
                return a;
            }
        }
        return null;


        // for (let i = 0; i < h; i++) {
        //     let le = Number.POSITIVE_INFINITY;
        //     let i2 = -1;
        //     let j2 = -1;
        //     let goal=false;
        //     for (let i = 0; i < l2.length; i++) {
        //         const e = l2[i];
        //         for (let j = 0; j < e.p.connected.length; j++) {
        //             const e2 = e.p.connected[j];
        //             if (e.p.length[j] + e.length < le) {
        //                 if (l2.find((value) => { return value.p == e2 }) != null && (e.ggner != l2.find((value) => { return value.p == e2 }).ggner)) {
        //                     le = e.p.length[j] + e.length;
        //                     i2 = i;
        //                     j2 = j;
        //                     goal=true;
        //                 }
        //                 if (l2.find((value) => { return value.p == e2 }) == null) {
        //                     le = e.p.length[j] + e.length;
        //                     i2 = i;
        //                     j2 = j;
        //                     goal=false;
        //                 }
        //             }
        //         }
        //     }
        //     if (j2 >= 0&&!goal) {
        //         let h2 = l2[i2];
        //         let he2 = l2[i2].p.connected[j2];
        //         l2.push(new PFHelpPoint(he2.x, he2.y, h2, le, h2.ggner, he2));
        //         // if(l2.length>0)new Line(0, he2.x, he2.y, l2[l2.length - 2].x, l2[l2.length - 2].y);
        //     }
        //     if (j2 >= 0&&goal) {
        //         const e = l2[i2];
        //         const e2 = e.p.connected[j2];
        //         let eh = e;
        //         let a: PFPoint[] = [new PFPoint(e.x, e.y)];
        //         while (true) {
        //             if (eh.path != null) {
        //                 eh = eh.path;
        //                 a.push(new PFPoint(eh.x, eh.y));
        //             } else {
        //                 break;
        //             }
        //         }
        //         let eh2 = l2.find((value) => { return value.p == e2 });
        //         let a2: PFPoint[] = [new PFPoint(eh2.x, eh2.y)];
        //         while (true) {
        //             if (eh2.path != null) {
        //                 eh2 = eh2.path;
        //                 a2.push(new PFPoint(eh2.x, eh2.y));
        //             } else {
        //                 break;
        //             }
        //         }
        //         let a3: PFPoint[];
        //         if (e.ggner) {
        //             a.reverse();
        //             a3 = a.slice();
        //             a3.push(...a2);
        //         } else {
        //             a2.reverse();
        //             a3 = a2.slice();
        //             a3.push(...a);
        //         }
        //         for (let i = 1; i < a3.length; i++) {
        //             const e1 = a3[i - 1];
        //             const e2 = a3[i];
        //             new Line(0, e1.x, e1.y, e2.x, e2.y)
        //         }
        //         return a3;
        //     }
        // }
        // return null;


        // let l:PFHelpPoint[]=[new PFHelpPoint(g.x,g.y,null,0,true),new PFHelpPoint(ziel.x,ziel.y,null,0,false)];
        // for (let i = 0; i < sx.length*sx.length; i++) {
        //     let length=Number.POSITIVE_INFINITY;
        //     let p:PFHelpPoint=null;
        //     let p2:PFCorner=null;
        //     for (let j = 0; j < l.length; j++) {
        //         const e = l[j];
        //         for (let i = 0; i < sx.length; i++) {
        //             const e2 = sx[i];
        //             if(!e2.convex){
        //                 let le=e.length*e.length+(e2.x-e.x)*(e2.x-e.x)+(e2.y-e.y)*(e2.y-e.y);
        //                 if(le<length){
        //                     length=le;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    static checkLineInBlock(g: Gegner, x1: number, y1: number, x2: number, y2: number): boolean {
        let lines = PathFinder.lines[g.map];
        for (let i = 0; i < lines.length; i++) {
            const e = lines[i];
            if (PathFinder.streckeSchneidetStrecke(x1, y1, x2, y2, e.p1.x, e.p1.y, e.p2.x, e.p2.y)) {
                return true;
            }
            // if (Collection.lineCollision2(x1, y1, x2, y2, e.p1.x, e.p1.y, e.p2.x, e.p2.y)) {
            //     return true;
            // }
        }
        let x = x1 / 2 + x2 / 2;
        let y = y1 / 2 + y2 / 2;
        if (PathFinder.checkPointInBlock(Inventar.server.maps[g.map], x + g.hitbox()[0], y + g.hitbox()[2], x + g.hitbox()[1], y + g.hitbox()[3])) {
            return true;
        }
        return false;
    }
    static checkPointInBlock(m: map, x1: number, y1: number, x2: number, y2: number): boolean {
        let s: string = `(${x1}, ${y1}) nach (${x2}, ${y2}): `;
        let b1x = Math.floor(x1 / map.bb - m.x + 1);
        let b1y = Math.floor(y1 / map.bb - m.y + 1);
        let xh = x2 / map.bb - m.x + 1;
        let b2x = (xh) % 1 == 0 ? xh - 1 : Math.floor(xh);
        let yh = y2 / map.bb - m.y + 1;
        let b2y = (yh) % 1 == 0 ? yh - 1 : Math.floor(yh);
        // let b1x = x1 / map.bb + 0.5 + m.x%1==0?Math.floor(x1 / map.bb + 0.5 + m.x):Math.floor(x1 / map.bb + 0.5 + m.x);
        // let b1y = y1 / map.bb + 0.5 + m.y%1==0?Math.floor(y1 / map.bb + 0.5 + m.y):Math.floor(y1 / map.bb + 0.5 + m.y);
        // let b2x = x2 / map.bb + 0.5 + m.x%1==0?Math.floor(x2 / map.bb + 0.5 + m.x)-1:Math.floor(x2 / map.bb + 0.5 + m.x);
        // let b2y = y2 / map.bb + 0.5 + m.y%1==0?Math.floor(y2 / map.bb + 0.5 + m.y)-1:Math.floor(y2 / map.bb + 0.5 + m.y);
        for (let x = b1x; x <= b2x; x++) {
            for (let y = b1y; y <= b2y; y++) {
                if (x >= 0 && y >= 0 && x < m.s.length && y < m.s[x].length && m.s[x][y].length > 0 && m.s[x][y][0] == "#") {
                    // console.log(s + "true");
                    // new Damage(Inventar.server.maps.indexOf(m), (x1+x2)/2, (y1+y2)/2, "~00ff00"+x1+", "+y1, 100000)
                    return true;
                }
            }
        }
        // new Damage(Inventar.server.maps.indexOf(m), (x1+x2)/2, (y1+y2)/2, "~ff0000"+x1+", "+y1, 100000)
        // console.log(s + "false");
        return false;
    }
    static streckeSchneidetStrecke(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) {

        let a1 = x2 - x1;
        let a2 = y2 - y1;
        let b1 = x3 - x4;
        let b2 = y3 - y4;
        let c1 = x3 - x1;
        let c2 = y3 - y1;

        let det = a1 * b2 - a2 * b1;

        if (det * det < 0.000000000001) {
            return false; // die Strecken sind so gut wie parallel
        }

        let det1 = c1 * b2 - c2 * b1;
        let det2 = a1 * c2 - a2 * c1;

        let s = det1 / det;
        let t = det2 / det;

        // liegt der Schnittpunkt innerhalb von beiden Strecken?
        let schneidet = s > 0 && s < 1 && t > 0 && t < 1;

        return schneidet;

    }
}
export class PFCorner {
    constructor(public x: number, public y: number, public direction: PFPoint, public convex: boolean) {

    }
}
export class PFPoint {
    constructor(public x: number, public y: number) {

    }
}
export class PFLine {
    constructor(public p1: PFPoint, public p2: PFPoint) {
        // new Line(0,p1.x,p1.y,p2.x,p2.y)
        // new PLine(0, p1, p2)
    }
}
export class PFHelpPoint {
    constructor(public x: number, public y: number, public path: PFHelpPoint, public length: number, public ggner: boolean, public p: PFHelpCorner) {
        if (path != null) {
            // new Line(0,x,y,path.x,path.y)
        }
    }
}
export class PFHelpCorner {
    constructor(public x: number, public y: number, public length: number[], public connected: PFHelpCorner[]) {

    }
}
export class SendParticle {
    constructor(public x: number, public y: number, public dx: number, public dy: number, public circle: boolean, public vx: number, public vy: number, public dvx: number, public dvy: number, public vcircle: boolean, public texture: string, public colour: number, public size: number, public type: string, public amount: number, public ax: number, public ay: number, public frictionx: number, public frictiony: number, public time: number, public dtime: number, public dsize: number, public onDestroy: SendParticle[] = [], public seed: number = Math.random()) {

    }
}
export class ChatGame {
    static id: number = 0;
    id: number = ChatGame.id++;
    static games: ChatGame[] = [];
    s: string[][] = [[]];
    acceptances: boolean[] = [];
    started: boolean = false;
    constructor(public type: string, public players: string[]) {
        let b = ChatGameType.findType(type) != null;
        for (let i = 0; i < players.length; i++) {
            const e = players[i];
            if (Inventar.server.findPlayer(e) == null) {
                b = false;
                break;
            }
        }
        let cd: string[] = [];
        for (let i = 0; i < players.length; i++) {
            const e = players[i];
            if (cd.includes(e)) {
                b = false;
                break;
            }
            cd.push(e);
        }
        if (b) {
            ChatGame.games.push(this);
            for (let i = 0; i < players.length; i++) {
                let s;
                if (i == 0) {
                    s = players[1];
                } else {
                    s = players[0];
                }
                for (let j = (i == 0) ? 2 : 1; j < players.length; j++) {
                    if (i != j) {
                        if (i + 1 == players.length || (i + 2 == players.length && i + 1 == j)) {
                            s = s + " and " + players[j];
                        } else {
                            s = s + ", " + players[j];
                        }
                    }
                }
                Inventar.server.msg("~00ff00you got invited to play " + type + " with " + s + ". click ~ffff00{/game accept " + this.id + "}[HERE];~00ff00 to accept!", players[i], true)
            }
        }
    }
    getPlayers(i: number) {
        let s;
        if (i == 0) {
            s = this.players[1];
        } else {
            s = this.players[0];
        }
        for (let j = (i == 0) ? 2 : 1; j < this.players.length; j++) {
            if (i != j) {
                if (i + 1 == this.players.length || (i + 2 == this.players.length && i + 1 == j)) {
                    s = s + " and " + this.players[j];
                } else {
                    s = s + ", " + this.players[j];
                }
            }
        }
        return s;
    }
    checkStart() {
        let b = true;
        for (let i = 0; i < this.players.length; i++) {
            const e = this.players[i];
            if (!this.acceptances[i]) {
                b = false;
            }
        }
        if (b) {
            this.onStart();
        }
    }
    onStart() {
        ChatGameType.findType(this.type).onStart(this);
    }
    command(name: string, s: string[]) {
        ChatGameType.findType(this.type).onCommand(this, name, s);
    }
    endGame(p: number[]) {
        for (let i = 0; i < this.players.length; i++) {
            const e = this.players[i];
            const e2 = p[i];
            if (p.length == 2 && p[0] == p[1]) {
                Inventar.server.msg("~ffff00the " + this.type + "-game with " + this.getPlayers(i) + " ended in a tie.", e, true);
            } else if (e2 == 1) {
                Inventar.server.msg("~00ff00you won the " + this.type + "-game with " + this.getPlayers(i) + ".", e, true);
            } else if (e2 == p.length) {
                Inventar.server.msg("~ff0000you lost the " + this.type + "-game with " + this.getPlayers(i) + ".", e, true);
            } else {
                Inventar.server.msg("~ffff00you got place " + e2 + " in the " + this.type + "-game with " + this.getPlayers(i) + ".", e, true);
            }
        }
        ChatGame.games.splice(ChatGame.games.indexOf(this))
    }
    static findType(id: number) {
        return ChatGame.games.find((v) => { return v.id == id })
    }
}
export class ChatGameType {
    static games: ChatGameType[] = [];
    constructor(public name: string, public minPlayers: number, public maxPlayers: number, public onStart: (cg: ChatGame) => void, public onCommand: (cg: ChatGame, player: string, s: string[]) => void) {
        ChatGameType.games.push(this);
    }
    static findType(type: string) {
        return ChatGameType.games.find((v) => { return v.name == type })
    }
    static fillGameTypes() {
        let h = ["rock", "paper", "scissors"]
        new ChatGameType("rock_paper_scissors", 2, 2, (cg) => {
            for (let i = 0; i < cg.players.length; i++) {
                const element = Inventar.server.findPlayer(cg.players[i]);
                Inventar.server.msg("~ffff00{/game action " + cg.id + " 0}[rock] {/game action " + cg.id + " 1}[paper] {/game action " + cg.id + " 2}[scissors] ", element.name, true)
            }
        }, (cg, p, s) => {
            let d = Number.parseFloat(s[0]);
            Inventar.server.msg("~00ff00you picked " + h[d], p, true)
            if (!Number.isNaN(d) && d % 1 == 0 && d >= 0 && d <= 2) {
                cg.s[0][cg.players.indexOf(p)] = d + "";
            }
            if (cg.s[0].length >= cg.players.length) {
                let b = true;
                for (let i = 0; i < cg.s[0].length; i++) {
                    const e = cg.s[0][i];
                    if (e == null) {
                        b = false;
                        break;
                    }
                }
                if (b) {
                    let h2 = 0;
                    if ((Number.parseFloat(cg.s[0][0]) + h2++) % 3 == Number.parseFloat(cg.s[0][1])) {//tie
                        for (let i = 0; i < cg.players.length; i++) {
                            Inventar.server.msg("~ffff00your rock-paper-scissor-game ended in a tie because you both picked " + h[Number.parseFloat(cg.s[0][0])] + ". it will automatically restart.", cg.players[i], true);
                        }
                        cg.s[0] = [];
                        ChatGameType.findType(cg.type).onStart(cg);
                    }
                    if ((Number.parseFloat(cg.s[0][0]) + h2++) % 3 == Number.parseFloat(cg.s[0][1])) {//p2
                        Inventar.server.msg("~ff0000you lost the rock-paper-scissors-game with " + cg.players[1], cg.players[0], true);
                        Inventar.server.msg("~00ff00you won the rock-paper-scissors-game with " + cg.players[0], cg.players[1], true);
                        ChatGame.games.splice(ChatGame.games.indexOf(cg))
                    }
                    if ((Number.parseFloat(cg.s[0][0]) + h2++) % 3 == Number.parseFloat(cg.s[0][1])) {//p1
                        Inventar.server.msg("~00ff00you won the rock-paper-scissors-game with " + cg.players[1], cg.players[0], true);
                        Inventar.server.msg("~ff0000you lost the rock-paper-scissors-game with " + cg.players[0], cg.players[1], true);
                        ChatGame.games.splice(ChatGame.games.indexOf(cg))
                    }
                }
            }
        });
        new ChatGameType("tick_tack_toe", 2, 2, (cg) => {
            cg.s[0] = [];
            cg.s[1] = [];
            cg.s[1][0] = "0";
            cg.s[0] = [];
            cg.command("", []);
        }, (cg, p, s) => {
            let h = (p == cg.players[0]) == (cg.s[1][0] == "0");
            if (h) {
                let d = Number.parseFloat(s[0]);
                if (d >= 0 && d < 9 && (cg.s[0][d] == "" || cg.s[0][d] == null)) {
                    cg.s[0][d] = cg.s[1][0] == "0" ? "x" : "o"
                    cg.s[1][0] = cg.s[1][0] == "0" ? "1" : "0"
                    let h: string[][] = [
                        [
                            "x", "x", "x",
                            "", "", "",
                            "", "", ""
                        ],
                        [
                            "", "", "",
                            "x", "x", "x",
                            "", "", ""
                        ],
                        [
                            "", "", "",
                            "", "", "",
                            "x", "x", "x"
                        ],
                        [
                            "x", "", "",
                            "x", "", "",
                            "x", "", ""
                        ],
                        [
                            "", "x", "",
                            "", "x", "",
                            "", "x", ""
                        ],
                        [
                            "", "", "x",
                            "", "", "x",
                            "", "", "x"
                        ],
                        [
                            "x", "", "",
                            "", "x", "",
                            "", "", "x"
                        ],
                        [
                            "", "", "x",
                            "", "x", "",
                            "x", "", ""
                        ],
                    ]
                    for (let i = 0; i < h.length; i++) {
                        const e = h[i];
                        let b = true;
                        let p1: boolean = null;
                        for (let j = 0; j < e.length; j++) {
                            const e2 = e[j];
                            if (e2 == "x") {
                                if (p1 == null && cg.s[0][j] != null && cg.s[0][j] != "") {
                                    p1 = cg.s[0][j] == "x";
                                }
                                if (p1 != (cg.s[0][j] == "x") || cg.s[0][j] == null || cg.s[0][j] == "") {
                                    b = false;
                                    break;
                                }
                            }
                        }
                        if (b) {
                            cg.endGame(p1 ? [1, 2] : [2, 1]);
                            return;
                        }
                    }
                    let b: boolean = true;
                    for (let i = 0; i < 9; i++) {
                        const e = cg.s[0][i];
                        if (e == null) {
                            b = false;
                        }
                    }
                    if (b) {
                        cg.endGame([1, 1])
                    }
                }
            } else {
                Inventar.server.msg("~ff0000it isn't your turn right now...", p, true)
            }
            if (h || s.length == 0) {
                let st = "";
                for (let i = 0; i < 9; i++) {
                    let h = cg.s[0][i];
                    if (h == "" || h == null) {
                        st = st + " ~ffffff{/game action " + cg.id + " " + i + "}[_];";
                    }
                    if (h == "x") {
                        st = st + " [X]";
                    }
                    if (h == "o") {
                        st = st + " [O]";
                    }
                    if (i % 3 == 2) {
                        st = st + "\n";
                    }
                }
                let gx = st;
                while (gx != gx.replace(" [X]", " ~00ff00[X]")) {
                    gx = gx.replace(" [X]", " ~00ff00[X]");
                }
                while (gx != gx.replace(" [O]", " ~ff0000[O]")) {
                    gx = gx.replace(" [O]", " ~ff0000[O]");
                }
                let go = st;
                while (go != go.replace(" [O]", " ~00ff00[O]")) {
                    go = go.replace(" [O]", " ~00ff00[O]");
                }
                while (go != go.replace(" [X]", " ~ff0000[X]")) {
                    go = go.replace(" [X]", " ~ff0000[X]");
                }
                Inventar.server.msg2(gx, cg.players[0], true);
                Inventar.server.msg2(go, cg.players[1], true);
            }
        });
        let hx = 7;
        let hy = 6;
        let xy = hx * hy;
        new ChatGameType("four_in_a_row", 2, 2, (cg) => {//7*6
            cg.s[0] = [];
            cg.s[1] = [];
            cg.s[1][0] = "0";
            cg.s[0] = [];
            cg.command("", []);
        }, (cg, p, s) => {
            let h = (p == cg.players[0]) == (cg.s[1][0] == "0");
            if (h) {
                let d = Number.parseFloat(s[0]);
                if (d >= 0 && d < hx && (cg.s[0][d] == "" || cg.s[0][d] == null)) {
                    while (cg.s[0][d]==""||cg.s[0][d]==null&&d<xy) {
                        d+=hx;
                    }
                    if(!(d<hx)){
                        d-=hx;
                        cg.s[0][d] = cg.s[1][0] == "0" ? "x" : "o"
                        cg.s[1][0] = cg.s[1][0] == "0" ? "1" : "0"
                    }
                    // for (let i = 0; i < h.length; i++) {
                    //     const e = h[i];
                    //     let b = true;
                    //     let p1: boolean = null;
                    //     for (let j = 0; j < e.length; j++) {
                    //         const e2 = e[j];
                    //         if (e2 == "x") {
                    //             if (p1 == null && cg.s[0][j] != null && cg.s[0][j] != "") {
                    //                 p1 = cg.s[0][j] == "x";
                    //             }
                    //             if (p1 != (cg.s[0][j] == "x") || cg.s[0][j] == null || cg.s[0][j] == "") {
                    //                 b = false;
                    //                 break;
                    //             }
                    //         }
                    //     }
                    //     if (b) {
                    //         cg.endGame(p1?[1,2]:[2,1]);
                    //         return;
                    //     }
                    // }
                    for (let x = 0; x < hx - 3; x++) {
                        for (let y = 0; y < hy - 3; y++) {
                            const e1 = cg.s[0][x + y * hx];
                            const e2 = cg.s[0][x + y * hx + (1 + hx) * 1];
                            const e3 = cg.s[0][x + y * hx + (1 + hx) * 2];
                            const e4 = cg.s[0][x + y * hx + (1 + hx) * 3];
                            if (e1 == e2 && e2 == e3 && e3 == e4 && e4 != null && e4 != "") {
                                let p1 = e4 == "x";
                                cg.endGame(p1 ? [1, 2] : [2, 1]);
                            }
                        }
                    }
                    for (let x = 0; x < hx - 3; x++) {
                        for (let y = 0; y < hy - 3; y++) {
                            const e1 = cg.s[0][x + y * hx + (hx-1)*0 + 3];
                            const e2 = cg.s[0][x + y * hx + (hx-1)*1 + 3];
                            const e3 = cg.s[0][x + y * hx + (hx-1)*2 + 3];
                            const e4 = cg.s[0][x + y * hx + (hx-1)*3 + 3];
                            if (e1 == e2 && e2 == e3 && e3 == e4 && e4 != null && e4 != "") {
                                let p1 = e4 == "x";
                                cg.endGame(p1 ? [1, 2] : [2, 1]);
                            }
                        }
                    }
                    for (let x = 0; x < hx - 3; x++) {
                        for (let y = 0; y < hy; y++) {
                            const e1 = cg.s[0][x + y * hx];
                            const e2 = cg.s[0][x + y * hx + (1) * 1];
                            const e3 = cg.s[0][x + y * hx + (1) * 2];
                            const e4 = cg.s[0][x + y * hx + (1) * 3];
                            if (e1 == e2 && e2 == e3 && e3 == e4 && e4 != null && e4 != "") {
                                let p1 = e4 == "x";
                                cg.endGame(p1 ? [1, 2] : [2, 1]);
                            }
                        }
                    }
                    for (let x = 0; x < hx; x++) {
                        for (let y = 0; y < hy - 3; y++) {
                            const e1 = cg.s[0][x + y * hx];
                            const e2 = cg.s[0][x + y * hx + (hx) * 1];
                            const e3 = cg.s[0][x + y * hx + (hx) * 2];
                            const e4 = cg.s[0][x + y * hx + (hx) * 3];
                            if (e1 == e2 && e2 == e3 && e3 == e4 && e4 != null && e4 != "") {
                                let p1 = e4 == "x";
                                cg.endGame(p1 ? [1, 2] : [2, 1]);
                            }
                        }
                    }
                    let b: boolean = true;
                    for (let i = 0; i < xy; i++) {
                        const e = cg.s[0][i];
                        if (e == null) {
                            b = false;
                        }
                    }
                    if (b) {
                        cg.endGame([1, 1])
                    }
                }
            } else {
                Inventar.server.msg("~ff0000it isn't your turn right now...", p, true)
            }
            if (h || s.length == 0) {
                let st = "";
                for (let i = 0; i < xy; i++) {
                    let h = cg.s[0][i];
                    if (h == "" || h == null) {
                        st = st + " ~ffffff{/game action " + cg.id + " " + i%hx + "}[~666666O~ffffff];";
                    }
                    if (h == "x") {
                        st = st + " [X]";
                    }
                    if (h == "o") {
                        st = st + " [O]";
                    }
                    if (i % hx == hx-1) {
                        st = st + "\n";
                    }
                }
                let gx = st;
                while (gx != gx.replace(" [X]", " ~00ff00[O]")) {
                    gx = gx.replace(" [X]", " ~00ff00[O]");
                }
                while (gx != gx.replace(" [O]", " ~ff0000[O]")) {
                    gx = gx.replace(" [O]", " ~ff0000[O]");
                }
                let go = st;
                while (go != go.replace(" [O]", " ~00ff00[O]")) {
                    go = go.replace(" [O]", " ~00ff00[O]");
                }
                while (go != go.replace(" [X]", " ~ff0000[O]")) {
                    go = go.replace(" [X]", " ~ff0000[O]");
                }
                Inventar.server.msg2(gx, (cg.s[1][0]=="0"?"~00ff00your turn:\n":"~ff0000opponent's turn:\n")+cg.players[0], true);
                Inventar.server.msg2(go, (cg.s[1][0]=="1"?"~00ff00your turn:\n":"~ff0000opponent's turn:\n")+cg.players[1], true);
            }
        });
    }
}
export class Point {
    constructor(public x: number, public y: number) {

    }
    move(x: number, y: number) {
        this.x += x;
        this.y += y;
    }
}
export class RNG {
    c: number = 1;
    constructor(public seed: number) {

    }
    random() {
        return Math.abs(Math.sin(this.c++) * (this.seed + 1) * 1619652) % 1;
    }
    randomCircle(x: number, y: number): Point {
        while (true) {
            let xn = (this.random() - 0.5) * x;
            let yn = (this.random() - 0.5) * y;
            if (Math.pow(xn / x, 2) + Math.pow(yn / y, 2) <= 1) {
                return new Point(xn, yn);
            }
        }
    }
}
/*
client string[]:
    0: max storage slots
    1: collection
    2: max minion slots
    3: s-collection
    4: s-boss-tier
    5: s-exp
    99: henry gratis sword
item string[]:
    0: enchants, miniontype, special item receiver
    1: minion tier, special item edition
    2: reforge
    3: expertise kills
    4: rune, sack item type
    5: rune lvl, sack item count
*/