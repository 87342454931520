import { Figur } from "./Figur";
import { Inventar } from "./Item";
import { Main } from "./Main";
import { ClientInventory, skin } from "../common/ClientData";
import * as PIXI from 'pixi.js'
import $ from 'jquery'

export class Spiel {
    geld: number;
    f: Figur;
    container: PIXI.Container;
    hotbar: Inventar;
    inventar: Inventar;
    menü: Inventar;
    news: Map<Zeit, String>;
    static spiel: Spiel;
    color: number=0;
    colors:string[]=["21+","-12","1+2","12-","+21","2-1"];
    constructor() {
        $(".newshide").hide()
        this.container = new PIXI.Container();
        Spiel.spiel = this;
        Main.app.stage.addChild(this.container);

        // Create a new texture
        // const texture = Main.spritesheet.textures['Ship_1#0'];

        // Create a 5x5 grid of ships
        // for (let i = 0; i < 25; i++) {
        //     const ship = new PIXI.Sprite(texture);
        //     ship.anchor.set(0.5);
        //     ship.x = (i % 5) * 40;
        //     ship.y = Math.floor(i / 5) * 40;
        //     this.container.addChild(ship);
        // }

        // Move this.container to the center
        this.container.x = Main.width / 2;
        this.container.y = Main.height / 2;

        // Center bunny sprite in local this.container coordinates
        this.container.pivot.x = this.container.width / 2;
        this.container.pivot.y = this.container.height / 2;
        this.f = new Figur();
        let $itemslots = $(".ItemSlot");
        let intervalId;
        $itemslots.on("mouseover", (event) => {
            $(".tooltip").show();
            $(".tooltip").text($(event.originalEvent.target).data("description"));
            intervalId = window.setInterval(() => {
                $(".tooltip").text($(event.originalEvent.target).data("description"));
            }, 50);
            $(document).on("mousemove.meiner", (event) => {
                $(".tooltip").css({
                    left: event.pageX - 6 - $(".tooltip").width() - 10,
                    top: event.pageY + 6+$(document).scrollTop()
                });
            });
        })
        $itemslots.on("mouseout", (event) => {
            $(".tooltip").hide();
            $(document).off("mousemove.meiner");
            window.clearInterval(intervalId);
        })
        const graphics = new PIXI.Graphics();
        Main.app.stage.addChild(graphics);
        $(document).on("mousemove", (event) => {
            $("#maus").css({
                left: event.pageX - 43,
                top: event.pageY
            });
        });
        $("#pixi").on("pointermove", (event) => {
            graphics.clear();
            if (Main.m.rayCastHelp) {
                let target = event.target;
                let rect = target.getBoundingClientRect();
                let x = event.clientX - rect.left;
                let y = event.clientY - rect.top;
                let d = Math.sqrt(Math.pow(400 - x, 2) + Math.pow(300 - y, 2)) / Figur.range;
                const path = [400, 300, 400 + (x - 400) / d, 300 + (y - 300) / d];
                graphics.lineStyle(1);
                graphics.drawPolygon(path);
            }
        });
        this.hotbar = new Inventar(200, 590, 9, 1);
        this.menü = new Inventar(835, 350, 9, 3);
        this.inventar = new Inventar(835, 61, 9, 6);
        $(".tab").on("mousedown", (event) => {
            $(".tabinhalt").hide()
            $("." + $(event.target).data("tabklasse")).show()
            $(".tab").removeClass("Atab");
            $(event.target).addClass("Atab");
        });
        this.news = new Map();
        // this.news.set(new Zeit(0, 0,10, 21, 28, 7, 2021), "<div>hier!</div>");
        for (let y = 0; y < skin.hy + skin.ay + skin.by; y++) {
            for (let x = 0; x < skin.ax * 2 + skin.hx; x++) {
                let b = false;
                if (x > skin.ax - 1 && x < skin.ax + skin.hx) {
                    b = true;
                }
                if (y > skin.hy - 1 && y < skin.hy + skin.ay) {
                    b = true;
                }
                if (b) {
                    $("#skin").append("<div class='pixel' id='px" + x + "x" + y + "' style='left:" + (x * 16 + 32) + "px;top:" + (y * 16 + 127) + "px'></div>")
                    let m = Main.m;
                    $("#px" + x + "x" + y + "").on("click", () => {
                        if (m.transparent) {
                            $("#px" + x + "x" + y + "").css("background-color", "#66666600")
                        } else {
                            $("#px" + x + "x" + y + "").css("background-color", (<string>$("#color").val()))
                        }
                    });
                }
            }
        }
    }

    FillInv(c: ClientInventory) {
        $("#maus").css({
            'background-image': "none"
        });
        $("#maus").text("");
        for (let i = 0; i < 9 * 10; i++) {
            let s = $("#" + i);
            s.css({
                'background-image': "none"
            });
            s.text("");
            Inventar.tooltip[i] = "";
            if (c.itemslots[i] != null) {
                s.css({
                    'background-image': "url('assets/items/" + this.getGraphic(c.itemslots[i].texture) + "')"
                });
                s.text(c.itemslots[i].Amount + "");
                if (c.itemslots[i].Amount == 1) {
                    s.text("");
                }
                Inventar.tooltip[i] = c.itemslots[i].text
            }
        }
        if (c.maus != null) {
            let m = $("#maus")
            m.css({
                'background-image': "url('assets/items/" + this.getGraphic(c.maus.texture) + "')"
            });
            m.text(c.maus.Amount + "");
            if (c.maus.Amount == 1) {
                m.text("");
            }
        }
    }
    getGraphic(s: string) {
        return s;
    }
    act(delta: number) {
        $("#geld").text("coins: " + this.geld);
        this.container.rotation -= 0.01 * delta;
        this.news.forEach((string, zeit) => {
            if (zeit.später()) {
                $(".newsa.news").append("<div class=newsartikel>" + string + "</div>");
                this.news.delete(zeit);
            }
        })
        this.color++;
        let c=this.colors[Math.floor(this.color/256)%6];
        let h=0;
        let d:number[]=[];
        for(let h=0;h<3;d[h]=Number.isNaN(Number.parseFloat(c[h]))?128-Number.parseFloat(c[h]+"128")+Number.parseFloat(c[h++]+this.color%256):Number.parseFloat(c[h++])*255-255);
        $(".rainbow").css("color","rgb("+d[h++]+", "+d[h++]+", "+d[h++]+")")
    }


}
export class Zeit {
    millisekunde: number = 0;
    sekunde: number = 0;
    minute: number = 0;
    stunde: number = 0;
    tag: number = 0;
    monat: number = 0;
    jahr: number = 0;
    constructor(millisekunde: number, sekunde: number, minute: number, stunde: number, tag: number, monat: number, jahr: number) {
        this.millisekunde = millisekunde;
        this.sekunde = sekunde;
        this.minute = minute;
        this.stunde = stunde;
        this.tag = tag;
        this.monat = monat;
        this.jahr = jahr;
    }
    vergleichen(z: Zeit) {

        if (this.jahr >= z.jahr) {
            if (this.jahr == z.jahr) {
                if (this.monat >= z.monat) {
                    if (this.monat == z.monat) {
                        if (this.tag >= z.tag) {
                            if (this.tag == z.tag) {
                                if (this.stunde >= z.stunde) {
                                    if (this.stunde == z.stunde) {
                                        if (this.minute >= z.minute) {
                                            if (this.minute == z.minute) {
                                                if (this.sekunde >= z.sekunde) {
                                                    if (this.sekunde == z.sekunde) {
                                                        if (this.millisekunde >= z.millisekunde) {
                                                            return true;
                                                        }
                                                    } else {
                                                        return true;
                                                    }
                                                }
                                            } else {
                                                return true;
                                            }
                                        }
                                    } else {
                                        return true;
                                    }
                                }
                            } else {
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                }
            } else {
                return true;
            }
        }
        return false;
    }
    später() {

        let d = new Date();
        if (this.jahr <= d.getFullYear()) {
            if (this.jahr == d.getFullYear()) {
                if (this.monat <= d.getMonth() + 1) {
                    if (this.monat == d.getMonth() + 1) {
                        if (this.tag <= d.getDate()) {
                            if (this.tag == d.getDate()) {
                                if (this.stunde <= d.getHours()) {
                                    if (this.stunde == d.getHours()) {
                                        if (this.minute <= d.getMinutes()) {
                                            if (this.minute == d.getMinutes()) {
                                                if (this.sekunde <= d.getSeconds()) {
                                                    if (this.sekunde == d.getSeconds()) {
                                                        if (this.millisekunde <= d.getMilliseconds()) {
                                                            return true;
                                                        }
                                                    } else {
                                                        return true;
                                                    }
                                                }
                                            } else {
                                                return true;
                                            }
                                        }
                                    } else {
                                        return true;
                                    }
                                }
                            } else {
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                }
            } else {
                return true;
            }
        }
        return false;






    }
}
let c=[];
export function cache(n:string,ca:(t)=>void){
    let h=c[n];
    if(h){
        ca(h);
    }else{
        PIXI.Assets.load(n).then((t)=>{
            let source: PIXI.ImageSource = t.source;
            source.minFilter = "nearest";
            source.magFilter = "nearest";
            c[n]=t;
            ca(t);
        });
    }
}